/* eslint-disable consistent-return */
import { useEffect, useRef, useState } from 'react';

const useDebounceValue = <T>(value: T, ms: number) => {
  const timeout = useRef<number | null>(null);
  const isFirstRender = useRef(true);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (isFirstRender.current) {
      // Avoid setTimeout on mount
      isFirstRender.current = false;
      return;
    }

    timeout.current = window.setTimeout(() => {
      timeout.current = null;
      setDebouncedValue(value);
    }, ms);

    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [value, ms]);

  return debouncedValue;
};

export default useDebounceValue;
