import {
  Box,
  TableContainer,
  Table as CUITable,
  TableProps as CUITableProps,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  forwardRef,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { SimpleText } from '../../styles';

type TableProps = {
  columns: JSX.Element[];
  rows: JSX.Element[];
  renderBody?: (rows: JSX.Element[]) => React.ReactElement;
};

export const ListTable = styled(CUITable)`
  th {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  tbody tr:hover {
    background-color: var(--chakra-colors-gray-5);
    font-weight: bold;
  }
`;

const renderDefaultBody = (items: JSX.Element[]) => <Tbody>{items}</Tbody>;

export const renderDefaultColumn = (column: string) => (
  <Th key={column}>
    <SimpleText fontSize="19px" fontWeight="semibold" textTransform="none">
      {column}
    </SimpleText>
  </Th>
);

const Table = forwardRef((props: PropsWithChildren<TableProps & CUITableProps>, ref) => {
  const { rows, columns, children, renderBody = renderDefaultBody, ...tableProps } = props;
  return (
    <Box marginBottom="20px" borderRadius="15px" backgroundColor="white" boxShadow="lg">
      <TableContainer>
        <ListTable ref={ref} variant="unstyled" fontSize="md" {...tableProps}>
          <Thead borderBottom="1px solid" borderColor="gray.10">
            <Tr>{columns}</Tr>
          </Thead>
          {renderBody(rows)}
        </ListTable>
      </TableContainer>
      {children}
    </Box>
  );
});

Table.displayName = 'Table';

export { Tr, Th, Td, Tbody };

export default Table;
