/* eslint-disable react/no-array-index-key */
import {
  Button,
  Center,
  forwardRef,
  Input,
  InputProps,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Spinner,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import React, { FocusEvent, ReactNode, useState } from 'react';
import { SimpleText } from '../../styles';

export type AutocompleteItem = { value: string; name: string };

type Props<T extends AutocompleteItem> = Omit<InputProps, 'onChange' | 'value'> & {
  removeable?: boolean;
  options: T[];
  selectedItems: T[];
  /**
   * Loading state when it's an async search
   */
  isLoading?: boolean;
  onChange: (selectedItems: T[]) => void;
  /**
   * Callback when it's a custom search or filter
   */
  onSearch?: (search: string) => void;
  renderHelperMessage?: (filteredOptions: AutocompleteItem[], search: string) => ReactNode;
};

const regEscape = (v: string) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

const renderHighlightText = (text: string, search: string, regex: RegExp) => {
  if (!search) {
    return text;
  }

  if (text.toLowerCase() === search.toLowerCase()) {
    return <SimpleText color="black">{text}</SimpleText>;
  }

  let index = 0;
  const renderedText = [];
  while (index < text.length) {
    if (regex.test(text.slice(index, index + search.length))) {
      renderedText.push(
        <SimpleText color="black" key={`${search} ${index}`}>
          {search}
        </SimpleText>
      );

      index += search.length;
    } else {
      renderedText.push(text[index]);
      index += 1;
    }
  }

  return renderedText;
};

const AutoComplete = forwardRef(
  <T extends AutocompleteItem>(props: Props<T>, ref: React.ForwardedRef<any>) => {
    const {
      removeable = true,
      options,
      selectedItems,
      isLoading,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      onSearch,
      renderHelperMessage,
      ...rest
    } = props;
    const { width, maxWidth } = rest;

    const [input, setInput] = useState('');
    const [isFocused, setFocus] = useBoolean();
    const [isPopoverHovered, setHover] = useBoolean();

    const createRegex = () => new RegExp(regEscape(input), 'ig');
    const filteredOptions = options.filter(
      (option) =>
        (!!onSearch || createRegex().test(option.name)) &&
        selectedItems.every((item) => item.name !== option.name)
    );

    const handleChange = (item: T) => () => {
      onChange([...selectedItems, item]);

      setInput('');
      setFocus.off();
    };

    const handleInputOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const text = e.target.value;
      setInput(text);

      if (onSearch) {
        onSearch(text);
      }
    };

    const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }

      if (onKeyDown) {
        onKeyDown(e);
      }

      if (e.key === 'Enter' && filteredOptions[0]) {
        onChange([...selectedItems, filteredOptions[0]]);
        setInput('');
      }
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }

      setFocus.on();
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }

      if (!isPopoverHovered) {
        setFocus.off();
      }
    };

    const handleCloseTag = (selectedItem: T) => () => {
      onChange(selectedItems.filter((item) => item.value !== selectedItem.value));
    };

    const searchOptions = filteredOptions.map((option) => (
      <Button
        key={option.value}
        width="100%"
        textAlign="start"
        display="block"
        variant="unstyled"
        padding="10px 20px"
        color="gray.60"
        onClick={handleChange(option)}
        _hover={{ backgroundColor: 'gray.5', cursor: 'pointer', color: 'black' }}
      >
        {renderHighlightText(option.name, input, createRegex())}
      </Button>
    ));

    const loadingView = (
      <Center marginY="12px">
        <Spinner />
      </Center>
    );

    return (
      <>
        <Popover
          isOpen={isFocused && searchOptions.length !== 0}
          onOpen={setFocus.on}
          onClose={setFocus.off}
          autoFocus={false}
          returnFocusOnClose={false}
          placement="bottom-start"
        >
          <PopoverAnchor>
            <Input
              ref={ref}
              value={input}
              onChange={handleInputOnChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyPress}
              {...rest}
            />
          </PopoverAnchor>
          <PopoverContent width={width} maxWidth={maxWidth}>
            <PopoverBody padding="0" onMouseOver={setHover.on} onMouseLeave={setHover.off}>
              {isLoading ? loadingView : searchOptions}
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Stack direction="row" spacing="10px" marginTop="12px" width={width} maxWidth={maxWidth}>
          {selectedItems.map((item) => (
            <Tooltip key={item.value} label={item.name}>
              <Tag backgroundColor="gray.10" color="gray.90" fontWeight="bold">
                <TagLabel>{item.name}</TagLabel>
                {removeable && <TagCloseButton onClick={handleCloseTag(item)} />}
              </Tag>
            </Tooltip>
          ))}
        </Stack>
        {renderHelperMessage && renderHelperMessage(filteredOptions, input)}
      </>
    );
  }
);

AutoComplete.displayName = 'AutoComplete';

export default AutoComplete;
