import { BaseParams, IPagination } from '.';
import { IScreen } from './screen';

export enum Status {
  DRAFT = 'Rascunho',
  PUBLISHED = 'Publicada',
  EXPIRED = 'Expirada',
  CURRENT = 'Vigente',
  DELETED = 'Removida',
}

type BaseComponent<Type extends string> = {
  id?: string;
  type: Type;
};

export type Item = {
  mediaId: string;
  download: string;
  url: string;
  name?: string;
  analyticsId?: string;
  countdown?: any;
};
type ItemsContent = { content: { items: Item[] } };

export type Story = BaseComponent<'story'> & ItemsContent;
export type Banner = BaseComponent<'banner'> & ItemsContent;
export type Carousel = BaseComponent<'carousel'> & ItemsContent;
export type Grid = BaseComponent<'grid'> & { content: { columns: number; items: Item[] } };
export type Showcase = BaseComponent<'showcase'> & { content: { page: string; position: string } };

export type Label = BaseComponent<'label'> & {
  content: {
    text: string;
    color: string;
    fontSize: 'small' | 'normal' | 'medium' | 'big';
    position: 'left' | 'center' | 'right';
  };
};

export type ComponentType = Label | Showcase | Banner | Grid | Carousel | Story;

export interface IBaseCampaign {
  id: string;
  name: string;
  description: string;
  status: Status;
  startDate: string;
  endDate: string;
  createdBy: string;
  standard: boolean;
  screen: IScreen;
  components?: ComponentType[];
}
export interface ICampaign extends IBaseCampaign {
  components?: ComponentType[];
}

export interface IComponent {
  type: ComponentType['type'];
  schema: any;
  slugs: any;
}

export interface ICampaignResponse {
  campaign: ICampaign;
}

export interface ICampaignCreate extends Omit<ICampaign, 'id' | 'createdBy' | 'status' | 'screen'> {
  screen: string;
}

export interface ICampaignEdit extends Partial<Omit<ICampaign, 'status' | 'createdBy' | 'screen'>> {
  screen?: string;
}

export interface ICampaignListResponse {
  pagination: IPagination;
  campaigns: ICampaign[];
}

export type UpsertCampaign = ICampaignCreate | ICampaignEdit;
export type CampaignParams = BaseParams<ICampaign> & { screen?: string };
