import {
  Button,
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Select } from '../../../shared/components/Form';
import useStore from '../../../shared/store';
import { DescriptionFormHelperProps, IForm } from './UserDrawer.types';

const FormControl = styled(ChakraFormControl)`
  &:not(last-child) {
    margin-bottom: 24px;
  }
`;

function DescriptionFormHelper({ roles }: DescriptionFormHelperProps) {
  const roleName = useWatch({ name: 'role' });
  const role = useMemo(() => roles.find((r) => r.name === roleName), [roleName, roles]);

  return <FormHelperText>{role?.description}</FormHelperText>;
}

function UserDraweForm() {
  const authenticatedUser = useStore((state) => state.auth.login.data);
  const roles = useStore((state) => state.users.roles);
  const { currentId } = useStore((state) => state.users.upsert);
  const { formState, register } = useFormContext<IForm>();
  const isEdit = useWatch<IForm>({ name: 'isEdit' });

  const isAuthenticatedUser = authenticatedUser!.id === currentId;
  const target = isAuthenticatedUser ? 'perfil' : 'usuário';
  const { errors, isValid, touchedFields, isDirty } = formState;

  const roleOptions = roles.map((role) => (
    <option key={role.id} value={role.name}>
      {role.name}
    </option>
  ));

  const createOnlyView = (
    <>
      <FormControl isInvalid={!!errors.password} isRequired>
        <FormLabel>Senha</FormLabel>
        <Input
          type="password"
          placeholder="Digite a senha do usuário"
          maxLength={13}
          {...register('password')}
        />
        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.passwordConfirm} isRequired>
        <FormLabel>Confirmação de senha</FormLabel>
        <Input
          type="password"
          placeholder="Confirme a senha"
          maxLength={13}
          {...register('passwordConfirm')}
        />
        <FormErrorMessage>{errors.passwordConfirm?.message}</FormErrorMessage>
      </FormControl>
    </>
  );

  const nonProfileView = (
    <FormControl isRequired isInvalid={!!errors.role} isDisabled={isAuthenticatedUser}>
      <FormLabel>Perfil</FormLabel>
      <Select
        placeholder="Selecione um perfil para o usuário"
        isTouched={!!touchedFields.role}
        {...register('role')}
      >
        {roleOptions}
      </Select>
      <FormErrorMessage>{errors.role?.message}</FormErrorMessage>
      <DescriptionFormHelper roles={roles} />
    </FormControl>
  );

  return (
    <>
      <FormControl isInvalid={!!errors.name} isRequired>
        <FormLabel>Nome</FormLabel>
        <Input placeholder="Digite o nome do usuário" {...register('name')} maxLength={41} />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.email} isRequired>
        <FormLabel>E-mail (exemplo@email.com)</FormLabel>
        <Input placeholder="Digite o e-mail do usuário" {...register('email')} />
        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
      </FormControl>
      {!isEdit && createOnlyView}
      {!isAuthenticatedUser && nonProfileView}
      <Button type="submit" colorScheme="primary" disabled={!isValid || !isDirty} width="100%">
        {isEdit ? 'Editar' : 'Cadastrar'} {target}
      </Button>
    </>
  );
}

export default UserDraweForm;
