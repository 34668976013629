/* eslint-disable consistent-return */
import { ToastPosition } from '@chakra-ui/react';
import { handleGenericErrors } from '../utils/requestHelpers';
import { Endpoint, Fetchable, RootStore, Setter } from './store.types';

type GetKeysOfType<Slice, Data> = keyof {
  [Key in keyof Slice as Slice[Key] extends Data ? Key : never]: Slice[Key];
};

type Key<Slice, Data extends any> = GetKeysOfType<Slice, Fetchable<Data>>;
type LoadingFunction = (isLoading: boolean) => void;
type GetSlice<T> = (state: RootStore) => T;

type BaseOpts<Placeholder> = {
  placeholder: Placeholder;
  shouldThrowError?: boolean;
  shouldPassErrorHandling?: boolean;
  toastPosition?: ToastPosition;
};

type Opts<Slice> = {
  /**
   * Zustand Setter function
   */
  set: Setter;
  /**
   * function to get the slice with a {@link Fetchable}
   */
  getSlice: GetSlice<Slice>;
  /**
   * string related to redux devetools helpers
   */
  prefix: string;
};

const createAction =
  <Data, Placeholder, Args extends unknown[]>(
    effect: (...args: Args) => Promise<Data>,
    loading: LoadingFunction,
    config: BaseOpts<Placeholder>
  ) =>
  async (...args: Args): Promise<Data | Placeholder> => {
    loading(true);
    try {
      return await effect(...args);
    } catch (error) {
      if (!config.shouldPassErrorHandling) {
        handleGenericErrors(error, config.toastPosition);
      }

      if (config.shouldThrowError) {
        throw error;
      }
    } finally {
      loading(false);
    }

    return config.placeholder;
  };

const getFetchable = <Slice, Data>(slice: Slice, key: Key<Slice, Data>) =>
  slice[key] as unknown as Fetchable<Data>;

const createEndpoint = <Slice, Data, Placeholder, Args extends unknown[] = unknown[]>(
  key: Key<Slice, Data | Placeholder>,
  effect: (...args: Args) => Promise<Data>,
  config: BaseOpts<Placeholder>,
  globalConfig: Opts<Slice>
): Endpoint<Args, Data, Placeholder, Key<Slice, Data | Placeholder>> => {
  const { placeholder } = config;
  const { prefix = '', set, getSlice } = globalConfig;

  const initialState = {
    data: placeholder,
    isLoading: false,
    isFetching: false,
    hasFetched: false,
  };

  const dispatchLoading: LoadingFunction = (isLoading) => {
    set(
      (state) => {
        getFetchable(getSlice(state), key).isLoading = isLoading;
        getFetchable(getSlice(state), key).isFetching = isLoading;
      },
      false,
      `${prefix}/${String(key)}/loading`
    );
  };

  const dispatchFetching: LoadingFunction = (isFetching) => {
    set(
      (state) => {
        getFetchable(getSlice(state), key).isFetching = isFetching;
      },
      false,
      `${prefix}/${String(key)}/loading`
    );
  };

  const dispatchSuccess = (data: Data | Placeholder) => {
    set(
      (state) => {
        getFetchable(getSlice(state), key).data = data;
        getFetchable(getSlice(state), key).hasFetched = true;
      },
      false,
      `${prefix}/${String(key)}/success`
    );
  };

  const fetch = async (...args: Parameters<typeof effect>) => {
    const data = await effect(...args);

    if (data) {
      dispatchSuccess(data);
    }

    return data;
  };

  const refetch = createAction(fetch, dispatchFetching, config);
  const action = createAction(fetch, dispatchLoading, config);

  return Object.assign(action, {
    actions: { dispatchLoading, dispatchSuccess, refetch },
    initialState,
    key,
  });
};

const createService =
  <Slice>(globalConfig: Opts<Slice>) =>
  <Data, Placeholder, Args extends unknown[] = unknown[]>(
    key: Key<Slice, Data | Placeholder>,
    effect: (...args: Args) => Promise<Data>,
    config: BaseOpts<Placeholder>
  ) =>
    createEndpoint(key, effect, config, globalConfig);

export const createSliceState = <Slice>(getEndpoints: () => Record<keyof Slice, any>) =>
  Object.fromEntries(
    Object.entries(getEndpoints()).map(([key, value]) => {
      const typedValue = value as { initialState: any; key: keyof Slice };
      return [typedValue.key || key, typedValue.initialState];
    })
  ) as Record<keyof Slice, any>;

export default createService;
