import React, { PropsWithChildren } from 'react';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import ActionButton from '../../../shared/components/ActionButton';
import Icon from '../../../shared/components/Icon';

type Props = {
  isSelected: boolean;
  shouldRenderOptions: boolean;
  onRemove: () => void;
};

function CursorOptions({
  children,
  shouldRenderOptions,
  isSelected,
  onRemove,
}: PropsWithChildren<Props>) {
  if (!shouldRenderOptions) {
    return <>{children}</>;
  }

  return (
    <Popover isOpen={isSelected} placement="bottom-end" strategy="absolute">
      <PopoverTrigger>{children}</PopoverTrigger>
      <Box zIndex="dropdown" position="relative">
        <PopoverContent width="min-content !important">
          <PopoverArrow />
          <PopoverBody padding="0">
            <ActionButton
              icon={<Icon icon="icon-delete" />}
              aria-label="Excluir componente"
              title="Excluir componente"
              onClick={onRemove}
            />
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>
  );
}

export default CursorOptions;
