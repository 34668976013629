import { CanceledError } from 'axios';
import api, {
  clearAuthorization,
  privateApiAbort,
  privateApi,
  setAuthorization,
  resetPrivateApiAbort,
} from '../../api';
import { IUser, IUserResponse } from './types/user';
import { IAuthPreResponse, IAuthResponse } from './types/auth';
import { isUnauthorized } from '../utils/requestHelpers';
import createService from '../store/serverState';
import { Getter, Setter } from '../store/store.types';
import history from '../../history';
import { ISuccessResponse } from './types';

const USER_KEY = 'USER_KEY';

const getAuth = () => {
  const serializedData = localStorage.getItem(USER_KEY);

  return serializedData === null ? serializedData : (JSON.parse(serializedData) as IAuthResponse);
};

const setAuth = (data: IAuthResponse) => {
  localStorage.setItem(USER_KEY, JSON.stringify(data));
  setAuthorization(data.tokens.accessToken);
};

const clearAuth = () => {
  localStorage.removeItem(USER_KEY);
  clearAuthorization();
};

const authenticate = async (payload: IUser) => {
  clearAuth();
  const { data } = await api.post<ISuccessResponse<IAuthPreResponse>>('/auth/login', payload);
  const {
    data: { user, ...tokens },
  } = data;

  const transformedData: IAuthResponse = {
    ...user,
    tokens: { ...tokens, createdAt: new Date().toISOString() },
  };
  setAuth(transformedData);
  resetPrivateApiAbort();

  return transformedData;
};

const me = async () => {
  const user = getAuth();

  if (!user) {
    clearAuthorization();
    return null;
  }

  setAuthorization(user.tokens.accessToken);
  const { data } = await privateApi.get<ISuccessResponse<{ user: IUserResponse }>>(
    `/users/${user.id}`
  );

  // getAuth again as we don't know if the token has refreshed
  const transformedData = { ...getAuth()!, ...data.data.user };
  setAuth(transformedData);

  return transformedData;
};

const refresh = async (refreshToken: string) => {
  try {
    const { data } = await api.post<ISuccessResponse<IAuthPreResponse>>('/auth/refresh', {
      refreshToken,
    });
    const {
      data: { user, ...tokens },
    } = data;

    const transformedData = { ...user, tokens: { ...tokens, createdAt: new Date().toISOString() } };
    setAuth(transformedData);

    return transformedData;
  } catch (error) {
    clearAuth();
    throw error;
  }
};

const createAuthInterceptor = (onExpired: () => void) => {
  const unsubRequest = privateApi.interceptors.request.use(
    async (config) => {
      const { tokens } = getAuth()!;
      const now = new Date();
      const expiresInAccess = new Date(tokens.createdAt);
      const expiresInRefresh = new Date(tokens.createdAt);
      expiresInAccess.setSeconds(expiresInAccess.getSeconds() + tokens.accessTokenExpiresIn);
      expiresInRefresh.setSeconds(expiresInRefresh.getSeconds() + tokens.refreshTokenExpiresIn);

      if (now >= expiresInRefresh) {
        onExpired();
        privateApiAbort();
        throw new CanceledError();
      }

      if (now >= expiresInAccess) {
        const data = await refresh(tokens.refreshToken);

        if (!config.headers) {
          config.headers = {};
        }
        config.headers.Authorization = `Bearer ${data.tokens.accessToken}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  const unsubResponse = privateApi.interceptors.response.use(
    (config) => config,
    (error) => {
      if (isUnauthorized(error)) {
        onExpired();
        privateApiAbort();
      }

      return Promise.reject(error);
    }
  );

  return () => {
    privateApi.interceptors.request.eject(unsubRequest);
    privateApi.interceptors.response.eject(unsubResponse);
  };
};

export const createAuthEndpoints = (set: Setter, get: Getter) => {
  const createEndpoint = createService({ set, getSlice: ({ auth }) => auth, prefix: 'auth' });

  return {
    login: createEndpoint(
      'login',
      async (user: IUser) => {
        const data = await authenticate(user);

        history.push('/');
        await get().api.initializeApp();

        return data;
      },
      { placeholder: null }
    ),
  };
};

export default {
  authenticate,
  me,
  clearAuth,
  getAuth,
  refresh,
  setAuth,
  createAuthInterceptor,
};
