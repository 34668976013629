export const stripExtension = (fileName: string) => fileName.split('.').slice(0, -1).join('.');
export const getImageType = (imageName: string) => imageName.split('.').pop();
export const getExtensionFromFile = (file: File) => file.type.split('/').pop();

export const imageReceived = (img: HTMLImageElement) =>
  new Promise<Blob | null>((resolve) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const type = `image/${getImageType(img.src)}`;

    canvas.width = img.width;
    canvas.height = img.height;
    context!.drawImage(img, 0, 0);

    canvas.toBlob(resolve, type);
  });

export const getBase64 = (file: File) =>
  new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));

    reader.readAsDataURL(file);
  });
