import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { UpsertMedia } from '../../../shared/services/types/media';
import ActionButton from '../../../shared/components/ActionButton';
import AutoComplete, { AutocompleteItem } from '../../../shared/components/Form/AutoComplete';
import Header, { HeaderDescription, HeaderTitle } from '../../../shared/components/Header';
import Icon from '../../../shared/components/Icon';
import toast from '../../../shared/components/Toast';
import useStore from '../../../shared/store';
import { getDirtyValues } from '../../../shared/utils/form';
import { yupPortuguese } from '../../../shared/utils/i18n';
import useDebounce from '../../../shared/hooks/useDebounce';
import { UpdateMedia } from './UpdateMedia';

interface IForm {
  name: string;
  category: { name: string; value: string } | null;
}

export type Source = { url?: string | null; file?: File | null };

const COPY_TOAST_ID = 'COPY_TOAST_ID';
const defaultValues = { name: '', category: null };

yup.setLocale(yupPortuguese);

const schema = yup.object().shape({
  name: yup.string().required(),
  categories: yup.array(yup.object().shape({ name: yup.string(), value: yup.string() })),
});

const getCategoryErrors = (
  filteredOptions: AutocompleteItem[],
  search: string,
  onCreate: () => void
) => {
  if (search.length > 0 && filteredOptions.length === 0) {
    return (
      <Box>
        Esta categoria não existe.{' '}
        <Button fontSize="15px" textDecoration="underline" variant="link" onClick={onCreate}>
          Clique aqui para criar essa categoria
        </Button>
      </Box>
    );
  }

  return null;
};

function MediaInfo({ name, value }: { name: string; value: string }) {
  return (
    <Box>
      <Text fontWeight="bold">{name}</Text>
      <Text>{value}</Text>
    </Box>
  );
}

function EditMediaModal() {
  const debounce = useDebounce();
  const location = useLocation();
  const { categoryId } = useParams();

  const { register, control, formState, reset, getValues, setValue, handleSubmit } = useForm<IForm>(
    {
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    }
  );

  const { currentId } = useStore((state) => state.media.upsert);
  const { isLoading } = useStore((state) => state.media.saveMedia);
  const {
    saveCategory,
    setEditMedia,
    setDeleteMedia,
    fetchCategoriesAutocomplete,
    setEditCategory,
    saveMedia,
  } = useStore((state) => state.api);

  const savedCategory = useStore((state) => state.categories.saveCategory.data);
  const mediaLibrary = useStore((state) => state.media.fetchMedia.data.media);

  const { data: categoryList, isLoading: isLoadingCategories } = useStore(
    (state) => state.categories.fetchCategoriesAutocomplete
  );

  const media = useMemo(
    () => mediaLibrary.find((item) => item.id === currentId),
    [mediaLibrary, currentId]
  );

  const imageSrc = media?.download || '';
  const { hasCopied, onCopy } = useClipboard(imageSrc);

  useEffect(() => {
    if (media) {
      const { category } = media;
      reset({ name: media.name, category: { name: category.name, value: category.id } });
    }
  }, [media, reset]);

  const isOpen = !!currentId;

  useEffect(() => {
    if (savedCategory && isOpen) {
      setValue('category', { value: savedCategory.id, name: savedCategory.name });

      saveCategory.actions.dispatchSuccess(null);
    }
  }, [savedCategory, saveCategory.actions, isOpen, getValues, setValue]);

  if (!media) {
    return null;
  }

  const { dirtyFields, isDirty, isValid } = formState;

  const mediaCreatedAt = new Date(media.createdAt);

  const categoriesOptions = categoryList.map((category) => ({
    name: category.name,
    value: category.id,
  }));

  const onClose = () => setEditMedia(null);
  const openDeleteModal = () => setDeleteMedia(media.id);
  const handleLinkCopy = () => {
    onCopy();

    if (!toast.isActive(COPY_TOAST_ID)) {
      toast({ status: 'info', title: 'Link da imagem copiado' });
    }
  };

  const onSubmit = handleSubmit(async (values) => {
    const isShowAll = location.pathname === '/midia';
    const dirtyValues: Partial<IForm> = getDirtyValues(dirtyFields, values);

    let saveValues: UpsertMedia = { id: currentId! };
    if (dirtyValues.category) {
      saveValues = { ...saveValues, ...dirtyValues, category: dirtyValues.category.value };
    } else {
      saveValues.name = dirtyValues.name;
    }

    await saveMedia(saveValues, categoryId, isShowAll);

    onClose();
  });

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="100%" maxWidth="1000px">
          <ModalHeader padding="32px 32px 12px">
            <Header>
              <HeaderTitle icon="icon-edit-alt">
                Editar mídia
                <HeaderDescription>
                  Insira as informações abaixo para editar a mídia
                </HeaderDescription>
              </HeaderTitle>
            </Header>
          </ModalHeader>
          <ModalCloseButton size="lg" color="gray.40" />
          <ModalBody paddingBottom="32px">
            <Box as="form" onSubmit={onSubmit}>
              <Flex>
                <Box flex="1" paddingRight="40px" minWidth="62%">
                  <Box height="380px">
                    <Image objectFit="contain" width="100%" height="350px" src={imageSrc} />
                    <Flex width="max-content" marginLeft="auto">
                      <UpdateMedia parentClose={onClose} />
                      <ActionButton
                        as="a"
                        target="_blank"
                        href={imageSrc}
                        download={`${media.name}.${media.extension}`}
                        icon={<Icon icon="icon-download" />}
                        aria-label="Baixar imagem"
                        title="Baixar imagem"
                      />
                      <ActionButton
                        icon={<Icon icon="icon-link" />}
                        aria-label="copiar link da imagem"
                        title="Copiar link da imagem"
                        disabled={hasCopied}
                        onClick={handleLinkCopy}
                      />
                      <ActionButton
                        icon={<Icon icon="icon-delete" />}
                        aria-label="Deletar imagem"
                        title="Deletar imagem"
                        onClick={openDeleteModal}
                      />
                    </Flex>
                  </Box>
                </Box>
                <Flex flex="1" flexDirection="column">
                  <Flex
                    width="100%"
                    flexDirection="column"
                    justifyContent="space-evenly"
                    height="350px"
                    backgroundColor="gray.5"
                    alignSelf="self-start"
                    padding="0 20px"
                    borderRadius="md"
                  >
                    <Text fontWeight="bold" fontSize="lg">
                      Detalhes do arquivo
                    </Text>
                    <MediaInfo
                      name="Data de criação:"
                      value={mediaCreatedAt.toLocaleString('pt-BR')}
                    />
                    <MediaInfo name="Tamanho:" value={`${Math.floor(media.length / 10 ** 3)}KB`} />
                    <MediaInfo name="Dimensões:" value={`${media.width}x${media.height}`} />
                    <MediaInfo name="Extensão:" value={media.extension.toUpperCase()} />
                  </Flex>
                </Flex>
              </Flex>
              <Flex marginY="20px">
                <FormControl paddingRight="40px" minWidth="62%" flex="6">
                  <FormLabel>Categoria</FormLabel>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <AutoComplete
                        removeable={false}
                        placeholder="Escolha uma categoria"
                        selectedItems={value ? [value] : []}
                        options={categoriesOptions}
                        isLoading={isLoadingCategories}
                        onChange={(selectedItems) => onChange(selectedItems.pop())}
                        onBlur={onBlur}
                        onSearch={debounce(fetchCategoriesAutocomplete, 500)}
                        renderHelperMessage={(options, search) => (
                          <FormHelperText>
                            {getCategoryErrors(options, search, () => setEditCategory('', search))}
                          </FormHelperText>
                        )}
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Nome</FormLabel>
                  <Input {...register('name')} />
                </FormControl>
              </Flex>
              <Button
                type="submit"
                display="block"
                colorScheme="primary"
                width="38%"
                marginLeft="auto"
                disabled={isLoading || !isDirty || !isValid}
                isLoading={isLoading}
              >
                Salvar
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditMediaModal;
