import {
  Box,
  Center,
  FormLabel,
  forwardRef,
  Image,
  Input,
  InputProps,
  Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import imageUploadSource from '../../../assets/images/image-upload.png';
import { transientOptions } from '../../utils';
import Icon from '../Icon';

type UploadFileProps = {
  showImage?: boolean;
  renderImage?: (source: string) => JSX.Element;
  onChange: (file?: FileList | null) => void;
} & Omit<InputProps, 'type' | 'style' | 'display' | 'onDrop' | 'onChange'>;

const UploadLabel = styled(FormLabel, transientOptions)`
  transform: ${({ $isActive }: { $isActive: boolean }) => $isActive && 'scale(0.98)'};
  opacity: ${({ $isActive }: { $isActive: boolean }) => $isActive && '0.6'};

  :active {
    transform: scale(0.98);
  }
`;

const UploadTextBox = styled(Box)`
  :hover {
    & :last-child {
      background-color: var(--chakra-colors-primary-600);
    }
  }
`;

const defaultRenderImage: UploadFileProps['renderImage'] = (source) => (
  <Image marginX="auto" borderRadius="8px" maxHeight="300px" maxWidth="100%" src={source} />
);

const UploadFile = forwardRef((props: UploadFileProps, ref) => {
  const {
    placeholder,
    showImage = true,
    onChange,
    renderImage = defaultRenderImage,
    ...rest
  } = props;

  const [source, setSource] = useState<string | null>(null);
  const [isActive, setActive] = useState(false);

  const showText = placeholder || isActive;

  const handleSourceChange = (files?: FileList | null) => {
    if (onChange) {
      onChange(files);
    }
    if (files) {
      const reader = new FileReader();
      if (files.item(0)) {
        reader.readAsDataURL(files.item(0)!);
      }

      reader.addEventListener('load', () => setSource(reader.result as string));
      return reader;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSourceChange(event.target.files);
  };

  const handleDragOver: React.DragEventHandler<HTMLLabelElement> = (event) => {
    event.preventDefault();
    setActive(true);
  };

  const handleDragLeave = () => setActive(false);

  const handleDrop: React.DragEventHandler<HTMLLabelElement> = (event) => {
    event.preventDefault();
    handleSourceChange(event.dataTransfer.files);
    setActive(false);
  };

  const renderUploadText = (text = '') => (
    <Text
      padding="0 80px"
      fontSize="22px"
      fontWeight="bold"
      lineHeight="1.2"
      color="gray.90"
      marginBottom="12px"
      marginX="auto"
    >
      {text}
    </Text>
  );

  const inputView = (
    <UploadTextBox
      width="100%"
      padding="10px 20px"
      boxShadow="0px 5px 20px rgba(197, 197, 197, 0.32)"
    >
      <Image
        alt="Adicione uma ou várias imagens"
        src={imageUploadSource}
        marginX="auto"
        marginBottom="12px"
      />
      {showText && renderUploadText(isActive ? 'Solte a imagem para selecioná-la' : placeholder)}
      <Box
        width="min-content"
        marginX="auto"
        lineHeight="0"
        backgroundColor="primary.100"
        padding="10px"
        borderRadius="50%"
      >
        <Icon icon="icon-add" color="white" fontSize="35px" />
      </Box>
    </UploadTextBox>
  );

  return (
    <UploadLabel
      cursor="pointer"
      textAlign="center"
      margin="0"
      borderRadius="8px"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      $isActive={isActive}
    >
      <Input multiple ref={ref} type="file" display="none" onChange={handleChange} {...rest} />
      <Center minHeight="200px">
        {source && showImage && !isActive ? renderImage(source) : inputView}
      </Center>
    </UploadLabel>
  );
});

UploadFile.displayName = 'UploadFile';

export default UploadFile;
