import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react';

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'bold',
    _disabled: {
      backgroundColor: 'gray.80',
      color: 'white',
    },
    _focusVisible: {
      boxShadow: 'none',
    },
  },

  variants: {
    outline: () => ({
      color: 'primary.100',
      borderColor: 'primary.100',
      _hover: { backgroundColor: 'gray.5' },
    }),
    solid: () => ({
      _hover: {
        _disabled: {
          backgroundColor: 'gray.80',
          color: 'white',
          border: 'none',
        },
      },
    }),
  },
};

const Input: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        height: '56px',
        borderRadius: '8px',
        caretColor: 'var(--chakra-colors-primary-100)',
        backgroundColor: 'white',
        borderColor: 'gray.20',
        _hover: {
          borderColor: 'primary.50',
        },
        _placeholder: {
          fontWeight: 'normal',
          color: 'gray.80',
        },
        _focusVisible: {
          backgroundColor: 'white',
          borderColor: 'primary.50',
          borderWidth: '2px',
          boxShadow: 'none',
        },
      },
    },
    filled: {
      field: {
        backgroundColor: 'white',
        _hover: {
          backgroundColor: 'white',
        },
        _focusVisible: {
          backgroundColor: 'white',
          borderColor: 'primary.50',
        },
        _placeholder: {
          color: 'gray.80',
        },
      },
    },
  },
};

const Textarea: ComponentStyleConfig = {
  variants: {
    outline: {
      borderRadius: '8px',
      caretColor: 'var(--chakra-colors-primary-100)',
      backgroundColor: 'white',
      borderColor: 'gray.20',
      _hover: {
        borderColor: 'primary.50',
      },
      _placeholder: {
        fontWeight: 'normal',
        color: 'gray.80',
      },
      _focusVisible: {
        backgroundColor: 'white',
        borderColor: 'primary.50',
        borderWidth: '2px',
        boxShadow: 'none',
      },
    },
  },
};

const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 'sm',
    color: 'gray.80',
    _focus: { color: 'primary.100' },
  },
};

const Select: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        borderRadius: '8px',
        backgroundColor: 'white',
        borderColor: 'gray.20',
        _hover: {
          borderColor: 'primary.50',
        },
        _placeholder: {
          fontWeight: 'normal',
          color: 'gray.80',
        },
        _focusVisible: {
          backgroundColor: 'white',
          borderColor: 'primary.50',
          borderWidth: '2px',
          boxShadow: 'none',
        },
      },
    },
  },
};

const Breadcrumb: ComponentStyleConfig = {
  baseStyle: {
    link: {
      fontSize: '20px',
    },
  },
};

const theme = extendTheme({
  colors: {
    black: '#302D2D',

    primary: {
      20: '#FFD1EC',
      30: '#FFBAE4',
      40: '#FFA3DA',
      50: '#FF8CD1',
      60: '#FF69C2',
      80: '#FF46B5',
      100: '#EC008C',
      200: '#FF8ACF',
      300: '#FF5CBD',
      400: '#FF2EAA',
      500: '#FF0097',
      600: '#CC0079',
      700: '#99005B',
      800: '#66003D',
      900: '#33001E',
    },

    yellow: {
      20: '#FFE9D5',
      50: '#FFC996',
      80: '#FFA857',
      100: '#FF922D',
    },

    red: {
      20: '#FFDBDD',
      50: '#FFA5AB',
      80: '#FF6F78',
      100: '#FF4B56',
      200: '#FF8A91',
      300: '#FF5C66',
      400: '#FF2E3B',
      500: '#FF0010',
      600: '#CC000C',
      700: '#990009',
      800: '#660006',
      900: '#330003',
    },

    green: {
      5: '#F4FDF9',
      20: '#CCD6E5',
      40: '#B5F4D6',
      50: '#8098BF',
      70: '#6CE9AC',
      100: '#2DDF89',
      200: '#99EFC6',
      300: '#71E9AF',
      400: '#4AE399',
      500: '#22DD83',
      600: '#1BB169',
      700: '#14854E',
      800: '#0D5934',
      900: '#072C1A',
    },

    blue: {
      5: '#F2F5F9',
      20: '#CCD6E5',
      40: '#99ACCC',
      50: '#8098BF',
      70: '#4D6FA6',
      100: '#B8D3FF',
      200: '#8AB7FF',
      300: '#5C9AFF',
      400: '#2E7EFF',
      500: '#0062FF',
      600: '#004ECC',
      700: '#003B99',
      800: '#002766',
      900: '#001433',
    },

    gray: {
      5: '#F4F4F4',
      10: '#EBEAEA',
      20: '#D6D5D5',
      40: '#ACABAB',
      50: '#989696',
      60: '#838181',
      70: '#6E6C6C',
      80: '#595757',
      90: '#454242',
    },
  },

  sizes: {
    10: '44px',
  },

  breakpoints: {
    xl: '1205px',
    '2xl': '1600px',
  },

  components: {
    Input,
    Textarea,
    Button,
    FormLabel,
    Select,
    Breadcrumb,
  },

  fonts: {
    heading: 'Nunito',
    body: 'Nunito',
  },

  styles: {
    global: {
      'html, body, #root': {
        height: '100%',
      },
      body: {
        margin: '0',
      },
      '.swiper-wrapper': {
        alignItems: 'center',
      },
      '.swiper-pagination-bullet': {
        border: '1px solid var(--chakra-colors-black)',
      },
      '.swiper-pagination-bullet-active': {
        backgroundColor: 'var(--chakra-colors-white)',
      },
    },
  },
});

export default theme;
