import { createCategoriesEndpoints } from '../../services/CategoryService';
import { createSliceState } from '../serverState';
import { SliceCreator, CategoriesSlice } from '../store.types';

const createCategoriesSlice: SliceCreator<CategoriesSlice> = (set, get) => ({
  ...createSliceState(() => createCategoriesEndpoints(set, get)),
  initialImages: [],
  upsert: { currentId: null, initialName: null },
  deletion: { currentId: null },
});

export default createCategoriesSlice;
