import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import imageSource from '../../../assets/images/image-upload.png';
import toast from '../../../shared/components/Toast';
import useStore from '../../../shared/store';

interface UploadFileProps {
  onClose: () => void;
}

export function UploadFile({ onClose }: UploadFileProps) {
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  const id = useStore((state) => state.media.upsert.currentId);

  useEffect(() => {
    const getStorage = localStorage.getItem('USER_KEY');
    if (!getStorage) return;

    const parsetToGetToken = JSON.parse(getStorage);

    setToken(parsetToGetToken.tokens.accessToken);
  }, []);

  const handleImageChange = (event: any) => {
    setImage(event.target.files?.[0]);
  };

  const handleUpdateImageSubmit = async () => {
    setLoading(true);
    const data = new FormData();

    data.append('file', image!);
    data.append('mediaId', String(id));

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/media/replace`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });

      if (response.status === 403) {
        toast({ status: 'error', title: `Você não possui permissão para realizar a operação` });
      } else if (response.status !== 200) {
        toast({ status: 'error', title: `Error ao atualizar imagem!` });
      } else {
        toast({ status: 'success', title: 'Imagem atualizada com sucesso!' });
      }

      setLoading(false);
      onClose();
    } catch (error) {
      toast({ status: 'error', title: `Error ao atualizar imagem! ${error}` });
    }
  };

  if (loading) {
    return (
      <Center h="400px">
        <Spinner color="primary.500" size="xl" />
      </Center>
    );
  }

  return (
    <FormControl>
      <FormLabel cursor="pointer" shadow="xl" py="5">
        <Input type="file" onChange={handleImageChange} accept="image/*" display="none" />
        <Center>
          {image ? (
            <Image src={URL.createObjectURL(image)} />
          ) : (
            <Flex flexDir="column">
              <Image src={imageSource} />
              <Text color="black.100" textAlign="center" fontSize="22px" fontWeight="700">
                Clique para substitur uma mídia
              </Text>
            </Flex>
          )}
        </Center>
      </FormLabel>

      <Flex mt="4">
        <Button flex="1" colorScheme="primary" onClick={handleUpdateImageSubmit} disabled={!image}>
          Salvar
        </Button>
      </Flex>
    </FormControl>
  );
}
