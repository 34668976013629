import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { passwordConfirmSchema, passwordSchema } from '../../../shared/schemas/password';
import useStore from '../../../shared/store';
import { yupPortuguese } from '../../../shared/utils/i18n';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const defaultValues = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};

yup.setLocale(yupPortuguese);

const formSchema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: passwordSchema,
  newPasswordConfirm: passwordConfirmSchema('newPassword'),
});

function NewPasswordModal({ isOpen, onClose }: Props) {
  const { isLoading } = useStore((state) => state.users.newPassword);
  const { newPassword } = useStore((state) => state.api);

  const { register, formState, handleSubmit, reset } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  const { isValid, errors } = formState;

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, reset]);

  const onSubmit = handleSubmit(async (values) => {
    await newPassword(values);
    onClose();
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold">Redefinição de senha</ModalHeader>
        <ModalBody>
          <Box as="form" id="reset-password-form" onSubmit={onSubmit}>
            <FormControl isInvalid={!!errors.currentPassword} marginBottom="20px">
              <FormLabel>Senha atual</FormLabel>
              <Input
                type="password"
                placeholder="Digite a senha atual"
                {...register('currentPassword')}
              />
              <FormErrorMessage>{errors.currentPassword?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.newPassword} marginBottom="20px">
              <FormLabel>Nova senha</FormLabel>
              <Input
                type="password"
                placeholder="Digite a nova senha"
                {...register('newPassword')}
              />
              <FormErrorMessage>{errors.newPassword?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.newPasswordConfirm} marginBottom="10px">
              <FormLabel>Confirmação da nova senha</FormLabel>
              <Input
                type="password"
                placeholder="Confirme a nova senha"
                {...register('newPasswordConfirm')}
              />
              <FormErrorMessage>{errors.newPasswordConfirm?.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="outline" marginRight="10px">
            Cancelar
          </Button>
          <Button
            type="submit"
            form="reset-password-form"
            colorScheme="primary"
            disabled={!isValid}
            isLoading={isLoading}
          >
            Atualizar senha
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NewPasswordModal;
