export interface IErrorResponse {
  status: 'success' | 'error';
  data: {
    code: number;
    error: string;
    message: string;
  };
}

export interface IServerErrorResponse {
  status: 'error';
  message: string;
  data: null;
}

export interface ISuccessResponse<T> {
  status: 'success';
  data: T;
}

export interface IPagination {
  current: number;
  total: number;
  perPage: number;
}

export type BaseParams<T = any> = {
  current?: number;
  filter?: string;
  order?: Order;
  perPage?: number;
  sort?: keyof T;
};

export interface ISuccessResponseList<T extends { pagination: IPagination }>
  extends ISuccessResponse<T> {}

export enum Order {
  DESC = 'desc',
  ASC = 'asc',
}

export enum DefaultSort {
  CREATED_AT = 'createdAt',
  NAME = 'name',
}
