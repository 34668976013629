import { createAppEndpoints } from '../../services/AppService';
import { createSliceState } from '../serverState';
import { AppSlice, SliceCreator } from '../store.types';

const createAppSlice: SliceCreator<AppSlice> = (set, get) => ({
  ...createSliceState(() => createAppEndpoints(set, get)),
  layoutColor: 'gray.5',
  isSidebarExpanded: true,
  hideExpansionButton: false,
});

export default createAppSlice;
