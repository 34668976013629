import { Box, Button, Center, Flex, SimpleGrid } from '@chakra-ui/react';
import React, { ChangeEventHandler, useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import { Select } from '../../shared/components/Form';
import Header, { HeaderDescription, HeaderTitle } from '../../shared/components/Header';
import Icon from '../../shared/components/Icon';
import Search from '../../shared/components/Search';
import { TextEllipsis } from '../../shared/components/Typography';
import useDebounce from '../../shared/hooks/useDebounce';
import { Order, DefaultSort } from '../../shared/services/types';
import CategoryService from '../../shared/services/CategoryService';
import useStore from '../../shared/store';
import { PageBox, PageSpinner, TablePagination } from '../../shared/styles';
import { capitalizeFirst } from '../../shared/utils';
import CategoryItem from './components/CategoryItem';
import { CategoryCard, renderImages } from './shared';

const SHOW_ALL_LABEL = 'todas as imagens';

function Gallery() {
  const debounce = useDebounce();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => CategoryService.getParams(searchParams), [searchParams]);

  const initialImages = useStore((state) => state.categories.initialImages);
  const { data, isLoading } = useStore((state) => state.categories.fetchCategories);
  const { isLoading: isLoadingDelete } = useStore((state) => state.categories.deleteCategory);
  const { currentId: deleteId } = useStore((state) => state.categories.deletion);
  const api = useStore((state) => state.api);

  useEffect(() => {
    api.fetchCategories(params);
  }, [api, params]);

  const { current, perPage, total } = data.pagination;

  const openCreateCategoryModal = () => api.setEditCategory('');
  const onClose = () => api.setDeleteCategory(null);
  const handleSearch = debounce((filter: string) => setSearchParams({ filter }, location), 500);

  const handleSortCreatedAt: ChangeEventHandler<HTMLSelectElement> = (e) =>
    setSearchParams({ order: e.target.value, sort: DefaultSort.CREATED_AT }, location);

  const handleSortName: ChangeEventHandler<HTMLSelectElement> = (e) =>
    setSearchParams({ order: e.target.value, sort: DefaultSort.NAME }, location);

  const handleDelete = async () => {
    await api.deleteCategory(deleteId!);
    onClose();
  };

  const loadingView = (
    <Center>
      <PageSpinner marginBottom="5" />
    </Center>
  );

  const showAllView = (
    <CategoryCard height="300px">
      {renderImages(initialImages, '/midia', location)}
      <Box margin="18px 0" textAlign="start" padding="0 12px">
        <TextEllipsis maxWidth="100%" marginBottom="12px">
          {capitalizeFirst(SHOW_ALL_LABEL)}
        </TextEllipsis>
      </Box>
    </CategoryCard>
  );

  const categoryList = (
    <SimpleGrid
      columns={data.categories.length > 4 ? undefined : { md: 2, lg: 2, xl: 3, '2xl': 4 }}
      minChildWidth={data.categories.length > 4 ? '300px' : undefined}
      spacing="50px"
    >
      {initialImages.length > 0 && data.pagination.current === 0 && showAllView}
      {data.categories.map((item) => (
        <CategoryItem key={item.id} item={item} />
      ))}
    </SimpleGrid>
  );

  return (
    <PageBox paddingBottom="40px">
      <Header marginBottom="20px">
        <HeaderTitle icon="icon-image">
          Biblioteca de mídia
          <HeaderDescription>Acervo de mídias para uso em campanhas e posts</HeaderDescription>
        </HeaderTitle>
        <Flex justifyContent="space-between">
          <Search
            placeholder="Pesquisar categoria"
            width="256px"
            onSearch={handleSearch}
            onChange={handleSearch}
          />
          <Button
            padding="12px 24px"
            width="256px"
            colorScheme="primary"
            size="lg"
            onClick={openCreateCategoryModal}
          >
            <Icon icon="icon-add" marginRight="5px" fontSize="xl" />
            Criar categoria
          </Button>
        </Flex>
      </Header>
      <Flex marginBottom="40px" justifyContent="flex-end">
        <Select
          key={`${params.sort} createdAt`}
          marginRight="24px"
          width="256px"
          placeholder="Ordenar por data de criação"
          isTouched={params.sort === DefaultSort.CREATED_AT}
          value={params.sort === DefaultSort.CREATED_AT ? params.order : undefined}
          onChange={handleSortCreatedAt}
        >
          <option value={Order.DESC}>Mais novo</option>
          <option value={Order.ASC}>Mais antigo</option>
        </Select>
        <Select
          key={`${params.sort} name`}
          width="256px"
          placeholder="Ordenar por nome"
          isTouched={params.sort === DefaultSort.NAME}
          value={params.sort === DefaultSort.NAME ? params.order : undefined}
          onChange={handleSortName}
        >
          <option value={Order.ASC}>Ordem alfábetica (A-Z)</option>
          <option value={Order.DESC}>Ordem alfábetica (Z-A)</option>
        </Select>
      </Flex>
      {isLoading ? loadingView : categoryList}
      <TablePagination
        marginTop="24px"
        path="/midia/galeria"
        current={current}
        perPage={perPage}
        total={total}
      />
      <ConfirmationModal isOpen={!!deleteId} onClose={onClose} isCancelDisabled={isLoadingDelete}>
        <Button
          onClick={handleDelete}
          variant="solid"
          colorScheme="primary"
          isLoading={isLoadingDelete}
          disabled={isLoadingDelete}
        >
          Excluir
        </Button>
      </ConfirmationModal>
    </PageBox>
  );
}

export default Gallery;
