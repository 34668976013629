import { Box, Divider, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { addMinutes, differenceInMinutes } from 'date-fns';
import { QuestionIcon } from '@chakra-ui/icons';
import deviceLayoutImage from '../../../../assets/images/device-layout.png';
import Icon from '../../../../shared/components/Icon';
import { useCampaingCountdown } from '../../../../shared/store/useCampaignCountdown';
import { Item } from '../../../../shared/services/types/campaign';

interface FlagPromotionProps {
  hasBottomSheet?: boolean;
  item?: Partial<Item>;
}

interface PreviewCountdownProps {
  item?: Partial<Item>;
}

function FlagPromotion({ hasBottomSheet, item }: FlagPromotionProps) {
  const { currentDate } = useCampaingCountdown((state) => state);
  const { watch } = useFormContext();

  const initialDate = currentDate;
  const selectedEndDate = new Date(watch('endDate') || addMinutes(new Date(initialDate), 60));

  const diffMinutes = differenceInMinutes(selectedEndDate, new Date(currentDate));
  const hoursAmount = Math.floor(diffMinutes / 60);
  const minutesAmout = diffMinutes % 60;

  const hours = String(hoursAmount).padStart(1, '0');
  const minutes = String(minutesAmout).padStart(2, '0');

  return (
    <Flex
      width="100%"
      bg={watch('backgroundColor') || item?.countdown?.backgroundColor || '#EC008C'}
      alignItems="center"
      justifyContent="center"
      h="34.34px"
      p="0.35rem"
    >
      <Text
        color={watch('fontColor') || item?.countdown?.fontColor || '#FFFFFF'}
        fontWeight="600"
        fontSize="15px"
      >
        {watch('label') ? watch('label') : 'Esta promoção termina em'}{' '}
        <Text as="span" fontWeight="900">
          {`${hours}h${minutes}m59s`}
        </Text>
        {hasBottomSheet ? (
          <Text as="span" ml="1rem">
            <QuestionIcon />
          </Text>
        ) : null}
      </Text>
    </Flex>
  );
}

export function PreviewCountdown({ item }: PreviewCountdownProps) {
  const { watch } = useFormContext();

  return (
    <Box
      as="section"
      w="100%"
      h="100%"
      display="flex"
      flexDirection="column"
      alignContent="center"
      justifyContent="center"
    >
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Box
          width="335px"
          height="324px"
          display="flex"
          alignContent="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Text width="400px" fontWeight="700" fontSize="18px" mb="1rem">
            Pré-visualização da contagem regressiva
          </Text>
          <FlagPromotion item={item} hasBottomSheet={watch('hasHelperModal')} />
          {watch('hasHelperModal') || item?.countdown?.helper ? (
            <Flex alignItems="center" justifyContent="center" flexDirection="column" mt="34px">
              <Text width="400px" fontWeight="700" fontSize="18px" mb="1rem" textAlign="center">
                Pré-visualização do modal de ajuda
              </Text>
              <Box position="relative">
                <Image width="284.05px" height="544px" src={deviceLayoutImage} />
                <Box
                  bg="white"
                  position="absolute"
                  bottom="-1px"
                  width="257px"
                  margin="13px"
                  right="1px"
                  rounded="2xl"
                  p="1rem"
                >
                  <Box>
                    <Flex alignItems="center" justifyContent="space-between" mb="1rem">
                      <Box />
                      <Divider width="32px" border="1px solid #D6D5D5" />
                      <Icon icon="icon-close" color="#D6D5D5" />
                    </Flex>

                    <Flex flexDirection="column" alignItems="center" justify="center">
                      <Text
                        as="h2"
                        fontFamily="Pluto"
                        fontSize="15px"
                        width="100%"
                        textAlign="center"
                        mb="10px"
                      >
                        {watch('title-modal')}
                      </Text>
                      <Text
                        as="p"
                        lineHeight="14px"
                        fontSize="13px"
                        width="100%"
                        fontWeight="700"
                        color="#A09E9E"
                        textAlign="center"
                      >
                        {watch('description-modal')}
                      </Text>
                      <Box
                        width="100%"
                        rounded="md"
                        p="10px 16px"
                        background="primary.100"
                        mt="20px"
                        textAlign="center"
                      >
                        <Text fontWeight="800" color="white">
                          {watch('button-text')}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Box>
            </Flex>
          ) : (
            <Image src={item?.download} />
          )}
        </Box>
      </Flex>
    </Box>
  );
}
