import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import useStore from '../../../../shared/store';
import Header, { HeaderDescription, HeaderTitle } from '../../../../shared/components/Header';
import { yupPortuguese } from '../../../../shared/utils/i18n';
import {
  getBase64,
  getExtensionFromFile,
  stripExtension,
} from '../../../../shared/utils/imageUpload';
import BatchUploadToast from './components/BatchUploadToast';
import MediaForm from './components/MediaForm';
import { IForm } from './CreateMediaModal.types';
import { BACKEND_ERROR_TYPE, defaultValues } from './shared';

yup.setLocale(yupPortuguese);

const isLargerThan5MB = (value: number) => value >= 5 * 10 ** 6;
const isMediaTypeValid = (file: File) => {
  const VALID_TYPES = ['jpg', 'jpeg', 'png'];

  return VALID_TYPES.some((type) => getExtensionFromFile(file) === type);
};

const formSchema = yup.object().shape({
  isURL: yup.boolean(),
  files: yup.array(
    yup
      .object()
      .required()
      .shape({
        name: yup.string().required(),
        source: yup.object().shape({
          file: yup.mixed().required(),
          url: yup.string(),
        }),
      })
      .test(
        'validate-file-size',
        'O arquivo deve ser menor que 5MB',
        (value) => !!value.source?.file && !isLargerThan5MB(value.source.file.size)
      )
      .test(
        'validate-file-media-type',
        'O arquivo deve ser do tipo .JPG, .JPEG ou .PNG',
        (value) => !!value.source?.file && isMediaTypeValid(value.source.file)
      )
  ),
});

function CreateMediaModal() {
  const { categoryId } = useParams();
  const location = useLocation();
  const methods = useForm<IForm>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
  });
  const { handleSubmit, setValue, setError, reset } = methods;

  const { currentId, initialFiles } = useStore((state) => state.media.upsert);
  const initialCategoryId = useStore((state) => state.media.initialCategoryId);
  const { saveMedia, setEditMedia, setInitialCategoryId, batchUpload } = useStore(
    (state) => state.api
  );

  const isOpen = currentId === '';

  useEffect(() => {
    if (isOpen && initialFiles.length > 0) {
      const files = initialFiles.map(async ({ file, error }, index) => {
        const url = await getBase64(file);
        setError(`files.${index}`, { type: BACKEND_ERROR_TYPE, message: error.message });

        return { name: stripExtension(file.name), source: { file, url } };
      });

      Promise.all(files).then((result) => setValue('files', result));
    }
  }, [isOpen, initialFiles, setValue, setError]);

  const onClose = () => {
    setEditMedia(null);
    setInitialCategoryId(null);
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async ({ files }) => {
    const isShowAll = location.pathname === '/midia';
    const category = initialCategoryId || categoryId;

    if (files.length === 1) {
      const { name, source } = files[0];
      const { file } = source;

      const nameWithExtension = `${name}.${getExtensionFromFile(file!)}`;
      const fileImage = new File([file!], name ? nameWithExtension : file!.name, {
        type: file!.type,
      });

      await saveMedia({ file: fileImage, category: category! }, categoryId, isShowAll);
    } else {
      const namedFiles = files.map(({ name, source: { file } }) => {
        const nameWithExtension = `${name}.${getExtensionFromFile(file!)}`;
        return new File([file!], name ? nameWithExtension : file!.name, { type: file!.type });
      });

      BatchUploadToast.openToast();
      batchUpload({ files: namedFiles, categoryId: category! });
    }
    onClose();
  });

  return (
    <FormProvider {...methods}>
      <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent minWidth="700px">
          <ModalHeader padding="32px 32px 12px">
            <Header>
              <HeaderTitle icon="icon-cloud-upload">
                Enviar arquivo
                <HeaderDescription>
                  Insira as informações abaixo para enviar um arquivo
                </HeaderDescription>
              </HeaderTitle>
            </Header>
          </ModalHeader>
          <ModalCloseButton size="lg" color="gray.40" />
          <ModalBody padding="0 32px 32px">
            <Box as="form" onSubmit={onSubmit}>
              <MediaForm />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
}

export default CreateMediaModal;
