import { createUsersEndpoints } from '../../services/UserService';
import { createSliceState } from '../serverState';
import { SliceCreator, UsersSlice } from '../store.types';

const createUsersSlice: SliceCreator<UsersSlice> = (set, get) => ({
  ...createSliceState(() => createUsersEndpoints(set, get)),
  roles: [],
  upsert: { currentId: null },
  deletion: { currentId: null },
});

export default createUsersSlice;
