import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Header, { HeaderDescription, HeaderTitle } from '../../../shared/components/Header';
import { UpdateScreen } from '../../../shared/services/types/screen';
import useStore from '../../../shared/store';
import { DrawerBody, DrawerCloseButton, DrawerHeader } from '../../../shared/styles';
import { getDirtyValues } from '../../../shared/utils/form';
import { yupPortuguese } from '../../../shared/utils/i18n';

type Form = {
  name: string;
};

yup.setLocale(yupPortuguese);

const schema = yup.object().shape({
  name: yup.string().required(),
});

const defaultValues = {
  name: '',
};

function EditScreenModal() {
  const screens = useStore((state) => state.screens.fetchScreens.data.screens);
  const { currentId } = useStore((state) => state.screens.upsert);
  const isLoading = useStore((state) => state.screens.saveScreen.isLoading);
  const { setEditScreen, saveScreen } = useStore((state) => state.api);

  const { register, formState, reset, handleSubmit } = useForm<Form>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const screen = useMemo(() => screens.find((item) => item.id === currentId), [screens, currentId]);

  const isOpen = currentId !== null;

  useEffect(() => {
    if (screen) {
      reset({ name: screen.name });
    } else {
      reset(defaultValues);
    }
  }, [isOpen, screen, reset]);

  const isEdit = !!screen;
  const { errors, isValid, isDirty, dirtyFields } = formState;

  const onClose = () => setEditScreen(null);
  const onSubmit = handleSubmit(async (values) => {
    if (isEdit) {
      const dirtyValues: Omit<UpdateScreen, 'id'> = getDirtyValues(dirtyFields, values);

      await saveScreen({ ...dirtyValues, id: currentId! });
      setTimeout(() => {
        window.location.reload();
      }, 0);
    } else {
      await saveScreen(values);
    }

    onClose();
  });

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Header>
            <HeaderTitle icon="icon-layout">
              {isEdit ? 'Editar' : 'Criar'} tela
              <HeaderDescription>
                Insira a informação abaixo para{' '}
                {isEdit ? 'editar a tela' : 'criar uma nova tela no aplicativo'}
              </HeaderDescription>
            </HeaderTitle>
          </Header>
        </DrawerHeader>
        <DrawerBody>
          <Box as="form" onSubmit={onSubmit}>
            <FormControl isInvalid={!!errors.name} marginBottom="24px">
              <FormLabel>Nome da tela</FormLabel>
              <Input placeholder="Digite o nome aqui" {...register('name')} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              colorScheme="primary"
              width="100%"
              marginTop="24px"
              disabled={isLoading || !isValid || (isEdit && !isDirty)}
              isLoading={isLoading}
            >
              Salvar
            </Button>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default EditScreenModal;
