export const BACKEND_ERROR_TYPE = 'backend-error';

export const defaultSource = { file: null, url: null };

export const getFileField = <T extends 'name' | 'source' | 'source.file' | 'source.url'>(
  field: T,
  cursor: number
) => `files.${cursor}.${field}` as const;

export const defaultValues = {
  files: [{ name: '', source: defaultSource }],
  isURL: false,
};
