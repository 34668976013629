import { IconType } from '../../shared/components/Icon/Icon.types';
import { Role } from '../../shared/services/types/user';

export interface ILink {
  to: string;
  name: string;
  icon: IconType;
  filter?: Role[];
}

export const links: ILink[] = [
  {
    to: '/telas',
    name: 'Gestão de campanhas',
    icon: 'icon-layout',
  },
  {
    to: '/midia/galeria',
    name: 'Biblioteca de mídia',
    icon: 'icon-image',
  },
  {
    to: '/usuarios',
    name: 'Gestão de usuários',
    icon: 'icon-users',
    filter: ['Marketing', 'Viewer'],
  },
];
