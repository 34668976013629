import Ajv from 'ajv';
import merge from 'deepmerge';
import { clearAuthorization, setAuthorization } from '../../../api';
import history from '../../../history';
import { ComponentType } from '../../services/types/campaign';
import { createAppEndpoints } from '../../services/AppService';
import AuthService, { createAuthEndpoints } from '../../services/AuthService';
import { createCampaignEndpoints } from '../../services/CampaignService';
import { createCategoriesEndpoints } from '../../services/CategoryService';
import { createMediaEndpoints } from '../../services/MediaService';
import { createScreensEndpoints } from '../../services/ScreenService';
import { createUsersEndpoints } from '../../services/UserService';
import { validate, validateMedia, validateSchema } from '../../utils/validation';
import { Api, Deletable, Editable, RootStore, Setter, SliceCreator } from '../store.types';
import { upsertInitialState } from './campaigns';

const ajv = new Ajv({ strict: false });

const createEditSetter =
  (set: Setter, getSlice: (state: RootStore) => Editable, prefix: string) =>
  (id: string | null) => {
    set(
      (state) => {
        getSlice(state).upsert.currentId = id;
      },
      false,
      `${prefix}/upsert/setId`
    );
  };

const createDeleteSetter =
  (set: Setter, getSlice: (state: RootStore) => Deletable, prefix: string) =>
  (id: string | null) => {
    set(
      (state) => {
        getSlice(state).deletion.currentId = id;
      },
      false,
      `${prefix}/deletion/setId`
    );
  };

const createApiSlice: SliceCreator<Api> = (set, get) => {
  const setter = (nextStateOrUpdater: Parameters<typeof set>[0], action: string) =>
    set(nextStateOrUpdater, false, action);

  return {
    ...createAppEndpoints(set, get),
    ...createAuthEndpoints(set, get),
    ...createUsersEndpoints(set, get),
    ...createMediaEndpoints(set, get),
    ...createCategoriesEndpoints(set, get),
    ...createCampaignEndpoints(set, get),
    ...createScreensEndpoints(set, get),

    setEditUser: createEditSetter(set, ({ users }) => users, 'users'),
    setEditScreen: createEditSetter(set, ({ screens }) => screens, 'screens'),

    setDeleteUser: createDeleteSetter(set, ({ users }) => users, 'users'),
    setDeleteCategory: createDeleteSetter(set, ({ categories }) => categories, 'categories'),
    setDeleteMedia: createDeleteSetter(set, ({ media }) => media, 'media'),
    setDeleteCampaign: createDeleteSetter(set, ({ campaigns }) => campaigns, 'campaigns'),
    setDeleteScreen: createDeleteSetter(set, ({ screens }) => screens, 'screens'),

    clearCampaignUpsert: () => {
      setter((state) => {
        state.campaigns.upsert = upsertInitialState;
      }, 'campaigns/upsert/clear');
    },
    dispatchSubmit: () => {
      setter((state) => {
        state.campaigns.upsert.hasSubmitted = true;
        state.campaigns.upsert.submitCount += 1;
      }, 'campaigns/upsert/dispatchSubmit');
    },
    changePosition: (position, isDirty = true) => {
      setter(({ campaigns: { upsert } }) => {
        upsert.cursor.position = position;

        if (upsert.components[position]?.type === 'showcase') {
          upsert.cursor.isDirty = true;
        } else {
          upsert.cursor.isDirty = isDirty;
        }
      }, 'campaigns/upsert/changePosition');
    },
    changeComponentPosition: (oldPosition, newPosition) => {
      setter(({ campaigns: { upsert } }) => {
        const [removedComponent] = upsert.components.splice(oldPosition, 1);
        upsert.components.splice(newPosition, 0, removedComponent);

        const [removedError] = upsert.errors.splice(oldPosition, 1);
        upsert.errors.splice(newPosition, 0, removedError);
      }, 'campaigns/upsert/changeComponentPosition');

      get().api.changePosition(newPosition);
    },
    dispatchInitialComponents: (components) => {
      setter((state) => {
        state.campaigns.upsert.components = components;

        state.campaigns.upsert.errors = components.map((component) => {
          const componentSchema = state.campaigns.fetchComponents.data.find(
            (c) => c.type === component.type
          )!;

          return validateSchema(
            componentSchema.schema,
            component.content,
            component.type,
            components
          );
        });
      }, 'campaigns/upsert/dispatchInitialComponents');
    },
    removeComponent: (position) => {
      setter((state) => {
        state.campaigns.upsert.components.splice(position, 1);
        state.campaigns.upsert.errors.splice(position, 1);
      }, 'campaigns/upsert/removeComponent');

      get().api.changePosition(position > 0 ? position - 1 : 0, true);
    },
    addComponent: (component) => {
      const cursor = get().campaigns.upsert.cursor;
      const components = get().campaigns.upsert.components;
      const isNewComponent = cursor.position === -1 || cursor.isDirty;

      setter((state) => {
        const errorsList = state.campaigns.upsert.errors;
        const schemas = state.campaigns.fetchComponents.data;
        const componentSchema = schemas.find((c) => c.type === component.type)!;

        if (isNewComponent) {
          state.campaigns.upsert.components.push(component);
        } else {
          if (component.type === 'showcase') {
            state.campaigns.upsert.cursor.isDirty = true;
          }

          state.campaigns.upsert.components = components.map((c, i) =>
            i === cursor.position ? component : c
          );
        }

        const errors = validateSchema(
          componentSchema.schema,
          component.content,
          component.type,
          state.campaigns.upsert.components
        );

        if (isNewComponent) {
          state.campaigns.upsert.errors.push(errors);
        } else {
          state.campaigns.upsert.errors = errorsList.map((e, i) =>
            i === cursor.position ? errors : e
          );
        }
      }, 'campaigns/upsert/addComponent');

      if (isNewComponent) {
        get().api.changePosition(cursor.position === -1 ? 0 : components.length, false);
      }
    },
    changeComponentMedia: (position, value) => {
      const itemErrors = value.map((item) => validateMedia(item));

      setter(({ campaigns: { upsert } }) => {
        const errors = upsert.errors[position];

        upsert.cursor.isDirty = true;
        upsert.components = upsert.components.map((c, index) => {
          if (index === position) {
            return { ...c, content: { ...c.content, items: value } };
          }

          return c;
        }) as ComponentType[];

        if (itemErrors.some((field) => Object.keys(field).length > 0)) {
          errors.items = itemErrors;
        } else {
          delete errors.items;
        }
      }, 'campaigns/upsert/changeComponentMedia');
    },
    changeComponent: (position, component, name, value) => {
      setter(({ campaigns: { upsert } }) => {
        upsert.cursor.isDirty = true;
        upsert.components = upsert.components.map((c, index) => {
          if (index === position) {
            return { ...c, content: merge(c.content, { [name]: value }) };
          }

          return c;
        }) as ComponentType[];

        const errors = upsert.errors[position];
        const internalErrors = validate(
          component.schema.properties[name],
          value,
          component.type,
          upsert.components
        );

        ajv.validate(component.schema.properties[name], value);

        if (internalErrors.length > 0) {
          errors[name] = internalErrors[0];
        } else if (ajv.errors) {
          errors[name] = ajv.errors![0].message;
        } else {
          delete errors[name];
        }
      }, 'campaigns/upsert/changeComponent');
    },

    setUser: (user) => {
      if (user) {
        setAuthorization(user.tokens.accessToken);
      } else {
        clearAuthorization();
      }

      setter((state) => {
        state.auth.login.data = user;
      }, 'auth/success');
    },
    logout: () => {
      AuthService.clearAuth();

      get().clear();

      setter((state) => {
        state.app.initializeApp.isLoading = false;
      }, 'auth/logout');

      history.push('/login');
    },
    dispatchRoles: (roles) => {
      setter((state) => {
        state.users.roles = roles;
      }, 'roles/success');
    },
    dispatchInitialImages: (images) => {
      setter((state) => {
        state.categories.initialImages = images;
      }, 'categories/initialImages/success');
    },
    setEditMedia: (currentId, initialFiles = []) => {
      setter((state) => {
        state.media.upsert = { currentId, initialFiles };
      }, 'categories/setEditCategory');
    },
    setEditCategory: (currentId, initialName = null) => {
      setter((state) => {
        state.categories.upsert = { currentId, initialName };
      }, 'categories/setEditCategory');
    },
    setInitialCategoryId: (categoryId) => {
      setter((state) => {
        state.media.initialCategoryId = categoryId;
      }, 'media/setInitialCategodyId');
    },
    dispatchSelectedMedia: (media) => {
      setter((state) => {
        state.campaigns.selectedMedia = media;
      }, 'campaigns/dispatchSelectedMedia');
    },
    clearFetchCampaign: () => {
      setter((state) => {
        state.campaigns.fetchCampaign.data = null;
      }, 'campaigns/clearFetchCampaign');
    },
    dispatchLayoutColor: (color: string) => {
      setter((state) => {
        state.app.layoutColor = color;
      }, 'app/layoutColor');
    },
    expandSidebar: (shouldExpand, hideExpansionButton = false) => {
      setter((state) => {
        state.app.isSidebarExpanded = shouldExpand;
        state.app.hideExpansionButton = hideExpansionButton;
      }, 'app/expandSidebar');
    },
  };
};

export default createApiSlice;
