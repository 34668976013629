import { Button } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import useStore from '../../../shared/store';

function DeleteMediaModal() {
  const { categoryId } = useParams();

  const { currentId } = useStore((state) => state.media.deletion);
  const { isLoading } = useStore((state) => state.media.deleteMedia);
  const { deleteMedia, setEditMedia, setDeleteMedia } = useStore((state) => state.api);

  const onClose = () => setDeleteMedia(null);

  const handleDelete = async () => {
    await deleteMedia(currentId!, categoryId);
    setEditMedia(null);
    onClose();
  };

  return (
    <ConfirmationModal isOpen={!!currentId} onClose={onClose} isCancelDisabled={isLoading}>
      <Button
        onClick={handleDelete}
        variant="solid"
        colorScheme="primary"
        isLoading={isLoading}
        disabled={isLoading}
      >
        Excluir
      </Button>
    </ConfirmationModal>
  );
}

export default DeleteMediaModal;
