import { Box, Button, Center, Drawer, DrawerContent, DrawerOverlay, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import Lottie from 'lottie-react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import marisaLoading from '../../../assets/lotties/marisa-loading.json';
import useStore from '../../../shared/store';
import { yupPortuguese } from '../../../shared/utils/i18n';
import { passwordConfirmSchema, passwordSchema } from '../../../shared/schemas/password';
import Icon from '../../../shared/components/Icon';
import UserDraweForm from './UserDrawerForm';
import { getDirtyValues } from '../../../shared/utils/form';
import { IForm, Props } from './UserDrawer.types';
import Header, { HeaderDescription, HeaderTitle } from '../../../shared/components/Header';
import { UserEdit } from '../../../shared/services/types/user';
import { DrawerBody, DrawerCloseButton, DrawerHeader } from '../../../shared/styles';
import { capitalizeFirst } from '../../../shared/utils';

const defaultValues = {
  name: '',
  email: '',
  password: '',
  passwordConfirm: '',
  role: null,
  isEdit: false,
};

yup.setLocale(yupPortuguese);

const formSchema = yup.object().shape({
  name: yup.string().required().max(40),
  email: yup.string().required().email('Formato de e-mail inválido'),
  role: yup.string().nullable().required(),
  isEdit: yup.boolean(),
  password: yup.string().when('isEdit', { is: false, then: passwordSchema }),
  passwordConfirm: yup
    .string()
    .when('isEdit', { is: false, then: passwordConfirmSchema('password') }),
});

function UserDrawer({ onClose }: Props) {
  const [isSuccessful, setSuccess] = useState(false);
  const authenticatedUser = useStore((state) => state.auth.login.data);
  const list = useStore((state) => state.users.fetchUsers.data);
  const { currentId } = useStore((state) => state.users.upsert);
  const { isLoading } = useStore((state) => state.users.saveUser);
  const { saveUser } = useStore((state) => state.api);

  const isAuthenticatedUser = authenticatedUser!.id === currentId;
  const user = useMemo(() => {
    if (isAuthenticatedUser) {
      return authenticatedUser;
    }

    return list.users.find((currentUser) => currentUser.id === currentId);
  }, [list, currentId, authenticatedUser, isAuthenticatedUser]);

  const methods = useForm<IForm>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(formSchema),
  });
  const { reset, setValue, handleSubmit, formState, control } = methods;
  const isEdit = useWatch<IForm>({ name: 'isEdit', control });

  const isOpen = currentId !== null;
  useEffect(() => {
    if (!isOpen) {
      setSuccess(false);
      return;
    }

    if (user) {
      const { role, ...data } = user;
      reset({ ...data, role: role.name, isEdit: true });
      setValue('role', role.name, { shouldTouch: true });
    } else {
      reset(defaultValues);
    }
  }, [isOpen, user, setValue, reset]);

  const { dirtyFields } = formState;
  const target = isAuthenticatedUser ? 'perfil' : 'usuário';

  const openForm = () => {
    setSuccess(false);
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async ({ isEdit: _, ...values }) => {
    if (isEdit) {
      const dirtyValues: Omit<UserEdit, 'id'> = getDirtyValues(dirtyFields, values);
      await saveUser({ ...dirtyValues, id: user!.id });
    } else {
      await saveUser({ ...values, role: values.role! });
    }

    setSuccess(true);
  });

  const openFormButton = (
    <Button variant="outline" onClick={openForm} fontSize="15px" marginBottom="8px">
      Cadastrar novo usuário
    </Button>
  );

  let body = (
    <Box as="form" onSubmit={onSubmit}>
      <UserDraweForm />
    </Box>
  );

  if (isSuccessful) {
    body = (
      <Center height="100%" flexDirection="column" textAlign="center" padding="20px">
        <Center
          padding="32px"
          backgroundColor="green.100"
          borderRadius="50%"
          boxShadow="0px 4px 12px rgba(45, 223, 137, 0.4);"
          marginBottom="32px"
        >
          <Icon icon="icon-check" color="white" fontSize="60px" />
        </Center>
        <Text
          fontWeight="semibold"
          fontSize="28px"
          color="gray.90"
          marginBottom="32px"
          lineHeight="1.3"
        >
          {isEdit ? `${capitalizeFirst(target)} editado` : 'Novo usuário cadastrado'} com sucesso!
        </Text>
        {!isEdit && openFormButton}
        <Button variant="link" color="gray.80" textDecoration="underline" onClick={onClose}>
          Fechar
        </Button>
      </Center>
    );
  } else if (isLoading) {
    body = (
      <Center height="100%" width="250px" marginX="auto">
        <Lottie animationData={marisaLoading} />
      </Center>
    );
  }

  return (
    <FormProvider {...methods}>
      <Drawer isOpen={isOpen} onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Header>
              <HeaderTitle icon={isEdit ? 'icon-edit-alt' : 'icon-user-add'}>
                {isEdit ? 'Editar' : 'Cadastrar'} {target}
                <HeaderDescription>
                  Insira as informações abaixo para {isEdit ? 'editar o' : 'adicionar um novo'}{' '}
                  {target}
                </HeaderDescription>
              </HeaderTitle>
            </Header>
          </DrawerHeader>
          <DrawerBody paddingBottom="40px">{body}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </FormProvider>
  );
}

export default UserDrawer;
