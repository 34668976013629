import React from 'react';
import { Global } from '@emotion/react';
import './assets/styles/MarisaGlyph.css';
import Pluto from './assets/fonts/PlutoCond/PlutoCondRegular.otf';
import PlutoBold from './assets/fonts/PlutoCond/PlutoCondBold.otf';
import Nunito from './assets/fonts/Nunito/Nunito-Regular.ttf';
import NunitoSemiBold from './assets/fonts/Nunito/Nunito-SemiBold.ttf';
import NunitoBold from './assets/fonts/Nunito/Nunito-Bold.ttf';
import MarisaGlyphEOT from './assets/fonts/MarisaGlyph/marisa-glyph.eot';
import MarisaGlyphTTF from './assets/fonts/MarisaGlyph/marisa-glyph.ttf';
import MarisaGlyphWOFF from './assets/fonts/MarisaGlyph/marisa-glyph.woff';
import MarisaGlyphSVG from './assets/fonts/MarisaGlyph/marisa-glyph.svg';

function Fonts() {
  return (
    <Global
      styles={`
      @font-face {
        font-family: 'Pluto';
        font-style: normal;
        font-weight: 400;
        src: url(${Pluto}) format("opentype");
      }

      @font-face {
        font-family: 'Pluto';
        font-style: bold;
        font-weight: 700;
        src: url(${PlutoBold}) format("opentype");
      }

      @font-face {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 400;
        src: url(${Nunito}) format("truetype");
      }

      @font-face {
        font-family: 'Nunito';
        font-style: semibold;
        font-weight: 600;
        src: url(${NunitoSemiBold}) format("truetype");
      }

      @font-face {
        font-family: 'Nunito';
        font-style: bold;
        font-weight: 700;
        src: url(${NunitoBold}) format("truetype");
      }

      @font-face {
        font-family: 'marisa-glyph';
        src:  url(${MarisaGlyphEOT}) format('embedded-opentype'),
          url(${MarisaGlyphTTF}) format('truetype'),
          url(${MarisaGlyphWOFF}) format('woff'),
          url(${MarisaGlyphSVG}) format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: block;
      }
      `}
    />
  );
}

export default Fonts;
