import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '../../shared/components/Breadcrumb';
import Header, { HeaderTitle } from '../../shared/components/Header';
import Icon from '../../shared/components/Icon';
import { NavPageLink } from '../../shared/components/Pagination';
import { ComponentType, ICampaignEdit, Status } from '../../shared/services/types/campaign';
import useStore from '../../shared/store';
import { getDirtyValues } from '../../shared/utils/form';
import { yupPortuguese } from '../../shared/utils/i18n';
import { PageBox, PageSpinner, PrimaryOutlineButton, SimpleText } from '../../shared/styles';
import CampaignForm from './components/CampaignForm';
import { Form } from './Campaign.types';
import { useCampaingCountdown } from '../../shared/store/useCampaignCountdown';

yup.setLocale(yupPortuguese);

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  startDate: yup
    .string()
    .required()
    .test(
      'validate-endDate',
      'A data/hora de início deve ser anterior a data/hora de finalização',
      (value, context) => !!value && new Date(value) < new Date(context.parent.endDate)
    ),
  endDate: yup
    .string()
    .required()
    .test(
      'validate-date-startDate',
      'A data/hora de finalização deve ser posterior a data/hora de início',
      (value, context) => !!value && new Date(value) >= new Date(context.parent.startDate)
    ),
});

const getDefaultValues = () => {
  const now = new Date().toISOString();

  return {
    name: '',
    description: '',
    startDate: now,
    endDate: now,
  };
};

function Campaign() {
  const navigate = useNavigate();
  const locationState = useLocation().state as { form: Form; components: ComponentType[] } | null;
  const { campaignId, screenId } = useParams();
  const [searchParams] = useSearchParams();

  const isExpanded = useStore((state) => state.app.isSidebarExpanded);
  const { addDate } = useCampaingCountdown((state) => state);

  const isLoading = useStore((state) => state.campaigns.saveCampaign.isLoading);
  const data = useStore((state) => state.campaigns.fetchCampaigns.data);
  const { data: currentCampaign, isLoading: isLoadingCampaign } = useStore(
    (state) => state.campaigns.fetchCampaign
  );
  const { dispatchLayoutColor, clearFetchCampaign, saveCampaign, fetchCampaign } = useStore(
    (state) => state.api
  );

  const methods = useForm<Form>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(),
    resolver: yupResolver(schema),
  });

  const { formState, getValues, setValue, reset, handleSubmit, watch } = methods;

  const setLocationValues = useCallback(
    (form: Partial<Form>) => {
      reset(form);

      if (locationState?.form) {
        Object.entries(locationState.form).forEach(([key, value]) => {
          setValue(key as keyof Form, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        });
      }
    },
    [locationState, reset, setValue]
  );

  const campaign = useMemo(
    () => data.campaigns.find(({ id }) => id === campaignId),
    [campaignId, data]
  );

  useEffect(() => {
    dispatchLayoutColor('white');
  }, [dispatchLayoutColor]);

  useEffect(() => {
    if (campaignId) {
      fetchCampaign(campaignId);
    } else {
      clearFetchCampaign();
    }
  }, [campaignId, clearFetchCampaign, fetchCampaign]);

  useEffect(() => {
    if (campaign || currentCampaign) {
      const { name, description, startDate, endDate } = campaign || currentCampaign!;
      setLocationValues({ name, description, startDate, endDate });
    } else {
      setLocationValues(getDefaultValues());
    }
  }, [setLocationValues, locationState, campaign, currentCampaign]);

  const { isValid, dirtyFields } = formState;
  const hasDirtyFields = Object.keys(dirtyFields).length > 0;

  const isEdit = !!campaignId;
  const isDuplicate = searchParams.get('duplicate');
  const isPublished = !isDuplicate && campaign && Status.DRAFT !== campaign.status;

  const onSubmit = handleSubmit((values) => {
    if (campaignId && !isDuplicate) {
      const dirtyValues: ICampaignEdit = getDirtyValues(dirtyFields, values);
      saveCampaign({
        ...dirtyValues,
        ...(locationState?.components && { components: locationState.components }),
        id: campaignId,
      });
    } else {
      const components = locationState?.components || currentCampaign?.components || [];
      saveCampaign({ ...values, components, screen: screenId });
    }
  });

  const onNext = () => {
    const values = getValues();
    const form = campaignId && !isDuplicate ? getDirtyValues(dirtyFields, values) : values;
    addDate(watch('startDate'));
    navigate(`componentes${isDuplicate ? '?duplicate=true' : ''}`, {
      state: { ...locationState, form },
    });
  };

  let action = isEdit ? 'Editar' : 'Criar';
  if (isDuplicate) {
    action = 'Duplicar';
  }

  if (isLoadingCampaign) {
    return (
      <Center height="100%">
        <PageSpinner />
      </Center>
    );
  }

  return (
    <Box backgroundColor="white" height="100%" position="relative" overflow="auto">
      <Flex
        alignItems="center"
        marginBottom="30px"
        padding="0 30px 0 45px"
        height="80px"
        borderBottom="1px solid"
        borderColor="gray.20"
      >
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/telas">
              <Icon icon="icon-layout" /> <SimpleText>Telas</SimpleText>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="..">
              <SimpleText>Campanhas</SimpleText>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink isCurrentPage>
              <SimpleText>{action} campanha</SimpleText>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <PageBox paddingTop="0 !important" paddingBottom="145px !important">
        <Header marginBottom="30px">
          <HeaderTitle>{action} campanha</HeaderTitle>
        </Header>
        <Box as="form" onSubmit={onSubmit}>
          <FormProvider {...methods}>
            <CampaignForm campaign={campaign} />
          </FormProvider>
          <Center
            position="fixed"
            left={isExpanded ? '250px' : '100px'}
            right="0"
            bottom="0"
            paddingX="30px"
            height="100px"
            borderTop="1px solid"
            borderColor="gray.20"
            backgroundColor="white"
          >
            <NavPageLink as={Link} to="..">
              <Box as="span" marginRight="10px">
                <Icon icon="icon-chevron-left" paddingTop="1px" />
              </Box>
              <Text>Voltar</Text>
            </NavPageLink>
            <Box marginLeft="auto" width="max-content">
              <PrimaryOutlineButton
                marginRight="16px"
                width="245px"
                borderColor="primary.100"
                isLoading={isLoading}
                disabled={isLoading || !isValid || (!hasDirtyFields && !isDuplicate)}
                type="submit"
              >
                {isPublished ? 'Salvar' : 'Salvar rascunho'}
              </PrimaryOutlineButton>
              <Button
                width="245px"
                colorScheme="primary"
                variant="solid"
                disabled={isLoading || !isValid}
                onClick={onNext}
              >
                Continuar
              </Button>
            </Box>
          </Center>
        </Box>
      </PageBox>
    </Box>
  );
}

export default Campaign;
