export const yupPortuguese = {
  mixed: {
    required: 'Este campo é obrigatório',
  },
  string: {
    min: ({ min }: { min: number }) => `O campo deve conter pelo menos ${min} caracteres`,
    max: ({ max }: { max: number }) => `O campo não pode conter mais de ${max} caracteres`,
  },
};

export const getPluralCase = (count: number, singular: string, plural: string) =>
  count > 1 ? plural : singular;
