/* eslint-disable no-nested-ternary */
import { QuestionIcon } from '@chakra-ui/icons';
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Icon from '../../../../shared/components/Icon';
import { Item } from '../../../../shared/services/types/campaign';
import { validateUrl } from '../../../../shared/utils/validation';

interface FormHelperModalProps {
  item?: Partial<Item>;
}

export function FormHelperModal({ item }: FormHelperModalProps) {
  const [modalTitle, setModalTitle] = useState(25);
  const [modalButtonTitle, setModalButtonTitle] = useState(20);
  const [closeHelperModal, setCloseHelperModal] = useState(false);
  const { register, watch, getValues } = useFormContext();

  const handleInpuCountCharacter = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'button-text') {
      const remainingChars = 20 - value.length;
      setModalButtonTitle(remainingChars);
    } else {
      const remainingChars = 25 - value.length;
      setModalTitle(remainingChars);
    }
  };

  const getColor = (count: number) => {
    if (count <= 30 && count >= 16) {
      return '#838181';
    }
    if (count <= 15 && count >= 6) {
      return '#FF922D';
    }
    if (count <= 5 && count >= 0) {
      return '#FF4B56';
    }
  };

  const handleColorCharacteres = (count: number) => {
    const color = getColor(count);
    return <Text color={color}>{count}</Text>;
  };

  useEffect(() => {
    if (!!item?.countdown?.helper === true) {
      setCloseHelperModal(!!getValues('hasHelperModal')!);
    }
  }, [getValues, item?.countdown.helper]);

  return (
    <>
      <Box as="section" h="100%" display="flex" flexDirection="column">
        <Text fontSize="lg" fontWeight="bold">
          <Text as="span" color="primary.100">
            Configurações
          </Text>{' '}
          do modal de ajuda
        </Text>
        {watch('hasHelperModal') || (item?.countdown.helper && !closeHelperModal) ? (
          <>
            <Box
              w="100%"
              mixBlendMode="normal"
              bg="pink.100"
              borderRadius="8px"
              p="10px 18px"
              mt="1.75rem"
            >
              <Text opacity="2" fontSize="13px">
                Quando a cliente clicar no ícone “ <QuestionIcon color="#989696" />” , será
                apresentado um modal informativo. Tente ser breve nas informações e caso possua
                regras mais complexas, utilize o link de direcionamento para uma página contendo os
                detalhes completos da promoção.
              </Text>
            </Box>
            <Box mt="1rem">
              <Flex flexDirection="column" gap="1rem">
                <FormControl isRequired>
                  <FormLabel>Titulo do modal de ajuda</FormLabel>
                  <InputGroup>
                    <Input
                      isRequired
                      maxLength={25}
                      value={watch('title-modal')}
                      {...register('title-modal', {
                        onChange: (e) => handleInpuCountCharacter(e),
                      })}
                    />
                    <InputRightElement>
                      <Tooltip label="Caracteres restantes">
                        {handleColorCharacteres(modalTitle)}
                      </Tooltip>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Corpo do texto do modal de ajuda</FormLabel>
                  <Textarea
                    isRequired={item?.countdown || ''}
                    rows={4}
                    maxLength={150}
                    value={watch('description-modal')}
                    {...register('description-modal')}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Texto do botão do modal de ajuda</FormLabel>
                  <InputGroup>
                    <Input
                      isRequired
                      maxLength={20}
                      value={watch('button-text')}
                      {...register('button-text', {
                        onChange: (e) => handleInpuCountCharacter(e),
                      })}
                    />
                    <InputRightElement>
                      <Tooltip label="Caracteres restantes">
                        {handleColorCharacteres(modalButtonTitle)}
                      </Tooltip>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl marginTop="24px" isRequired>
                  <FormLabel>Link de direcionamento do botão</FormLabel>
                  <InputGroup>
                    <Input
                      isInvalid={!validateUrl(watch('button-link'))}
                      value={watch('hasUrl') ? '#' : watch('button-link')}
                      color={watch('hasUrl') === '# ' ? '#838181' : ''}
                      {...register('button-link')}
                      disabled={watch('hasUrl')}
                    />
                    <InputRightAddon height="56px" backgroundColor="white">
                      <Icon
                        icon="icon-link"
                        fontSize={{ base: 'lg', xl: '2xl' }}
                        color="primary.100"
                      />
                    </InputRightAddon>
                  </InputGroup>
                  <Checkbox
                    isRequired={false}
                    marginTop="6px"
                    colorScheme="primary"
                    color="#838181"
                    {...register('hasUrl')}
                    defaultChecked={item?.countdown?.helper?.buttonLink === '#' || false}
                  >
                    <Text fontSize="14px">Este botão não possui link de direcionamento</Text>
                  </Checkbox>
                </FormControl>

                <Box w="100%" mixBlendMode="normal" bg="pink.100" borderRadius="8px" p="10px 18px">
                  <Text fontSize="13px">
                    Caso não tenha nenhum link que direcione para mais detalhes da promoção, insira
                    um texto genérico (“continuar”), porque ao clicar no botão, o modal fechará sem
                    interromper o fluxo de navegação da cliente.
                  </Text>
                </Box>
              </Flex>
            </Box>
          </>
        ) : (
          <Flex flexDirection="column" flex={1} justifyContent="center" alignItems="center">
            <Icon icon="icon-circle-information" fontSize="7xl" color="#99ACCC" />
            <Text
              fontWeight="800"
              fontSize="14px"
              textTransform="uppercase"
              color="#99ACCC"
              textAlign="center"
            >
              Você optou por não adicionar
              <br /> um modal de ajuda
            </Text>
          </Flex>
        )}
      </Box>
    </>
  );
}
