import React, { useCallback, useEffect } from 'react';
import { Center, Flex, Spinner } from '@chakra-ui/react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '../../shared/components/Breadcrumb';
import { ComponentType } from '../../shared/services/types/campaign';
import { SimpleText } from '../../shared/styles';
import { Cursor } from './CampaignComponents.types';
import Icon from '../../shared/components/Icon';
import useStore from '../../shared/store';
import DragDropBaseProvider from '../../shared/contexts/DragDropBaseContext';
import Components from './components/Components';
import ComponentForm from './components/ComponentForm';
import Preview from './components/Preview';
import BottomBar from './components/BottomBar';

const DEFAULT_COMPONENTS = { components: undefined };

function CampaignComponents() {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const fetchCampaign = useStore((state) => state.campaigns.fetchCampaign);
  const { isLoading } = useStore(({ campaigns }) => campaigns.fetchComponents);
  const {
    cursor,
    components: addedComponents,
    errors,
  } = useStore((state) => state.campaigns.upsert);

  const api = useStore((state) => state.api);

  const locationState = useLocation().state as { components: ComponentType[] } | null;

  const { components: locationComponents } = locationState || DEFAULT_COMPONENTS;

  const getCursor = useCallback(
    (): Cursor => ({
      ...cursor,
      component: addedComponents[cursor.position],
      errors: errors[cursor.position],
    }),
    [cursor, addedComponents, errors]
  );

  const redirectToTelasAnClearComponents = () => {
    navigate('/telas');
    api.clearCampaignUpsert();
  };

  const redirectToCampanhasAnClearComponents = () => {
    navigate('../..');
    api.clearCampaignUpsert();
  };

  useEffect(() => {
    api.dispatchLayoutColor('white');
  }, [api]);

  useEffect(() => {
    if (addedComponents.length === 0) {
      api.initializeCampaignComponents(campaignId, locationComponents);
    }
  }, [api, campaignId, addedComponents, locationComponents]);

  if (cursor.position === -1) {
    return null;
  }

  if (isLoading || fetchCampaign.isLoading) {
    return (
      <Center height="100%">
        <Spinner thickness="4px" size="xl" color="primary.100" />
      </Center>
    );
  }

  const isEdit = !!campaignId;
  const campaign = fetchCampaign.data;
  const isDuplicate = searchParams.get('duplicate');
  const backRoute = campaign?.standard ? '/telas' : `..${isDuplicate ? '?duplicate=true' : ''}`;
  const newLocationState = { ...locationState, components: addedComponents };

  let action = isEdit ? 'Editar' : 'Criar';
  if (isDuplicate) {
    action = 'Duplicar';
  }

  const campaignsBreadcrumb = (
    <BreadcrumbItem onClick={redirectToCampanhasAnClearComponents}>
      <BreadcrumbLink>
        <SimpleText>Campanhas</SimpleText>
      </BreadcrumbLink>
    </BreadcrumbItem>
  );

  const editCampaignBreadcrumb = (
    <BreadcrumbItem>
      <BreadcrumbLink as={Link} to={backRoute} state={newLocationState}>
        <SimpleText>{action} campanha</SimpleText>
      </BreadcrumbLink>
    </BreadcrumbItem>
  );

  return (
    <Flex backgroundColor="white" height="100%" position="relative" flexDirection="column">
      <Flex
        alignItems="center"
        padding="0 30px 0 45px"
        height="80px"
        borderBottom="1px solid"
        borderColor="gray.20"
      >
        <Breadcrumb>
          <BreadcrumbItem onClick={redirectToTelasAnClearComponents}>
            <BreadcrumbLink>
              <Icon icon="icon-layout" /> <SimpleText>Telas</SimpleText>
            </BreadcrumbLink>
          </BreadcrumbItem>
          {!campaign?.standard && campaignsBreadcrumb}
          {!campaign?.standard && editCampaignBreadcrumb}
          <BreadcrumbItem>
            <BreadcrumbLink isCurrentPage>
              <SimpleText>Componentes</SimpleText>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Flex backgroundColor="blue.5" flex="1">
        <DragDropBaseProvider onChangePosition={api.changeComponentPosition}>
          <Components getCursor={getCursor} />
          <Preview getCursor={getCursor} />
        </DragDropBaseProvider>
        <ComponentForm getCursor={getCursor} />
      </Flex>
      <BottomBar />
    </Flex>
  );
}

export default CampaignComponents;
