import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import Fonts from './Fonts';
import history, { initializeHistory } from './history';
import RootRoutes from './routes';
import { ToastContainer } from './shared/components/Toast';
import theme from './theme';

initializeHistory();

function App() {
  return (
    <HistoryRouter history={history}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <RootRoutes />
        <ToastContainer />
      </ChakraProvider>
    </HistoryRouter>
  );
}

export default App;
