import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import useStore from '../../../shared/store';
import { capitalizeFirst } from '../../../shared/utils';
import { Select } from '../../../shared/components/Form';
import { HeaderDescription } from '../../../shared/components/Header';
import { Cursor } from '../CampaignComponents.types';
import { componentSlug } from '../shared';
import MediaField from './MediaField';
import { isMediaField, Property } from '../../../shared/utils/validation';
import { SimpleText } from '../../../shared/styles';

type Props = {
  getCursor: () => Cursor;
};

const FIELDS_WITHOUT_MEDIA = ['label', 'showcase'];

function ComponentForm({ getCursor }: Props) {
  const { errors, hasSubmitted } = useStore((state) => state.campaigns.upsert);
  const components = useStore(({ campaigns }) => campaigns.fetchComponents.data);
  const { dispatchSelectedMedia, changeComponent, changeComponentMedia } = useStore(
    ({ api }) => api
  );

  const component = useMemo(
    () => components.find((c) => c.type === getCursor().component?.type),
    [components, getCursor]
  );

  useEffect(() => {
    dispatchSelectedMedia(null);
  }, [getCursor, dispatchSelectedMedia]);

  if (!component) {
    return (
      <Box padding="35px 24px" backgroundColor="white" overflow="auto" flex="1" width="400px" />
    );
  }

  const state: any = getCursor().component?.content;

  const onChange =
    (name: string, isMedia = false) =>
    (value: any) => {
      const { position } = getCursor();

      if (isMedia) {
        changeComponentMedia(position, value);
      } else {
        changeComponent(position, component, name, value);
      }
    };

  const showcaseDescription = <HeaderDescription>Escolha a posição da vitrine</HeaderDescription>;

  const description = (
    <HeaderDescription>
      Selecione uma mídia por vez e visualize abaixo <br />
      (formato permitido .JPG, .JPEG ou .PNG com até 5MB)
    </HeaderDescription>
  );

  const ComponentSchemaView = Object.entries<Property>(component.schema.properties).map(
    ([key, value]) => {
      const isMedia = isMediaField(value);

      const error = errors[getCursor().position] || {};
      const baseProps = {
        key: `${key} ${component.type} ${getCursor().position}`,
        _notLast: { marginBottom: '24px' },
      };
      const handleChange = onChange(key, isMedia);
      const slug = capitalizeFirst(component.slugs[key].name);
      const hint = component.slugs[key].hint;
      const isRequired = component.schema.required.some((field: string) => field === key);
      const hintView = hint && <FormHelperText>{hint}</FormHelperText>;

      if (value.enum) {
        const slugEnum = component.slugs[key].enum;
        return (
          componentSlug[component.type] !== 'grid' && (
            <FormControl
              {...baseProps}
              isRequired={isRequired}
              isInvalid={hasSubmitted && error[key]}
            >
              <FormLabel>{slug}</FormLabel>
              <Select
                height="56px"
                name={key}
                value={state[key] || component.slugs[key].default}
                onChange={(e) => handleChange(e.target.value)}
              >
                {value.enum.map((option, index) => (
                  <option key={option} value={String(option)}>
                    {capitalizeFirst(slugEnum ? slugEnum[index] : key)}
                  </option>
                ))}
              </Select>
              {hintView}
              <FormErrorMessage>{error[key]}</FormErrorMessage>
            </FormControl>
          )
        );
      }

      switch (value.type) {
        case 'string':
          return (
            <FormControl
              {...baseProps}
              isRequired={isRequired}
              isInvalid={hasSubmitted && error[key]}
              marginBottom="10px"
            >
              <FormLabel>{slug}</FormLabel>
              <Input
                name={key}
                placeholder={`Insira o campo ${component.slugs[key].name} aqui`}
                value={state[key] || ''}
                onChange={(e) => handleChange(e.target.value)}
              />
              {hintView}
              <FormErrorMessage>{error[key]}</FormErrorMessage>
            </FormControl>
          );

        case 'array':
          return (
            <MediaField
              key={baseProps.key}
              type={component.type}
              value={state[key]}
              componentErrors={error[key] || []}
              onChange={handleChange}
            />
          );
        default:
          return null;
      }
    }
  );

  return (
    <Box
      padding="35px 24px"
      backgroundColor="white"
      overflow="auto"
      flex="1"
      width="400px"
      __css={{
        '&::-webkit-scrollbar': {
          w: '2',
          h: '2',
        },

        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10',
          bg: `gray.300`,
        },
      }}
    >
      <Box height="0px">
        <Box marginBottom="30px">
          <Heading as="h2" fontSize="xl" marginBottom="30px">
            Configurações do{' '}
            <SimpleText color="primary.100">{componentSlug[component.type]}</SimpleText>
            {FIELDS_WITHOUT_MEDIA.every((type) => component.type !== type) && description}
            {component.type === 'showcase' && showcaseDescription}
          </Heading>
          <Box as="form">{ComponentSchemaView}</Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ComponentForm;
