import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Center,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import duplicateSource from '../../../assets/icons/duplicate.png';
import { PrimaryOutlineButton } from '../../../shared/styles';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDuplicate: () => void;
};

function DuplicateCampaignModal({ isOpen, onClose, onDuplicate }: Props) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogCloseButton />
        <AlertDialogContent>
          <AlertDialogBody textAlign="center" padding="48px 64px">
            <Center flexDirection="column" marginBottom="32px">
              <Image src={duplicateSource} marginBottom="24px" />
              <Text fontWeight="semibold" fontSize="24px" marginBottom="16px" color="black">
                Deseja duplicar esta campanha?
              </Text>
              <Text color="gray.60">
                Clique em “Confirmar” para criar uma cópia e editar as informações da campanha
                selecionada.
              </Text>
            </Center>
            <Flex>
              <PrimaryOutlineButton ref={cancelRef} onClick={onClose}>
                Cancelar
              </PrimaryOutlineButton>
              <Button flex="1" colorScheme="primary" onClick={onDuplicate}>
                Confirmar
              </Button>
            </Flex>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default DuplicateCampaignModal;
