import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { ChangeEventHandler, useState } from 'react';
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import useStore from '../../../../../shared/store';
import { BACKEND_ERROR_TYPE, defaultValues, getFileField } from '../shared';
import { IForm } from '../CreateMediaModal.types';
import URLImageTab from './URLImageTab';
import MediaList from './MediaList';
import { PrimaryOutlineButton } from '../../../../../shared/styles';

export type FileErrors = (
  | Merge<
      FieldError,
      FieldErrorsImpl<{
        name: string;
        source: {
          url: string;
          file: File;
        };
      }>
    >
  | undefined
)[];

function MediaForm() {
  const [cursor, setCursor] = useState(0);
  const hasInitialFiles = useStore((state) => state.media.upsert.initialFiles.length > 0);
  const { isLoading } = useStore((state) => state.media.saveMedia);
  const { register, formState, control, reset, clearErrors, trigger } = useFormContext<IForm>();
  const filesMethods = useFieldArray({ name: 'files', control });

  const { isValid, errors, isDirty } = formState;
  const currentErrors = errors.files && errors.files[cursor];
  const hasBackendErrors =
    errors.files &&
    (errors.files as FileErrors).some((error) => error?.type === BACKEND_ERROR_TYPE);
  const isValidWithInitialFiles = !hasInitialFiles || (isDirty && !hasBackendErrors);

  const onChangeCursor = (position: number) => {
    setCursor(position);
  };

  // 2 indexes -> 0 or 1, false or true
  const handleModeChange = (tabIndex: number) => {
    reset({ ...defaultValues, isURL: !!tabIndex });
    setCursor(0);
  };

  const clearMediaList = () => {
    reset(defaultValues);
    setCursor(0);
  };

  const getNameProps = () => {
    if (!filesMethods.fields[cursor]) {
      return;
    }

    const key = filesMethods.fields[cursor].id;
    const { onChange, ...props } = register(getFileField('name', cursor));

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange(e);

      if (currentErrors?.type === BACKEND_ERROR_TYPE) {
        clearErrors(`files.${cursor}`);
        trigger(`files.${cursor}`);
      }
    };

    return { key, onChange: handleChange, ...props };
  };

  return (
    <Box>
      <Tabs isFitted onChange={handleModeChange} colorScheme="primary">
        <TabList>
          <Tab>Upload do computador</Tab>
          <Tab>Upload por URL</Tab>
        </TabList>
        <TabPanels>
          <TabPanel paddingX="0" paddingBottom="0">
            <FormControl isInvalid={!!currentErrors?.source}>
              <MediaList cursor={cursor} onChangeCursor={onChangeCursor} methods={filesMethods} />
              <FormErrorMessage>{currentErrors?.source?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              marginTop="24px"
              display={filesMethods.fields.length > 8 ? 'none' : 'block'}
              isInvalid={!!(currentErrors?.name || currentErrors)}
            >
              <FormLabel>Nome da mídia</FormLabel>
              <Input placeholder="Digite o nome da mídia" {...getNameProps()} />
              <FormErrorMessage>
                {currentErrors?.message || currentErrors?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </TabPanel>
          <TabPanel paddingX="0" paddingBottom="0">
            <URLImageTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Flex marginTop="24px">
        <Controller
          control={control}
          name="files"
          render={({ field: { value } }) => (
            <PrimaryOutlineButton
              flex="1"
              marginRight="18px"
              disabled={isLoading || value.length < 2}
              onClick={clearMediaList}
            >
              Limpar lista de arquivos
            </PrimaryOutlineButton>
          )}
        />

        <Button
          flex="1"
          type="submit"
          colorScheme="primary"
          disabled={!isValid || isLoading || !isValidWithInitialFiles}
          isLoading={isLoading}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  );
}

export default MediaForm;
