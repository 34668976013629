import { Box, Center, Heading, Image, useConst, useMediaQuery } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import imagePlaceholderSource from '../../../assets/images/image-placeholder.png';
import { HeaderDescription } from '../../../shared/components/Header';
import { IComponent } from '../../../shared/services/types/campaign';
import CampaignService from '../../../shared/services/CampaignService';
import useStore from '../../../shared/store';
import { transientOptions } from '../../../shared/utils';
import { Cursor } from '../CampaignComponents.types';
import { componentSlug, renderCarousel, renderStory } from '../shared';
import Icon from '../../../shared/components/Icon';

type Props = {
  getCursor: () => Cursor;
};

type ComponentBoxProps = { $componentType: string; $isSelected: boolean };

const mark = `
  ::after {
    content: '\\e92c';
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    translate: 50% -50%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    font-family: 'marisa-glyph';
    font-size: var(--chakra-fontSizes-md);
    font-weight: bold;
    background-color: var(--chakra-colors-green-100);
  }
`;

const ComponentBox = styled(Center, transientOptions)`
  position: relative;
  margin: auto;
  width: 300px;
  background-color: var(--chakra-colors-white);
  border-radius: 20px;
  border: 3px solid var(--chakra-colors-blue-20);
  ::before {
    content: '${({ $componentType }: ComponentBoxProps) => $componentType}';
    z-index: 1;
    position: absolute;
    text-transform: uppercase;
    left: 50%;
    top: 0;
    border-radius: 8px;
    translate: -50% -50%;
    padding: 4px 12px;
    font-size: var(--chakra-fontSizes-xs);
    font-weight: bold;
    background-color: var(--chakra-colors-gray-10);
  }
  ${({ $isSelected }: ComponentBoxProps) => ($isSelected ? mark : '')}
`;

ComponentBox.defaultProps = {
  as: 'button',
  type: 'button',
};

function Components({ getCursor }: Props) {
  const [isLargerThan1347] = useMediaQuery('(min-width: 1347px)', { fallback: true });
  const data = useStore((state) => state.campaigns.fetchComponents.data);
  const { expandSidebar, addComponent } = useStore((state) => state.api);
  const { isSidebarExpanded, hideExpansionButton } = useStore((state) => state.app);

  const initialAppState = useConst(() => ({ isSidebarExpanded, hideExpansionButton }));

  useEffect(
    () => () => {
      expandSidebar(initialAppState.isSidebarExpanded, initialAppState.hideExpansionButton);
    },
    [initialAppState, expandSidebar]
  );

  useEffect(() => {
    if (initialAppState.isSidebarExpanded && !isLargerThan1347) {
      expandSidebar(false, true);
    }
  }, [initialAppState, isLargerThan1347, expandSidebar]);

  const components = data.map((component: IComponent) => {
    const { type } = component;

    const baseProps = {
      $componentType: componentSlug[type],
      $isSelected: getCursor().component?.type === type,
      onClick: () =>
        addComponent({
          type,
          content: CampaignService.getInitialContent(component.schema.properties),
        }),
      key: type,
      padding: '24px',
    };

    switch (type) {
      case 'banner':
        return (
          <ComponentBox {...baseProps} marginBottom="32px !important">
            <Image width="60px" alt="Banner do componente" src={imagePlaceholderSource} />
          </ComponentBox>
        );
      case 'carousel':
        return (
          <ComponentBox {...baseProps} padding="0" marginBottom="32px !important">
            {renderCarousel()}
          </ComponentBox>
        );
      case 'grid':
        return (
          <ComponentBox
            {...baseProps}
            padding="0 0 32px"
            display="grid"
            minWidth="min-content !important"
            width="min-content !important"
            gap="15px"
            gridTemplateColumns="repeat(2, 1fr)"
            border="none !important"
          >
            {Array.from({ length: 2 }, (_, i) => (
              <Box
                key={i}
                borderRadius="12px"
                border="2px solid"
                borderColor="blue.20"
                padding="20px"
              >
                <Image minWidth="40px" alt="Banner do componente" src={imagePlaceholderSource} />
              </Box>
            ))}
          </ComponentBox>
        );
      case 'label':
        return (
          <ComponentBox {...baseProps} marginBottom="32px !important">
            <Icon icon="icon-text" color="#CCD6E5" fontSize={44} />
          </ComponentBox>
        );
      case 'showcase':
        return (
          <ComponentBox
            {...baseProps}
            display="flex"
            justifyContent="space-between"
            padding="0 0 32px"
            border="none !important"
          >
            {Array.from({ length: 3 }, (_, i) => (
              <Box
                key={i}
                borderRadius="12px"
                border="2px solid"
                borderColor="blue.20"
                padding="20px"
              >
                <Image minWidth="40px" alt="Banner do componente" src={imagePlaceholderSource} />
              </Box>
            ))}
          </ComponentBox>
        );
      case 'story':
        return (
          <ComponentBox
            {...baseProps}
            display="flex"
            justifyContent="space-between"
            padding="0 0 32px"
            border="none !important"
          >
            {renderStory()}
          </ComponentBox>
        );
      default:
        return null;
    }
  });

  return (
    <Box
      backgroundColor="white"
      padding="30px 24px 0"
      overflow="auto"
      flex="1"
      minWidth="340px"
      __css={{
        '&::-webkit-scrollbar': {
          w: '2',
          h: '2',
        },

        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10',
          bg: `gray.300`,
        },
      }}
    >
      <Box height="0px">
        <Box marginBottom="30px">
          <Box marginBottom="40px">
            <Heading fontSize="xl">Componentes</Heading>
            <HeaderDescription>Insira os componentes da campanha</HeaderDescription>
          </Box>
          {components}
        </Box>
      </Box>
    </Box>
  );
}

export default Components;
