import { useRef } from 'react';

const useDebounce = () => {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  return <T extends unknown[]>(callback: (...args: T) => unknown, ms: number) =>
    (...args: T) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        callback(...args);
      }, ms);
    };
};

export default useDebounce;
