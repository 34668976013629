import {
  Box,
  Flex,
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
  useConst,
  useMediaQuery,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import DateTime from '../../../shared/components/Form/DateTime';
import { TextEllipsis } from '../../../shared/components/Typography';
import { ICampaign } from '../../../shared/services/types/campaign';
import useStore from '../../../shared/store';
import { Form } from '../Campaign.types';

const FormControl = styled(ChakraFormControl)`
  &:not(last-child) {
    margin-bottom: 18px;
  }
`;

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
} as const;

function CampaignForm({ campaign }: { campaign: ICampaign | undefined }) {
  const { campaignId } = useParams();
  const [isLargerThan1505] = useMediaQuery('(min-width: 1505px)');
  const { register, formState, control, getValues } = useFormContext<Form>();
  const user = useStore((state) => state.auth.login.data);
  const minStartDate = useConst(() => new Date().toISOString());

  const { errors, dirtyFields } = formState;

  const addEndDate = () => {
    localStorage.setItem('@end-campaign-date', getValues('endDate'));
  };

  const endDateField = (
    <FormControl
      minWidth="300px"
      width={isLargerThan1505 ? '48%' : '100%'}
      isRequired
      isInvalid={!!errors.endDate}
      onBlur={addEndDate}
    >
      <FormLabel>Data de finalização</FormLabel>
      <Controller
        name="startDate"
        control={control}
        render={({ field: { value: startDate } }) => (
          <Controller
            name="endDate"
            control={control}
            rules={{ deps: 'startDate' }}
            render={({ field: { value, onChange } }) => (
              <DateTime
                highlightMode
                value={value}
                minValue={startDate}
                onChange={onChange}
                startHighlight={startDate}
                endHighlight={value}
              />
            )}
          />
        )}
      />
      <FormErrorMessage>{errors.endDate?.message}</FormErrorMessage>
    </FormControl>
  );

  return (
    <Flex>
      <Box flex="1" marginRight="80px">
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>Nome</FormLabel>
          <Input placeholder="Digite o nome da campanha" {...register('name')} />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.description}>
          <FormLabel>Descrição</FormLabel>
          <Textarea
            resize="none"
            placeholder="Digite a descrição da campanha"
            minHeight="160px"
            {...register('description')}
          />
          <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
        </FormControl>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <FormControl
            minWidth="300px"
            width={isLargerThan1505 ? '48%' : '100%'}
            isRequired
            isInvalid={!!errors.startDate}
          >
            <FormLabel>Data de início</FormLabel>
            <Controller
              name="endDate"
              control={control}
              render={({ field: { value: endDate } }) => (
                <Controller
                  name="startDate"
                  control={control}
                  rules={{ deps: 'endDate' }}
                  render={({ field: { value, onChange } }) => (
                    <DateTime
                      highlightMode
                      minValue={minStartDate}
                      startHighlight={value}
                      endHighlight={endDate}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              )}
            />
            <FormErrorMessage>{errors.startDate?.message}</FormErrorMessage>
          </FormControl>
          {(campaignId || dirtyFields.startDate) && endDateField}
        </Flex>
      </Box>
      <Box backgroundColor="gray.5" padding="32px 24px" width="500px" borderRadius="16px">
        <Text marginBottom="24px" fontWeight="bold" fontSize="xl">
          Informações da campanha
        </Text>
        <Box
          backgroundColor="white"
          borderRadius="12px"
          padding="24px"
          marginBottom="24px"
          boxShadow="lg"
        >
          <Text fontWeight="bold">Criado por</Text>
          <TextEllipsis maxWidth="200px" color="gray.80">
            {campaign ? campaign.createdBy : user!.name}
          </TextEllipsis>
        </Box>
        <Box backgroundColor="white" borderRadius="16px" padding="24px" boxShadow="lg">
          <Text fontWeight="bold">Nome da campanha</Text>
          <Controller
            name="name"
            control={control}
            render={({ field: { value } }) => (
              <TextEllipsis maxWidth="350px" color="gray.80">
                {value || 'Não informado'}
              </TextEllipsis>
            )}
          />
          <Text fontWeight="bold" marginTop="12px">
            Descrição da campanha
          </Text>
          <Controller
            name="description"
            control={control}
            render={({ field: { value } }) => (
              <Text
                color="gray.80"
                whiteSpace="pre-wrap"
                minHeight="30px"
                maxHeight="100px"
                overflow="auto"
                lineHeight="1.2"
              >
                {value || 'Não informado'}
              </Text>
            )}
          />
          <Text fontWeight="bold" marginTop="12px">
            Data e horário de início
          </Text>
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value } }) => (
              <Text color="gray.80">{new Date(value).toLocaleString('pt-BR', DATE_OPTIONS)}</Text>
            )}
          />
          <Text fontWeight="bold" marginTop="12px">
            Data e horário de finalização
          </Text>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { value } }) => (
              <Text color="gray.80">{new Date(value).toLocaleString('pt-BR', DATE_OPTIONS)}</Text>
            )}
          />
        </Box>
      </Box>
    </Flex>
  );
}

export default CampaignForm;
