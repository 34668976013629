import {
  Box,
  Button,
  Spinner,
  Text,
  DrawerCloseButton as CUIDrawerCloseButton,
  DrawerHeader as CUIDrawerHeader,
  DrawerBody as CUIDrawerBody,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import Pagination from '../components/Pagination';

export const PageBox = styled(Box)`
  padding: 40px 30px 0 45px;
`;

export const PageSpinner = styled(Spinner)`
  color: var(--chakra-colors-primary-100);
  margin-bottom: 20px;
`;
PageSpinner.defaultProps = { thickness: '4px', size: 'xl' };

export const TablePagination = styled(Pagination)`
  padding: 18px 24px;
  border-top: 1px solid var(--chakra-colors-gray-10);
`;

export const SimpleText = styled(Text)`
  display: inline;
`;
SimpleText.defaultProps = { as: 'span' };

export const PrimaryOutlineButton = styled(Button)`
  flex: 1;
  margin-right: 18px;
  color: var(--chakra-colors-primary-100);
  border-color: var(--chakra-colors-primary-100);

  :hover {
    background-color: var(--chakra-colors-gray-5);
  }

  :disabled {
    border: none;

    :hover {
      background-color: var(--chakra-colors-gray-80);
    }
  }
`;
PrimaryOutlineButton.defaultProps = { variant: 'outline' };

export const DrawerCloseButton = styled(CUIDrawerCloseButton)`
  top: 24px;
  right: 24px;
  color: var(--chakra-colors-gray-40);
`;
DrawerCloseButton.defaultProps = { size: 'lg' };

export const DrawerHeader = styled(CUIDrawerHeader)`
  padding: 48px 32px 40px;
  color: var(--chakra-colors-gray-80);
`;

export const DrawerBody = styled(CUIDrawerBody)`
  padding: 0 32px;
`;
