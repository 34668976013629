import { Menu, MenuButton, MenuItem, MenuList, Tooltip, useDisclosure } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import useStore from '../../../shared/store';
import NewPasswordModal from './NewPasswordModal';

function UserMenu({ children }: PropsWithChildren) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setEditUser } = useStore((state) => state.api);
  const user = useStore((state) => state.auth.login.data);

  const handleClick = () => setEditUser(user!.id);

  return (
    <>
      <Menu>
        <Tooltip label="Abrir menu do perfil">
          <MenuButton aria-label="Mostrar opções de usuário">{children}</MenuButton>
        </Tooltip>
        <MenuList>
          <MenuItem onClick={handleClick}>Meu perfil</MenuItem>
          <MenuItem onClick={onOpen}>Atualizar senha</MenuItem>
        </MenuList>
      </Menu>
      <NewPasswordModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export default UserMenu;
