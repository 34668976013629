import axios from 'axios';

let privateController = new AbortController();

/**
 * Base api and auth-only api instances, always ensure to add only global configs on this file.
 */

const BASE_CONFIG = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const api = axios.create(BASE_CONFIG);
export const privateApi = axios.create({ ...BASE_CONFIG, signal: privateController.signal });

export const setAuthorization = (token: string) => {
  privateApi.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const clearAuthorization = () => {
  delete privateApi.defaults.headers.common.Authorization;
};

export const privateApiAbort = () => {
  privateController.abort();
};

export const resetPrivateApiAbort = () => {
  privateController = new AbortController();
  privateApi.defaults.signal = privateController.signal;
};

export default api;
