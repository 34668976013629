export const isValidDate = (date: any) => date instanceof Date && !Number.isNaN(date.valueOf());

export const isEqualDateOnly = (date: Date, date2 = new Date()) =>
  date.toDateString() === date2.toDateString();

export const getMonthDays = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

export const getFirstDayOfMonth = (date: Date) => {
  const firstDayOfMonth = new Date(date);
  firstDayOfMonth.setDate(1);

  return firstDayOfMonth.getDay();
};

export const isOffset = (day: number, date: Date) => day < 1 || day > getMonthDays(date);

export const getOffsetDay = (day: number, date: Date) => {
  const prevMonth = new Date(date);
  prevMonth.setMonth(prevMonth.getMonth() - 1);

  if (day < 1) {
    return getMonthDays(prevMonth) + day;
  }

  return day - getMonthDays(date);
};

export const toDateOnly = (date: Date) => {
  const newDate = new Date(date);

  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);

  return newDate;
};
