import { Box, Button, Drawer, DrawerContent, DrawerOverlay, Text } from '@chakra-ui/react';
import React from 'react';
import Header, { HeaderDescription, HeaderTitle } from '../../../shared/components/Header';
import { ICampaignCreate, UpsertCampaign } from '../../../shared/services/types/campaign';
import useStore from '../../../shared/store';
import { useCampaingCountdown } from '../../../shared/store/useCampaignCountdown';
import { DrawerBody, DrawerCloseButton, DrawerHeader } from '../../../shared/styles';

type Props = {
  campaign: UpsertCampaign;
  data: ICampaignCreate & { id?: string };
  isOpen: boolean;
  onClose: () => void;
};

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
} as const;

function Review({ campaign, data, isOpen, onClose }: Props) {
  const isLoadingSave = useStore((state) => state.campaigns.publishCampaign.isLoading);
  const { publishCampaign } = useStore((state) => state.api);
  const { countdown } = useCampaingCountdown((state) => state);

  const updatedComponents = campaign.components?.map((component: any) => {
    let transformedComponent = component;

    if (component.type === 'grid') {
      transformedComponent = {
        ...component,
        content: {
          ...component.content,
          columns: '2',
        },
      };
    }

    if (countdown.label) {
      transformedComponent = {
        ...transformedComponent,
        content: {
          ...transformedComponent.content,
          items: transformedComponent?.content?.items?.map((item: any) => ({
            ...item,
            countdown,
          })),
        },
      };
    }

    return transformedComponent;
  });

  const updatedCampaing = {
    ...campaign,
    components: updatedComponents,
  } as UpsertCampaign;

  const handleClick = async () => {
    await publishCampaign(updatedCampaing);
    onClose();
  };

  if (data === null) {
    return null;
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Header>
            <HeaderTitle icon="icon-external-link">
              Publicar campanha
              <HeaderDescription>
                Confirme as informações abaixo e publique a campanha
              </HeaderDescription>
            </HeaderTitle>
          </Header>
        </DrawerHeader>
        <DrawerBody>
          <Box padding="24px" borderRadius="12px" backgroundColor="gray.5">
            <Box backgroundColor="white" padding="12px 24px" borderRadius="12px" boxShadow="lg">
              <Text fontWeight="bold">Nome da campanha</Text>
              <Text maxWidth="350px" color="gray.80">
                {data.name}
              </Text>
            </Box>
            <Box
              backgroundColor="white"
              padding="12px 24px"
              borderRadius="12px"
              boxShadow="lg"
              marginTop="18px"
            >
              <Text fontWeight="bold">Descrição da campanha</Text>
              <Text
                color="gray.80"
                whiteSpace="pre-wrap"
                minHeight="30px"
                maxHeight="200px"
                overflow="auto"
                lineHeight="1.2"
              >
                {data.description}
              </Text>
            </Box>
            <Box
              backgroundColor="white"
              padding="12px 24px"
              borderRadius="12px"
              boxShadow="lg"
              marginTop="18px"
            >
              <Text fontWeight="bold">Data e horário de início</Text>
              <Text color="gray.80">
                {new Date(data!.startDate).toLocaleString('pt-BR', DATE_OPTIONS)}
              </Text>
            </Box>
            <Box
              backgroundColor="white"
              padding="12px 24px"
              borderRadius="12px"
              boxShadow="lg"
              marginTop="18px"
            >
              <Text fontWeight="bold">Data e horário de finalização</Text>
              <Text color="gray.80">
                {new Date(data!.endDate).toLocaleString('pt-BR', DATE_OPTIONS)}
              </Text>
            </Box>
          </Box>
          <Button
            colorScheme="primary"
            width="100%"
            marginTop="24px"
            onClick={handleClick}
            isLoading={isLoadingSave}
            disabled={isLoadingSave}
          >
            Publicar campanha
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default Review;
