import { Box, Button, Center } from '@chakra-ui/react';
import { Droppable, DroppableProvided } from '@hello-pangea/dnd';
import React, { useEffect } from 'react';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import Header, { HeaderDescription, HeaderTitle } from '../../shared/components/Header';
import Icon from '../../shared/components/Icon';
import Table, { Th, Tbody } from '../../shared/components/Table';
import toast from '../../shared/components/Toast';
import DragDropBaseProvider from '../../shared/contexts/DragDropBaseContext';
import useStore from '../../shared/store';
import { PageBox, PageSpinner, SimpleText } from '../../shared/styles';
import EditScreenDrawer from './components/EditScreenDrawer';
import ScreenItem from './components/ScreenItem';
import { isScreenAlmostObsolete } from './shared';

const openExpirationWarning = () => {
  const TOAST_ID = 'SCREEN_EXPIRATION_ID';

  if (!toast.isActive(TOAST_ID)) {
    toast({
      duration: 5000,
      id: TOAST_ID,
      status: 'warning',
      title: 'Atenção! Existem campanhas que expirarão em breve.',
      description: 'Por gentileza, verifique e publique novas campanhas',
    });
  }
};

const columns = [
  '',
  'Tela',
  'Ativação',
  'Campanha vigente',
  'Data fim da campanha vigente',
  'Ações',
].map((column: string) => (
  <Th key={column} paddingX={{ base: '12px !important', '2xl': 'auto !important' }}>
    <SimpleText
      fontSize={{ base: '15px', xl: '16px', '2xl': '19px' }}
      fontWeight="semibold"
      textTransform="none"
    >
      {column}
    </SimpleText>
  </Th>
));

// eslint-disable-next-line react/function-component-definition
const renderBody = (dropProvided: DroppableProvided) => (items: JSX.Element[]) =>
  (
    <Tbody>
      <>
        {items}
        {dropProvided.placeholder}
      </>
    </Tbody>
  );

function ScreenList() {
  const { data, isLoading } = useStore((state) => state.screens.fetchScreens);
  const isLoadingDelete = useStore((state) => state.screens.deleteScreen.isLoading);
  const deletion = useStore((state) => state.screens.deletion);
  const { fetchScreens, setEditScreen, setDeleteScreen, deleteScreen, orderScreens } = useStore(
    (state) => state.api
  );

  useEffect(() => {
    (async () => {
      const { screens } = await fetchScreens();

      if (screens.some(isScreenAlmostObsolete)) {
        openExpirationWarning();
      }
    })();
  }, [fetchScreens, isLoadingDelete]);

  const createScreen = () => setEditScreen('');
  const closeDeleteModal = () => setDeleteScreen(null);

  const onDelete = async () => {
    await deleteScreen(deletion.currentId!);
    closeDeleteModal();
  };

  const rows = data.screens.map((screen, index) => (
    <ScreenItem key={screen.id} screen={screen} index={index} />
  ));

  const loadingView = (
    <Center>
      <PageSpinner />
    </Center>
  );

  const tableView = data.screens.length && (
    <Droppable droppableId="screen-list">
      {(dropProvided) => (
        <Table
          size={{ base: 'sm', '2xl': 'md' }}
          columns={columns}
          rows={rows}
          renderBody={renderBody(dropProvided)}
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
        >
          <Box padding="20px" />
        </Table>
      )}
    </Droppable>
  );

  return (
    <PageBox>
      <Header marginBottom="40px">
        <HeaderTitle icon="icon-layout">
          Gestão de campanhas
          <HeaderDescription>
            Gerencie, edite e atualize as múltiplas telas do app Marisa
          </HeaderDescription>
        </HeaderTitle>
        <Button colorScheme="primary" size="lg" onClick={createScreen}>
          <Icon icon="icon-add" marginRight="5px" fontSize="xl" />
          Criar tela
        </Button>
      </Header>
      <DragDropBaseProvider onChangePosition={orderScreens}>
        {isLoading ? loadingView : tableView}
      </DragDropBaseProvider>
      <EditScreenDrawer />
      <ConfirmationModal
        isOpen={deletion.currentId !== null}
        onClose={closeDeleteModal}
        isCancelDisabled={isLoadingDelete}
        description="Todas as campanhas registradas nesta tela também serão excluídas"
      >
        <Button
          variant="solid"
          colorScheme="primary"
          onClick={onDelete}
          isLoading={isLoadingDelete}
          disabled={isLoadingDelete}
        >
          Excluir
        </Button>
      </ConfirmationModal>
    </PageBox>
  );
}

export default ScreenList;
