/* eslint-disable no-console */
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Input,
  useBoolean,
  Center,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  ChangeEvent,
  FocusEvent,
  useTransition,
  ChangeEventHandler,
} from 'react';
import { isEqualDateOnly, isValidDate } from '../../utils/date';
import Icon from '../Icon';

type Props = {
  /**
   * ISO 8601 Datetime string format
   */
  value: string;
  /**
   * @param datetime ISO 8601 Datetime string format
   */
  onChange: (datetime: string) => void;
  minValue?: string;
  isDisabled?: boolean;
};

const TimeSelect = styled(Box)`
  height: 231px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  & > * {
    display: flex !important;
    min-width: auto !important;
    padding: 0 !important;
    width: 60px !important;
    height: 44px !important;
    border-radius: 0 !important;

    :disabled {
      background-color: white !important;
      color: var(--chakra-colors-gray-60) !important;

      :hover {
        background-color: white !important;
        color: var(--chakra-colors-gray-60) !important;
      }
    }
  }
`;

const getTwoDigits = (value: number) => value.toString().padStart(2, '0');

const renderTime = (
  hour: number,
  minute: number,
  onClick: (hour: number, minute: number) => void,
  minHour: number = 0,
  minMinute: number = 0
) => {
  const handleMinutes = (m: number) => () => onClick(hour, m);

  const handleHours = (h: number) => () => onClick(h, minute);

  return (
    <Flex>
      <TimeSelect>
        {Array.from({ length: 24 - minHour }, (_, i) => {
          const currentHour = i + minHour;

          return (
            <Button
              key={currentHour}
              onClick={handleHours(currentHour)}
              backgroundColor={hour === currentHour ? 'gray.200' : 'white'}
            >
              {getTwoDigits(currentHour)}
            </Button>
          );
        })}
      </TimeSelect>
      <TimeSelect>
        {Array.from({ length: 60 - minMinute }, (_, i) => {
          const currentMinute = minMinute + i;

          return (
            <Button
              key={currentMinute}
              onClick={handleMinutes(currentMinute)}
              backgroundColor={minute === currentMinute ? 'gray.200' : 'white'}
            >
              {getTwoDigits(currentMinute)}
            </Button>
          );
        })}
      </TimeSelect>
    </Flex>
  );
};

function Time(props: Props) {
  const { value, onChange, minValue, isDisabled } = props;

  const minutesInputRef = useRef<HTMLInputElement | null>(null);

  const dateValue = useMemo(() => new Date(value), [value]);
  const dateMinValue = useMemo(() => (minValue ? new Date(minValue) : null), [minValue]);

  const [isFocused, setFocus] = useBoolean();
  const [isPopoverOpen, setPopoverOpen] = useBoolean();
  const [hoursTypeCount, setHoursTypeCount] = useState(0);
  const [hours, setHours] = useState(dateValue.getHours());
  const [minutes, setMinutes] = useState(dateValue.getMinutes());
  const [, startHoursTransition] = useTransition();
  const [, startMinutesTransition] = useTransition();

  useEffect(() => {
    setHours(dateValue.getHours());
    setMinutes(dateValue.getMinutes());

    if (!isValidDate(dateValue) && process.env.NODE_ENV !== 'production') {
      console.error(`
        The DateTime component received a invalid datetime format, it should receive a ISO 8601 string format.
      `);
    }
  }, [dateValue]);

  useEffect(() => {
    if (hoursTypeCount > 0 && hoursTypeCount % 2 === 0) {
      minutesInputRef.current?.focus();
    }
  }, [hoursTypeCount]);

  const isMinDate = !!dateMinValue && isEqualDateOnly(dateMinValue, dateValue);
  const isMinHour = !!dateMinValue && dateMinValue.getHours() === dateValue.getHours();

  const onFocusInput = (e: FocusEvent<HTMLInputElement>) => {
    e.currentTarget.setSelectionRange(-1, -1);
    setFocus.on();
  };

  const handleTimeChange = (hour: number, minute: number) => {
    const newValue = new Date(dateValue);
    newValue.setHours(hour);
    newValue.setMinutes(minute);

    onChange(newValue.toISOString());
  };

  const handleHourChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newHours = parseInt(e.target.value, 10);
    const firstDigitValue = parseInt(e.target.value.slice(-1), 10);

    if (e.target.value === '') {
      newHours = 0;
    } else if (Number.isNaN(newHours)) {
      return;
    }

    if (newHours > 23) {
      newHours %= 10; // get only new digit here
    }

    e.currentTarget.setSelectionRange(-1, -1);
    setHours(newHours);

    if (firstDigitValue < 3) {
      setHoursTypeCount((state) => state + 1);
    } else {
      minutesInputRef.current?.focus();
      setHoursTypeCount(0);
    }

    startHoursTransition(() => {
      const newValue = new Date(dateValue);
      newValue.setHours(newHours);

      onChange(newValue.toISOString());
    });
  };

  const handleMinuteChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newMinutes = parseInt(e.target.value, 10);

    if (e.target.value === '') {
      newMinutes = 0;
    } else if (Number.isNaN(newMinutes)) {
      return;
    }

    if (newMinutes > 59) {
      newMinutes %= 10; // get only new digit here
    }

    e.currentTarget.setSelectionRange(-1, -1);
    setMinutes(newMinutes);

    startMinutesTransition(() => {
      const newValue = new Date(dateValue);
      newValue.setMinutes(newMinutes);

      onChange(newValue.toISOString());
    });
  };

  const renderInput = (
    input: string,
    onChangeInput: ChangeEventHandler,
    ref?: React.MutableRefObject<HTMLInputElement | null>
  ) => (
    <Input
      ref={ref}
      display="inline-block"
      width="30px"
      height="45px"
      padding="0"
      textAlign="center"
      border="none"
      _invalid={{ border: 'none' }}
      onFocus={onFocusInput}
      onBlur={setFocus.off}
      value={input}
      onChange={onChangeInput}
    />
  );

  return (
    <Popover
      isLazy
      isOpen={isPopoverOpen}
      onOpen={setPopoverOpen.on}
      onClose={setPopoverOpen.off}
      returnFocusOnClose={false}
      placement="bottom-start"
    >
      <PopoverAnchor>
        <Center
          paddingX="24px"
          borderRadius="0 8px 8px 0"
          borderStyle="solid"
          borderWidth={isFocused || isPopoverOpen ? '2px' : '1px'}
          borderColor={isFocused || isPopoverOpen ? 'primary.60' : 'gray.20'}
        >
          {renderInput(getTwoDigits(hours), handleHourChange)}:
          {renderInput(getTwoDigits(minutes), handleMinuteChange, minutesInputRef)}
          <Box
            as="button"
            marginLeft="6px"
            type="button"
            display="inline-block"
            height="20.25px"
            lineHeight="0"
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            disabled={isDisabled}
            onClick={setPopoverOpen.on}
          >
            <Icon
              icon="icon-clock"
              fontSize="lg"
              verticalAlign="middle"
              opacity={isDisabled ? 0.4 : 1}
              color={isPopoverOpen ? 'primary.100' : undefined}
            />
          </Box>
        </Center>
      </PopoverAnchor>
      <PopoverContent width="138.25" padding="0">
        <PopoverBody height="275px" padding="0" fontSize="14px" position="relative">
          {renderTime(
            dateValue.getHours(),
            dateValue.getMinutes(),
            handleTimeChange,
            isMinDate ? dateMinValue?.getHours() : undefined,
            isMinHour ? dateMinValue?.getMinutes() : undefined
          )}
          <Button
            position="fixed"
            bottom="0"
            borderRadius="0"
            width="100%"
            onClick={setPopoverOpen.off}
          >
            Salvar
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default Time;
