import { Box, Center, Flex, Image, Tooltip } from '@chakra-ui/react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import imagePlaceholderSource from '../../../assets/images/image-placeholder.png';
import ActionButton from '../../../shared/components/ActionButton';
import Icon from '../../../shared/components/Icon';
import DragDropBaseProvider from '../../../shared/contexts/DragDropBaseContext';
import { ComponentType, Item } from '../../../shared/services/types/campaign';
import useStore from '../../../shared/store';
import { getPluralCase } from '../../../shared/utils/i18n';
import { MediaFieldErrors, validateUrl } from '../../../shared/utils/validation';
import { MediaFieldCursor } from '../CampaignComponents.types';

type Props = {
  value: Partial<Item>[];
  type: ComponentType['type'];
  errors: MediaFieldErrors[];
  onChangePosition: (source: number, destination: number) => void;
  onAddMedia: () => void;
  onRemoveMedia: (position: number) => void;
  getCursor: () => MediaFieldCursor;
  setCursor: (position: number) => void;
  hasCountdown: boolean[];
};

const limitLengths = {
  banner: 1,
  grid: 2,
};

function MediaFieldList({
  value,
  type,
  errors,
  onChangePosition,
  onAddMedia,
  onRemoveMedia,
  getCursor,
  setCursor,
  hasCountdown,
}: Props) {
  const submitCountRef = useRef(0);

  const submitCount = useStore((state) => state.campaigns.upsert.submitCount);
  const isValid = errors.every((error) => Object.keys(error).length === 0);
  const [showDelectionButton, setshowDelectionButton] = useState(false);
  const [reloadAfterDelete, setReloadAfterDelete] = useState(false);

  useEffect(() => {
    if (!isValid && submitCountRef.current !== submitCount) {
      setCursor(errors.findIndex((error) => Object.keys(error).length > 0));
      submitCountRef.current = submitCount;
    }
  }, [submitCount, isValid, errors, setCursor, reloadAfterDelete]);

  const length = value.length;
  const shouldLimit = type === 'banner' || type === 'grid' ? length >= limitLengths[type] : false;

  let addButtonTooltip = 'Adicionar imagem';
  if (!isValid) {
    addButtonTooltip = 'Os campos das imagens devem ser válidos para adicionar nova imagem';
  } else if (shouldLimit) {
    const limit = limitLengths[type as keyof typeof limitLengths];
    const images = getPluralCase(limit, 'imagem', 'imagens');
    addButtonTooltip = `Este componente não suporta mais que ${limit} ${images}`;
  }

  const handleOnRemoveMedia =
    (position: number): MouseEventHandler =>
    () => {
      onRemoveMedia(position);
      setReloadAfterDelete(!reloadAfterDelete);
    };

  const handleBorderColor = (position: number) => {
    if (position === getCursor().position) {
      return '#CCD6E5';
    }
    if (submitCount > 0 && Object.keys(errors[position] || {}).length > 0) {
      return 'red.100';
    }

    return 'transparent';
  };

  const renderDeleteAction = (position: number) => (
    <ActionButton
      backgroundColor="rgba(0, 0, 0, 0.5)"
      color="white"
      position="absolute"
      minWidth="30px"
      height="30px"
      bottom="2px"
      left="3px"
      borderRadius="5px"
      opacity={showDelectionButton ? 1 : 0}
      _hover={{ color: 'white' }}
      aria-label="deletar mídia"
      title="Deletar mídia"
      icon={<Icon icon="icon-delete" fontSize="md" />}
      onClick={handleOnRemoveMedia(position)}
    />
  );

  const mark = (item: Partial<Item>, hasCountdownChecked: boolean) => {
    const statusUrl = item.url !== '#' ? validateUrl(String(item.url)) : true;

    const statusAnalytics = item.analyticsId !== '#' ? item.analyticsId : true;

    const statusStory = item.name === undefined ? true : !!item.name;

    const statusCountdown = !item.countdown && !hasCountdownChecked ? true : !!item.countdown;

    return statusUrl && statusAnalytics && statusStory && statusCountdown
      ? {
          content: '"\\2713"',
          position: 'absolute',
          width: '20px',
          height: '20px',
          bg: 'green.100',
          right: '-8px',
          top: '-7px',
          color: 'white',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }
      : {};
  };

  return (
    <DragDropBaseProvider onChangePosition={onChangePosition}>
      <Box marginTop="24px">
        <Droppable droppableId="media-field-list" direction="horizontal">
          {(dropProvided) => (
            <Flex overflowX="auto" {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
              <Tooltip label={addButtonTooltip}>
                <Center
                  as="button"
                  type="button"
                  minWidth="100px"
                  height="100px"
                  marginBottom="12px"
                  marginTop="12px"
                  backgroundColor="blue.5"
                  border="3px dashed"
                  borderRadius="lg"
                  borderColor="blue.20"
                  onClick={onAddMedia}
                  _disabled={{ opacity: 0.5, cursor: 'not-allowed' }}
                  disabled={shouldLimit || !isValid}
                >
                  <Icon icon="icon-add" fontSize="2xl" />
                </Center>
              </Tooltip>
              {Array.from({ length }, (_, position) => (
                <Draggable
                  index={position}
                  key={`${value[position]?.mediaId} ${position}`}
                  draggableId={`${value[position]?.mediaId} ${position}`}
                >
                  {(dragProvided) => (
                    <Box
                      width="100px"
                      height="100px"
                      position="relative"
                      marginBottom="12px"
                      marginTop="12px"
                      marginLeft="12px"
                      onMouseOver={() => setshowDelectionButton(true)}
                      onMouseLeave={() => setshowDelectionButton(false)}
                      onClick={() => setCursor(position)}
                      key={`${value[position]?.mediaId} ${position}`}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      ref={dragProvided.innerRef}
                      _before={mark(value[position], hasCountdown[position])}
                    >
                      <>{value[position]?.download ? renderDeleteAction(position) : null}</>
                      <Center
                        width="100px"
                        height="100px"
                        border="3px solid"
                        borderRadius="lg"
                        borderColor={handleBorderColor(position)}
                      >
                        <Image
                          width={value[position]?.download ? '100%' : '43px'}
                          height={value[position]?.download ? '100%' : '43px'}
                          borderRadius="md"
                          objectFit="cover"
                          src={value[position]?.download || imagePlaceholderSource}
                        />
                      </Center>
                    </Box>
                  )}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </Flex>
          )}
        </Droppable>
      </Box>
    </DragDropBaseProvider>
  );
}

export default MediaFieldList;
