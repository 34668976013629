/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Flex, Tooltip, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Icon from '../../shared/components/Icon';
import useStore from '../../shared/store';
import Sidebar from './components/Sidebar';
import SmallSidebar from './components/SmallSidebar';
import UserDrawer from './components/UserDrawer';
import CreateMediaModal from './components/CreateMediaModal';
import EditCategoryModal from './components/EditCategoryModal';

function Layout() {
  const location = useLocation();
  const [isLargerThan1148] = useMediaQuery('(min-width: 1148px)');
  const { setEditUser, expandSidebar } = useStore((state) => state.api);
  const layoutColor = useStore((state) => state.app.layoutColor);
  const isExpanded = useStore((state) => state.app.isSidebarExpanded);
  const hideExpansionButton = useStore((state) => state.app.hideExpansionButton);
  const closeDrawer = () => setEditUser(null);

  const toggleExpansion = () => {
    expandSidebar(!isExpanded);
  };

  useEffect(() => {
    expandSidebar(isLargerThan1148, !isLargerThan1148);
  }, [expandSidebar, isLargerThan1148, location]);

  const expansionButton = (
    <Box marginY="auto" position="absolute" zIndex="sticky" top="50%" transform="translateY(-50%)">
      <Tooltip label={isExpanded ? 'Diminuir barra lateral' : 'Expandir barra lateral'}>
        <Button
          display="inline-block"
          minWidth="auto"
          width="21px"
          height="70px"
          onClick={toggleExpansion}
          variant="unstyled"
          backgroundColor="white"
          borderRadius=" 0 12px 12px 0"
          boxShadow="rgba(0, 0, 0, 0.15) 5px 0px 20px -5px"
        >
          <Icon
            icon={isExpanded ? 'icon-caret-left' : 'icon-caret-right'}
            color="primary.100"
            fontSize="lg"
          />
        </Button>
      </Tooltip>
    </Box>
  );

  const exeptionShowExpansionSidebar = () => {
    const isComponentScreen = location.pathname.includes('/campanhas');
    if (isComponentScreen) {
      expandSidebar(false);
    }
  };

  useEffect(() => {
    exeptionShowExpansionSidebar();
  }, [location]);

  return (
    <Flex height="100%">
      {isExpanded ? <Sidebar /> : <SmallSidebar />}
      <Flex flex="1" overflow="auto" height="100%" backgroundColor={layoutColor}>
        {!hideExpansionButton && expansionButton}
        <Box flex="1" overflow="auto">
          <Outlet />
        </Box>
      </Flex>
      <UserDrawer onClose={closeDrawer} />
      <CreateMediaModal />
      <EditCategoryModal />
    </Flex>
  );
}

export default Layout;
