import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { ChangeEventHandler, FormEventHandler, useEffect, useState } from 'react';
import useStore from '../../../shared/store';

function EditCategoryModal() {
  const [name, setName] = useState('');
  const { setEditCategory, saveCategory } = useStore((state) => state.api);
  const { currentId, initialName } = useStore((state) => state.categories.upsert);
  const isLoading = useStore((state) => state.categories.saveCategory.isLoading);
  const category = useStore((state) =>
    state.categories.fetchCategories.data.categories.find((item) => item.id === currentId)
  );

  useEffect(() => {
    if (initialName) {
      setName(initialName);
    } else if (category) {
      setName(category.name);
    } else {
      setName('');
    }
  }, [category, initialName, currentId]);

  const isOpen = currentId !== null;
  const isEdit = category && isOpen;
  const isValid = isEdit ? name !== category.name : name !== '';
  const onClose = () => setEditCategory(null);
  const handleName: ChangeEventHandler<HTMLInputElement> = (e) => setName(e.target.value);

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    if (category) {
      await saveCategory({ id: category.id, name }, initialName);
    } else {
      await saveCategory({ name }, initialName);
    }

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent padding="32px">
        <ModalHeader padding="0 0 24px" color="gray.90" fontWeight="bold">
          {isEdit ? 'Editar' : 'Criar'} Categoria
        </ModalHeader>
        <ModalCloseButton size="lg" color="gray.40" />
        <ModalBody padding="0">
          <Box as="form" onSubmit={onSubmit}>
            <FormControl marginBottom="24px">
              <FormLabel>Nome</FormLabel>
              <Input value={name} onChange={handleName} placeholder="Digite o nome da categoria" />
            </FormControl>
            <Button
              type="submit"
              colorScheme="primary"
              width="100%"
              isLoading={isLoading}
              disabled={isLoading || !isValid}
            >
              Salvar
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EditCategoryModal;
