import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import ActionButton from '../../../shared/components/ActionButton';
import Icon from '../../../shared/components/Icon';
import Header, { HeaderDescription, HeaderTitle } from '../../../shared/components/Header';

import { UploadFile } from './UploadFile';

interface IUpdateMedia {
  parentClose: () => void;
}

export function UpdateMedia({ parentClose }: IUpdateMedia) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [image, setImage] = useState<File | null>(null);
  const [hasError, setHasError] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const handleUpload = async (imgUrl: string) => {
    try {
      setIsLoadingImage(true);
      const response = await fetch(imgUrl);
      const blob = await response.blob();
      const name = imgUrl.split('/').pop()!;
      const file = new File([blob!], name, { type: blob!.type });
      setImage(file);
    } catch (err) {
      setHasError(true);
      throw new Error();
    } finally {
      setIsLoadingImage(false);
    }
  };

  return (
    <>
      <ActionButton
        as="button"
        icon={<Icon icon="icon-regroup" />}
        aria-label="Substituir imagem"
        title="Substituir imagem"
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalContent minWidth="700px">
            <ModalHeader padding="32px 32px 12px">
              <Header>
                <HeaderTitle icon="icon-cloud-upload">
                  Enviar arquivo
                  <HeaderDescription>
                    Insira as informações abaixo para enviar um arquivo
                  </HeaderDescription>
                </HeaderTitle>
              </Header>
            </ModalHeader>
            <ModalCloseButton size="lg" color="gray.40" />
            <ModalBody padding="0 32px 32px">
              <Tabs isFitted onChange={() => ''} colorScheme="primary">
                <TabList>
                  <Tab>Upload do computador</Tab>
                  <Tab>Upload por URL</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <FormControl>
                      <UploadFile onClose={parentClose} />
                    </FormControl>
                  </TabPanel>
                  <TabPanel paddingX="0" paddingBottom="0">
                    {image && <Image src={`${image}`} />}
                    <FormControl>
                      <FormLabel>Insira a URL do arquivo</FormLabel>
                      <InputGroup border={`${hasError && '1px solid red'}`} borderRadius="9px">
                        <Input
                          placeholder="Digite a URL"
                          onChange={(e) => handleUpload(e.target.value)}
                        />
                        <InputRightElement>{isLoadingImage && <Spinner />}</InputRightElement>
                      </InputGroup>
                    </FormControl>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>
          </ModalContent>
        </ModalContent>
      </Modal>
    </>
  );
}
