/* eslint-disable no-console */
import {
  Box,
  Button,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  SimpleGrid,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { SimpleText } from '../../styles';
import {
  getFirstDayOfMonth,
  getMonthDays,
  getOffsetDay,
  isEqualDateOnly,
  isOffset,
  isValidDate,
  toDateOnly,
} from '../../utils/date';
import ActionButton from '../ActionButton';
import Icon from '../Icon';

type Props = {
  /**
   * ISO 8601 Datetime string format
   */
  value: string;
  /**
   * @param datetime ISO 8601 Datetime string format
   */
  onChange: (datetime: string) => void;
  minValue?: string;
  highlightMode?: boolean;
  startHighlight?: string;
  endHighlight?: string;
  isDisabled?: boolean;
};

const LENGTH = 42;

function DateInput({
  value,
  onChange,
  minValue,
  highlightMode = false,
  startHighlight = value,
  endHighlight = value,
  isDisabled,
}: Props) {
  const dateValue = useMemo(() => new Date(value), [value]);
  const dateMinValue = useMemo(() => (minValue ? new Date(minValue) : null), [minValue]);
  const [isFocused, setFocus] = useBoolean();
  const [popoverValue, setValue] = useState(dateValue);

  useEffect(() => {
    if (isFocused) {
      setValue(dateValue);
    }

    if (!isValidDate(dateValue) && process.env.NODE_ENV !== 'production') {
      console.error(`
        The DateTime component received a invalid datetime format, it should receive a ISO 8601 string format.
      `);
    }
  }, [dateValue, isFocused]);

  const startHighlightDate = new Date(startHighlight);
  const endHighlightDate = new Date(endHighlight);

  const onNextMonth = () => {
    const newValue = new Date(popoverValue);
    newValue.setMonth(newValue.getMonth() + 1);

    setValue(newValue);
  };

  const onPrevMonth = () => {
    const newValue = new Date(popoverValue);
    newValue.setMonth(newValue.getMonth() - 1);

    setValue(newValue);
  };

  const handleFocus = () => setFocus.on();

  const handleChange = (dayOfMonth: number) => () => {
    const newValue = new Date(popoverValue);
    newValue.setDate(dayOfMonth);

    onChange(newValue.toISOString());
    setFocus.off();
  };

  const calendarView = Array.from({ length: LENGTH }, (_, i) => {
    const day = i - getFirstDayOfMonth(popoverValue) + 1;
    const days = getMonthDays(popoverValue);
    const date = new Date(popoverValue);
    date.setDate(day);

    const dateOnly = toDateOnly(date);
    const isBeforeMin = dateMinValue ? toDateOnly(dateMinValue) > dateOnly : false;

    const isHighlight =
      highlightMode &&
      dateOnly >= toDateOnly(startHighlightDate) &&
      dateOnly <= toDateOnly(endHighlightDate);

    const isStartEdge =
      isEqualDateOnly(date, startHighlightDate) || date.getDay() === 0 || day === 1;
    const isEndEdge =
      isEqualDateOnly(date, endHighlightDate) || date.getDay() === 6 || day === days;

    return (
      <Button
        padding="4px"
        width="auto"
        height="auto"
        fontSize="16px"
        borderLeftRadius={isHighlight && isStartEdge ? '16px' : 'none'}
        borderRightRadius={isHighlight && isEndEdge ? '16px' : 'none'}
        color={isHighlight ? 'white' : 'gray.80'}
        backgroundColor={isHighlight ? 'primary.100' : 'white'}
        _disabled={{
          color: 'gray.40',
          backgroundColor: 'white',
          cursor: 'default',
          _hover: { color: 'gray.40', backgroundColor: 'white' },
          _active: { backgroundColor: 'white' },
        }}
        _hover={{
          backgroundColor: isHighlight ? 'primary.100' : 'white',
          color: isHighlight ? 'white' : 'black',
        }}
        key={`${popoverValue.toDateString()} ${day}`}
        onClick={handleChange(day)}
        disabled={isOffset(day, popoverValue) || (isBeforeMin && !isHighlight)}
      >
        {isOffset(day, popoverValue) ? getOffsetDay(day, popoverValue) : day}
      </Button>
    );
  });

  return (
    <Popover
      isOpen={isFocused}
      onOpen={setFocus.on}
      onClose={setFocus.off}
      returnFocusOnClose={false}
      placement="bottom-start"
    >
      <PopoverAnchor>
        <Box
          as="button"
          type="button"
          textAlign="start"
          paddingX="24px"
          flex="1"
          borderRadius="8px 0 0 8px"
          onClick={handleFocus}
          borderWidth={isFocused ? '2px' : '1px'}
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          borderColor={isFocused ? 'primary.60' : 'gray.20'}
          disabled={isDisabled}
        >
          <SimpleText opacity={isDisabled ? 0.4 : 1} verticalAlign="middle">
            {dateValue.toLocaleDateString('pt-BR')}
          </SimpleText>
          <Icon
            icon="icon-calendar"
            fontSize="lg"
            marginLeft="6px"
            verticalAlign="middle"
            color={isFocused ? 'primary.100' : undefined}
            opacity={isDisabled ? 0.4 : 1}
          />
        </Box>
      </PopoverAnchor>
      <PopoverContent>
        <PopoverBody minWidth="125.5px">
          <Text
            color="gray.80"
            lineHeight="none"
            fontSize="lg"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ActionButton
              icon={<Icon icon="icon-chevron-left" fontSize="xl" />}
              aria-label="Ir para mês anterior"
              title="Ir para mês anterior"
              onClick={onPrevMonth}
              disabled={
                dateMinValue?.getMonth() === popoverValue.getMonth() &&
                dateMinValue.getFullYear() === popoverValue.getFullYear()
              }
            />
            <SimpleText textTransform="capitalize">
              {popoverValue.toLocaleDateString('pt-BR', { month: 'long' })}{' '}
              {popoverValue.getFullYear()}
            </SimpleText>
            <ActionButton
              icon={<Icon icon="icon-chevron-right" fontSize="xl" />}
              aria-label="Ir para próximo mês"
              title="Ir para próximo mês"
              onClick={onNextMonth}
            />
          </Text>
          <SimpleGrid
            columns={7}
            rowGap="4px"
            color="gray.80"
            fontWeight="bold"
            fontSize="14px"
            textAlign="center"
            paddingY="6px"
          >
            <Text>DOM</Text>
            <Text>SEG</Text>
            <Text>TER</Text>
            <Text>QUA</Text>
            <Text>QUI</Text>
            <Text>SEX</Text>
            <Text>SÁB</Text>
            {calendarView}
          </SimpleGrid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default DateInput;
