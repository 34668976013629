import { Box, BoxProps, Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import React, { ChangeEventHandler, FormEventHandler, useState, useTransition } from 'react';
import Icon from '../Icon';

type Props = {
  placeholder?: string;
  onSearch: (search: string) => void;
  onChange?: (search: string) => void;
  hideUploadButon?: boolean;
};

function Search({
  placeholder,
  onSearch,
  onChange,
  hideUploadButon,
  ...props
}: Props & Omit<BoxProps, 'onChange'>) {
  const [query, setQuery] = useState('');
  const [, startTranstion] = useTransition();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setQuery(e.target.value);

    if (onChange) {
      startTranstion(() => {
        onChange(e.target.value);
      });
    }
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    onSearch(query);
  };

  return (
    <Box
      width="100%"
      marginRight={hideUploadButon ? '' : '24px'}
      as="form"
      onSubmit={handleSubmit}
      {...props}
    >
      <InputGroup>
        <Input height="49px" placeholder={placeholder} value={query} onChange={handleChange} />
        <InputRightElement>
          <Button
            display="inline-flex"
            type="submit"
            color="primary.100"
            fontSize="xl"
            variant="unstyled"
            height="54px"
          >
            <Icon icon="icon-search" />
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  );
}

export default Search;
