import { Box, Button, Center, Flex, HStack, List, ListItem, Text } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { LogoSmall } from '../../../shared/components/Logo';
import useStore from '../../../shared/store';
import Icon from '../../../shared/components/Icon';
import { links } from '../shared';
import UserMenu from './UserMenu';

function SmallSidebar() {
  const user = useStore((state) => state.auth.login.data);
  const { logout } = useStore((state) => state.api);

  const userNames = user!.name.split(' ').slice(0, 2);
  const userProfileSize = `${30 + userNames.length * 15}px`;

  const navLinks = links.reduce<JSX.Element[]>((acc, link) => {
    const { filter = [], name, to, icon } = link;
    const isFiltered = filter.some((role) => role === user!.role.name);
    const navLink = (
      <ListItem key={name}>
        <NavLink to={to}>
          {({ isActive }) => (
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              height="60px"
              _hover={{ textDecoration: 'none', fontWeight: 'bold' }}
              color={isActive ? 'primary.100' : 'gray.50'}
              fontWeight={isActive ? 'bold' : 'semibold'}
            >
              <Box
                position="absolute"
                border="4.5px solid transparent"
                height="100%"
                borderLeftColor={isActive ? 'primary.100' : 'transparent'}
              />
              <Icon icon={icon} fontSize="2em" width="100%" textAlign="center" />
            </Box>
          )}
        </NavLink>
      </ListItem>
    );

    return isFiltered ? acc : [...acc, navLink];
  }, []);

  return (
    <Flex
      position="relative"
      zIndex="sticky"
      flexDirection="column"
      minWidth="100px"
      backgroundColor="white"
      paddingBottom="30px"
      boxShadow="rgba(0, 0, 0, 0.1) 5px 0px 25px -5px, rgba(0, 0, 0, 0.04) 0px 0px 25px -5px"
    >
      <HStack
        spacing={0}
        height="80px"
        justifyContent="center"
        alignItems="center"
        marginBottom="20px"
        backgroundColor="primary.100"
        position="relative"
      >
        <Center borderRadius="50%" width="60px" height="60px" backgroundColor="white">
          <LogoSmall width="30px" />
        </Center>
      </HStack>
      <HStack
        spacing={0}
        marginBottom="20px"
        paddingBottom="20px"
        borderBottom="1px solid"
        borderColor="gray.10"
        justifyContent="center"
      >
        <UserMenu>
          <Box fontSize="xl">
            <Center
              color="white"
              backgroundColor="primary.100"
              borderRadius="50%"
              marginX="auto"
              width={userProfileSize}
              height={userProfileSize}
              fontWeight="bold"
            >
              <Text>{userNames.map((word) => word[0])}</Text>
            </Center>
          </Box>
        </UserMenu>
      </HStack>
      <HStack spacing={0} flexDirection="column" alignItems="stretch">
        <Text color="gray.80" fontWeight="semibold" padding="0 20px 15px" textAlign="center">
          Ações
        </Text>
        <List spacing={0} fontSize="16px">
          {navLinks}
        </List>
      </HStack>
      <HStack spacing={0} marginTop="auto">
        <Button
          onClick={logout}
          color="red"
          marginX="auto"
          variant="unstyled"
          fontWeight="normal"
          fontSize="lg"
        >
          <Icon icon="icon-log-out" fontSize="xl" verticalAlign="middle" />
        </Button>
      </HStack>
    </Flex>
  );
}

export default SmallSidebar;
