import create from 'zustand';

type Countdown = {
  label: string;
  endDate: string;
  helper: {
    title: string;
    description: string;
    buttonText: string;
    buttonLink?: string;
  };
};

type State = {
  currentDate: string;
  endDate: string;
  showHelperModal: boolean;
  countdown: Countdown;
  addDate: (date: string) => void;
  addEndDate: (date: string) => void;
  toggleHelperModal: () => void;
  addCountdown: (countdown: Countdown) => void;
};

export const useCampaingCountdown = create<State>((set) => ({
  currentDate: '',
  endDate: '',
  showHelperModal: false,
  countdown: {
    label: '',
    endDate: '',
    backgroundColor: '',
    fontColor: '',
    helper: {
      title: '',
      description: '',
      buttonText: '',
      buttonLink: '',
    },
  },

  addDate: (currentDate: string) => {
    set({ currentDate });
  },
  addEndDate: (endDate: string) => {
    set({ endDate });
  },
  addCountdown: (countdown: Countdown) => {
    set({ countdown });
  },

  toggleHelperModal: () => set((state) => ({ showHelperModal: !state.showHelperModal })),
}));
