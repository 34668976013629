import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import ActionButton from '../../../shared/components/ActionButton';
import Icon from '../../../shared/components/Icon';
import { passwordConfirmSchema, passwordSchema } from '../../../shared/schemas/password';
import { IUserResponse } from '../../../shared/services/types/user';
import useStore from '../../../shared/store';

type Props = {
  user: IUserResponse;
};

const defaultValues = {
  newPassword: '',
  newPasswordConfirm: '',
};

const formSchema = yup.object().shape({
  newPassword: passwordSchema,
  newPasswordConfirm: passwordConfirmSchema('newPassword'),
});

function PasswordResetPopover({ user }: Props) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const initialRef = useRef<HTMLInputElement | null>(null);
  const { isLoading } = useStore((state) => state.users.newPassword);
  const { newPassword } = useStore((state) => state.api);

  const { register, formState, handleSubmit, reset } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  const { isValid, errors } = formState;
  const newPasswordRegister = register('newPassword');

  const newPasswordRef = (ref: HTMLInputElement) => {
    initialRef.current = ref;
    newPasswordRegister.ref(ref);
  };

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const onSubmit = handleSubmit(async (values) => {
    await newPassword({ ...values, userId: user.id });
    handleClose();
  });

  const formId = `new-password-form-${user.id}`;

  return (
    <Popover placement="right" initialFocusRef={initialRef} isOpen={isOpen} onClose={handleClose}>
      <PopoverTrigger>
        <ActionButton
          onClick={onToggle}
          icon={<Icon icon="icon-key-ii" fontSize="2xl" />}
          aria-label={`Atualizar senha do usuário ${user.name}`}
          title="Atualizar senha do usuário"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Box as="form" id={formId} onSubmit={onSubmit} padding="10px">
            <FormControl isInvalid={!!errors.newPassword} marginBottom="20px">
              <FormLabel fontWeight="bold">Nova senha</FormLabel>
              <Input
                type="password"
                placeholder="Digite a nova senha do usuário"
                {...newPasswordRegister}
                ref={newPasswordRef}
              />
              <FormErrorMessage whiteSpace="initial">
                {errors.newPassword?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.newPasswordConfirm}>
              <FormLabel fontWeight="bold">Confirmação da nova senha</FormLabel>
              <Input
                type="password"
                placeholder="Confirme a nova senha"
                {...register('newPasswordConfirm')}
              />
              <FormErrorMessage whiteSpace="initial">
                {errors.newPasswordConfirm?.message}
              </FormErrorMessage>
            </FormControl>
            <Flex marginTop="25px">
              <Button
                variant="outline"
                marginLeft="auto"
                marginRight="10px"
                onClick={handleClose}
                disabled={isLoading}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="primary"
                disabled={!isValid || isLoading}
                isLoading={isLoading}
              >
                Atualizar senha
              </Button>
            </Flex>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default PasswordResetPopover;
