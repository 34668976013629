import { forwardRef, Tooltip, IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';

const ActionButton = forwardRef(({ title, ...rest }: IconButtonProps, ref) => (
  <Tooltip label={title}>
    <IconButton
      variant="unstyled"
      size="sm"
      fontSize="lg"
      color="gray.70"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      _hover={{ color: 'black' }}
      _disabled={{
        _hover: { backgroundColor: 'transparent', color: 'gray.70', cursor: 'not-allowed' },
      }}
      {...rest}
      ref={ref}
    />
  </Tooltip>
));

export default ActionButton;
