import history from '../../history';
import createService from '../store/serverState';
import { Getter, Setter } from '../store/store.types';
import AuthService from './AuthService';

export const createAppEndpoints = (set: Setter, get: Getter) => {
  const createAppEndpoint = createService({ set, getSlice: ({ app }) => app, prefix: 'app' });

  return {
    initializeApp: createAppEndpoint(
      'initializeApp',
      async () => {
        try {
          const storagedUser = AuthService.getAuth();
          if (!storagedUser) {
            history.push('/login');
          }
          if (!get().auth.login.data) {
            const data = await AuthService.me();
            get().api.setUser(data);

            return data;
          }
        } catch (error) {
          AuthService.clearAuth();
          history.push('/login');

          throw error;
        }

        return null;
      },
      { placeholder: null }
    ),
  };
};
