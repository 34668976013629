import * as yup from 'yup';
import { yupPortuguese } from '../utils/i18n';
import { STRONG_PASSWORD_REGEX } from '../utils/validation';

yup.setLocale(yupPortuguese);

export const passwordSchema = yup
  .string()
  .min(8)
  .max(12)
  .matches(STRONG_PASSWORD_REGEX, 'A senha deve seguir todos os critérios de segurança');

export const passwordConfirmSchema = (passwordKey: string) =>
  yup.string().oneOf([yup.ref(passwordKey)], 'A senha deve ser idêntica a informada acima');
