import { createSearchParams } from 'react-router-dom';
import { privateApi } from '../../api';
import history from '../../history';
import { responseToast } from '../components/Toast';
import createService from '../store/serverState';
import { Getter, ParamsWithPage, Setter } from '../store/store.types';
import { findById, getPage, getParam, handlePaginationOnDeletion } from '../utils/requestHelpers';
import { isMediaField, Property } from '../utils/validation';
import { ISuccessResponse, ISuccessResponseList, Order } from './types';
import {
  CampaignParams,
  ComponentType,
  ICampaign,
  ICampaignCreate,
  ICampaignEdit,
  ICampaignListResponse,
  ICampaignResponse,
  IComponent,
  UpsertCampaign,
} from './types/campaign';

const PER_PAGE = 10;

const getInitialContent = (properties?: IComponent['schema']['properties']) => {
  if (!properties) {
    return {};
  }

  return Object.fromEntries(
    Object.entries<Property>(properties).map(([key, value]) => {
      if (value.enum) {
        return [key, value.enum[0]];
      }
      if (isMediaField(value)) {
        return [key, [{}]];
      }
      switch (value.type) {
        case 'string':
          return [key, ''];
        case 'integer':
          return [key, 1];
        case 'array':
          return [key, []];
        default:
          return [key, null];
      }
    })
  );
};

const normalizeCamponents = (components?: ComponentType[]) => {
  if (!components) {
    return [];
  }

  return components.map((component) => {
    const { type, content } = component;

    if (type === 'label' || type === 'showcase') {
      return component;
    }

    return {
      ...component,
      content: {
        ...content,
        items: content.items.map(({ mediaId, url, name, analyticsId, countdown }) => ({
          analyticsId,
          url,
          name,
          media: mediaId,
          countdown,
        })),
      },
    };
  });
};

const parseCampaign = (campaign: ICampaign): ICampaign => {
  if (!campaign?.components) {
    return campaign;
  }

  return {
    ...campaign,
    components: campaign.components.map(({ type, id: _, ...content }) => ({
      type,
      content,
    })) as unknown as ComponentType[],
  };
};

const getParams = (
  searchParams: URLSearchParams,
  screenId?: string
): ParamsWithPage<CampaignParams> => ({
  screen: screenId,
  page: getPage(searchParams),
  filter: getParam('filter', searchParams),
  order: getParam<Order>('order', searchParams),
  sort: getParam<keyof ICampaign>('sort', searchParams),
});

const getCampaigns = async (params: CampaignParams = {}) => {
  const { perPage = PER_PAGE, order = Order.DESC, ...rest } = params;
  const { data } = await privateApi.get<ISuccessResponseList<ICampaignListResponse>>('/campaigns', {
    params: { perPage, order, ...rest },
  });

  return data.data;
};

const getCampaign = async (id: string) => {
  const { data } = await privateApi.get<ISuccessResponse<ICampaignResponse>>(`/campaigns/${id}`);
  return parseCampaign(data.data.campaign);
};

const updateCampaign = async (campaign: ICampaignEdit) => {
  const { id, ...payload } = campaign;

  const updatedComponents = payload.components?.map((component) => {
    if (component.type === 'grid') {
      return {
        ...component,
        content: {
          ...component.content,
          columns: '2',
        },
      };
    }

    return component;
  });

  const updatedCampaing = {
    ...payload,
    components: updatedComponents,
  } as UpsertCampaign;

  const { data } = await privateApi.patch<ISuccessResponse<ICampaignResponse>>(`/campaigns/${id}`, {
    ...updatedCampaing,
    ...(campaign.components?.length && {
      components: normalizeCamponents(updatedCampaing.components),
    }),
  });

  return parseCampaign(data.data.campaign);
};

const createCampaign = async (campaign: ICampaignCreate) => {
  const updatedComponents = campaign.components?.map((component) => {
    if (component.type === 'grid') {
      return {
        ...component,
        content: {
          ...component.content,
          columns: '2',
        },
      };
    }

    return component;
  });

  const updatedCampaing = {
    ...campaign,
    components: updatedComponents,
  } as UpsertCampaign;

  const { data } = await privateApi.post<ISuccessResponse<ICampaignResponse>>('/campaigns', {
    ...campaign,
    components: normalizeCamponents(updatedCampaing.components),
  });

  return parseCampaign(data.data.campaign);
};

const deleteCampaign = (id: string) => privateApi.delete(`campaigns/${id}`);
const moveCampaign = (id: string, screenId: string) => updateCampaign({ id, screen: screenId });
const publishCampaign = async (id: string) => {
  const response = await privateApi.put(`campaigns/${id}/publish`);

  return response.data;
};

const unpublishCampaign = async (id: string) =>
  (await privateApi.put<ISuccessResponse<ICampaignResponse>>(`campaigns/${id}/unpublish`)).data.data
    .campaign;

const isCreatedCampaign = (campaign: UpsertCampaign): campaign is ICampaignEdit =>
  (campaign as ICampaignEdit).id !== undefined;

const upsertCampaign = (campaign: UpsertCampaign) =>
  isCreatedCampaign(campaign) ? updateCampaign(campaign) : createCampaign(campaign);

const getComponents = async () =>
  (await privateApi.get<ISuccessResponse<{ components: IComponent[] }>>('/components')).data.data
    .components;

export const createCampaignEndpoints = (set: Setter, get: Getter) => {
  const campaignsPlaceholder = {
    pagination: { current: 0, total: 0, perPage: PER_PAGE },
    campaigns: [],
  };

  const createEndpoint = createService({
    set,
    getSlice: ({ campaigns }) => campaigns,
    prefix: 'campaigns',
  });

  const override = (campaign: ICampaign) => {
    const index = findById(campaign, get().campaigns.fetchCampaigns.data.campaigns);

    if (index === -1) {
      const searchParams = createSearchParams(history.location.search);
      get().api.fetchCampaigns.actions.refetch(getParams(searchParams));
    } else {
      set((state) => {
        state.campaigns.fetchCampaigns.data.campaigns[index] = campaign;
      });
    }
  };

  return {
    fetchCampaigns: createEndpoint(
      'fetchCampaigns',
      async ({ page = 1, ...params }: ParamsWithPage<CampaignParams>) => {
        const current = (page - 1) * get().campaigns.fetchCampaigns.data.pagination.perPage;

        if (!get().screens.fetchScreens.hasFetched) {
          get().api.fetchScreens();
        }

        return getCampaigns({ current, ...params });
      },
      { placeholder: campaignsPlaceholder }
    ),
    saveCampaign: createEndpoint(
      'saveCampaign',
      async (campaign: UpsertCampaign) => {
        const newCampaign = await upsertCampaign(campaign);

        override(newCampaign);
        responseToast({ title: 'Campanha salva com sucesso!', status: 'success' });
        history.push(`/telas/${newCampaign.screen.id}/campanhas`);

        return newCampaign;
      },
      { placeholder: null }
    ),
    redirectBackup: createEndpoint(
      'redirectBackup',
      async (campaign: UpsertCampaign) => {
        const newCampaign = await upsertCampaign(campaign);

        override(newCampaign);
        responseToast({ title: 'Campanha salva com sucesso!', status: 'success' });
        history.push(`/telas`);

        return newCampaign;
      },
      { placeholder: null }
    ),
    deleteCampaign: createEndpoint(
      'deleteCampaign',
      async (id: string, screenId?: string) => {
        await deleteCampaign(id);
        responseToast({ title: 'Campanha deletada com sucesso', status: 'success' });

        handlePaginationOnDeletion(
          get().campaigns.fetchCampaigns.data.pagination,
          `/telas/${screenId}/campanhas`,
          (params) => get().api.fetchCampaigns.actions.refetch(getParams(params, screenId))
        );
      },
      { placeholder: null }
    ),
    fetchCampaign: createEndpoint('fetchCampaign', getCampaign, { placeholder: null }),
    fetchComponents: createEndpoint('fetchComponents', getComponents, { placeholder: [] }),
    publishCampaign: createEndpoint(
      'publishCampaign',
      async (campaign: UpsertCampaign) => {
        const newCampaign = await upsertCampaign(campaign);
        await publishCampaign(newCampaign.id);

        override(newCampaign);
        responseToast({ title: 'Campanha publicada com sucesso!', status: 'success' });
        history.push(`/telas/${newCampaign.screen.id}/campanhas`);
      },
      { placeholder: null }
    ),
    unpublishCampaign: createEndpoint(
      'unpublishCampaign',
      async (campaignId: string) => {
        const newCampaign = await unpublishCampaign(campaignId);

        override(newCampaign);
        responseToast({ title: 'Campanha despublicada com sucesso!', status: 'success' });
        history.push(`/telas/${newCampaign.screen.id}/campanhas`);
      },
      { placeholder: null }
    ),
    moveCampaign: createEndpoint(
      'moveCampaign',
      async (id: string, screenId: string, newScreenId: string) => {
        await moveCampaign(id, newScreenId);
        responseToast({ title: 'Campanha movida com sucesso', status: 'success' });

        handlePaginationOnDeletion(
          get().campaigns.fetchCampaigns.data.pagination,
          `/telas/${screenId}/campanhas`,
          (params) => get().api.fetchCampaigns.actions.refetch(getParams(params, screenId))
        );
      },
      { placeholder: null, shouldThrowError: true }
    ),
    initializeCampaignComponents: createEndpoint(
      'initializeCampaignComponents',
      async (campaignId?: string, componentsFromLocation?: ComponentType[]) => {
        const { api } = get();

        const components = await api.fetchComponents();

        if (componentsFromLocation) {
          api.dispatchInitialComponents(componentsFromLocation);

          if (campaignId && get().campaigns.fetchCampaign.data?.id !== campaignId) {
            await api.fetchCampaign(campaignId);
          }
        } else if (campaignId) {
          const campaign = await api.fetchCampaign(campaignId);
          api.clearCampaignUpsert();

          if (campaign?.components && campaign.components.length > 0) {
            api.dispatchInitialComponents(campaign.components);
          } else {
            const { type, schema } = components[0];
            api.addComponent({ type, content: getInitialContent(schema.properties) });
          }
        } else {
          api.clearFetchCampaign();
          api.clearCampaignUpsert();
        }

        api.changePosition(0, true);
      },
      { placeholder: null }
    ),
  };
};

export default {
  getCampaigns,
  getCampaign,
  createCampaign,
  updateCampaign,
  upsertCampaign,
  deleteCampaign,
  publishCampaign,
  unpublishCampaign,
  getComponents,
  getParams,
  getInitialContent,
  perPage: PER_PAGE,
};
