import { Box, Button, Flex, Progress, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Icon from '../../../../../shared/components/Icon';
import toast from '../../../../../shared/components/Toast';
import useStore from '../../../../../shared/store';

const TOAST_ID = 'BATCH_UPLOAD_TOAST';

function BatchUploadToast() {
  const { isLoading } = useStore((state) => state.media.batchUpload);
  const { total, uploadCount, failedUploads } = useStore((state) => state.media.uploadState);
  const setEditMedia = useStore((state) => state.api.setEditMedia);

  const isValid = failedUploads.length === 0;
  useEffect(() => {
    if (!isLoading && isValid) {
      toast.close(TOAST_ID);
    }
  }, [isLoading, isValid]);

  const handleRetry = () => {
    setEditMedia('', failedUploads);
    toast.close(TOAST_ID);
  };

  const failView = (
    <Flex>
      <Icon icon="icon-circle-error" color="red" fontSize="2xl" marginRight="3px" />
      <Text>
        Não foi possível salvar alguns arquivos,{' '}
        <Button
          variant="link"
          onClick={handleRetry}
          fontWeight="semibold"
          color="inherit"
          textDecoration="underline"
        >
          Clique aqui para tentar novamente
        </Button>
      </Text>
    </Flex>
  );

  const progressView = (
    <>
      <Text>Salvando arquivos na biblioteca de mídia...</Text>
      <Progress
        marginTop="6px"
        size="sm"
        hasStripe
        isAnimated={isValid}
        value={(100 * uploadCount) / total}
        colorScheme="green"
      />
    </>
  );

  return (
    <Box
      backgroundColor="white"
      color="gray.90"
      fontWeight="semibold"
      padding="12px"
      borderRadius="6px"
      width="400px"
      boxShadow="lg"
    >
      {!isValid && !isLoading ? failView : progressView}
    </Box>
  );
}

BatchUploadToast.openToast = () => {
  if (!toast.isActive(TOAST_ID)) {
    toast({
      duration: null,
      position: 'bottom-right',
      id: TOAST_ID,
      containerStyle: { marginBottom: '80px' },
      render: () => <BatchUploadToast />,
    });
  }
};

export default BatchUploadToast;
