import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import React, { PropsWithChildren } from 'react';

type Props = {
  onChangePosition: (source: number, destination: number) => any;
};

function DragDropBaseProvider({ children, onChangePosition }: PropsWithChildren<Props>) {
  const onDragStart = () => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    onChangePosition(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {children}
    </DragDropContext>
  );
}

export default DragDropBaseProvider;
