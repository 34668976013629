import { Icon as ChakraIcon, IconProps, forwardRef } from '@chakra-ui/react';
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { IconType } from './Icon.types';

type BaseProps = {
  icon: IconType;
  theme?: Theme | undefined;
  as?: React.ElementType<any> | undefined;
};

const StyledIcon = styled('i')`
  ::before {
    line-height: 0;
  }
`;

const BaseIcon = forwardRef(
  ({ className, icon, ...rest }: React.HTMLProps<HTMLElement> & BaseProps, ref) => {
    const classNames = `${icon}${className ? ` ${className}` : ''}`;

    return <StyledIcon className={classNames} ref={ref} {...rest} />;
  }
);

const Icon = forwardRef(({ icon, ...rest }: IconProps & { icon: IconType }, ref) => (
  <ChakraIcon ref={ref} as={BaseIcon} icon={icon} {...rest} />
));

Icon.displayName = 'MarisaIcon';

export default Icon;
