import React, { useEffect } from 'react';
import {
  Location,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import Login from './pages/Login';
import UserList from './pages/UserList';
import CampaignList from './pages/CampaignList';
import Layout from './pages/Layout';
import useStore from './shared/store';
import AuthService from './shared/services/AuthService';
import toast from './shared/components/Toast';
import MediaLibrary from './pages/MediaLibrary';
import Gallery from './pages/Gallery';
import Campaign from './pages/Campaign';
import CampaignComponents from './pages/CampaignComponents';
import ScreenList from './pages/ScreenList';

function PrivateRoute() {
  const user = useStore((state) => state.auth.login.data);

  if (!user) {
    return null;
  }

  return <Outlet />;
}

/**
 * Modal routes to navigate over another page
 *
 * NOTE: Every navigation action(e.g. link/navigate) need to persist the state by
 * adding the location object as the second argument or state prop of the Link component
 */
function ModalRoutes() {
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as { backgroundLocation: Location } | undefined;
  const isOpen = !!state?.backgroundLocation;

  const handleClose = () => {
    const { pathname, search, hash } = state!.backgroundLocation;
    navigate({ pathname, search, hash }, state?.backgroundLocation);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent minWidth="80%" minHeight="100%" overflow="auto" marginBottom="0">
        <ModalCloseButton size="lg" color="gray.40" />
        <ModalBody padding="24px 32px 0 32px">
          <Routes>
            <Route path="/midia">
              <Route index element={<MediaLibrary />} />
              <Route path="galeria" element={<Gallery />} />
              <Route path="galeria/:categoryId" element={<MediaLibrary />} />
            </Route>
            <Route path="*" element={null} />
          </Routes>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function RootRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useStore((state) => state.auth.login.data);
  const { isLoading } = useStore((state) => state.app.initializeApp);
  const { initializeApp, setUser, dispatchLayoutColor } = useStore((state) => state.api);

  useEffect(() => {
    const unsub = AuthService.createAuthInterceptor(() => {
      navigate('/login');
      setUser(null);
      toast({ status: 'warning', title: 'Sessão expirada, faça login novamente' });
    });

    return unsub;
  }, [navigate, setUser]);

  useEffect(() => {
    initializeApp();
  }, [initializeApp]);

  useEffect(() => {
    dispatchLayoutColor('gray.5');
  }, [location.pathname, dispatchLayoutColor]);

  const isMarketing = user?.role.name === 'Marketing';
  const state = location.state as { backgroundLocation: Location } | undefined;

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner size="lg" />
      </Center>
    );
  }

  // Updade

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="telas" />} />
            {!isMarketing && <Route path="usuarios" element={<UserList />} />}
            <Route path="midia">
              <Route index element={<MediaLibrary />} />
              <Route path="galeria" element={<Gallery />} />
              <Route path="galeria/:categoryId" element={<MediaLibrary />} />
            </Route>
            <Route path="telas">
              <Route index element={<ScreenList />} />
              <Route path=":screenId">
                <Route path="campanhas">
                  <Route index element={<CampaignList />} />
                  <Route path="novo">
                    <Route index element={<Campaign />} />
                    <Route path="componentes" element={<CampaignComponents />} />
                  </Route>
                  <Route path=":campaignId">
                    <Route index element={<Campaign />} />
                    <Route path="componentes" element={<CampaignComponents />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="/login" element={<Login />} />
      </Routes>
      <ModalRoutes />
    </>
  );
}

export default RootRoutes;
