import { ComponentType, Item } from '../services/types/campaign';
import { yupPortuguese } from './i18n';

export type Property = {
  type: 'string' | 'array' | 'object' | 'integer';
  enum?: string[];
  items?: Property;
  properties?: Property;
};

export type MediaItemProperty = Property & {
  type: 'object';
  properties: { media: string; url: string };
};

export type MediaFieldErrors = {
  mediaId?: string;
  name?: string;
  url?: string;
  analyticsId?: string;
};

export type MediaProperty = Property & { type: 'array'; items: MediaItemProperty };

export const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]+$/;
export const STRONG_PASSWORD_REGEX = /(?=.*\d+)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z])/;

export const validateUrl = (url: string) => {
  if (url === '#') {
    return true;
  }

  // eslint-disable-next-line prefer-regex-literals
  const regExp = new RegExp(
    '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
  );

  const result = regExp.test(url);

  return result;
};

export const isMediaField = (property: Property): property is MediaProperty =>
  !!property.items &&
  (property.items.properties as MediaProperty['items']['properties']).media !== undefined;

export const validateMedia = (item: Partial<Item>) => {
  const errors: MediaFieldErrors = {};

  if (!item.mediaId) {
    errors.mediaId = yupPortuguese.mixed.required;
  }

  if (!item.url) {
    errors.url = yupPortuguese.mixed.required;
  } else if (item.url !== '#' && !validateUrl(item.url)) {
    errors.url = 'Digite um link válido (exemplo: "https://www.marisa.com.br")';
  }

  if (item.analyticsId === '') {
    errors.analyticsId = yupPortuguese.mixed.required;
  }

  return errors;
};

export const validate = (
  property: Property,
  value: any,
  type: ComponentType['type'],
  components: ComponentType[]
) => {
  const errors: any[] = [];

  if (property.enum) {
    if (type === 'showcase') {
      let sameShowcaseCount = 0;

      components.forEach((c) => {
        if (c.type === 'showcase' && c.content.position === value) {
          sameShowcaseCount += 1;
        }
      });

      if (sameShowcaseCount > 1) {
        errors.push('A vitrine já foi selecionada em outro showcase');
      }
    }

    return errors;
  }

  if (!value) {
    errors.push(yupPortuguese.mixed.required);
    return errors;
  }

  if (property.type === 'string' && value === '') {
    errors.push(yupPortuguese.mixed.required);
  }

  if (isMediaField(property)) {
    errors.push(
      ...(value as Item[]).reduce<MediaFieldErrors[]>((acc, item) => {
        const result = validateMedia(item);
        if (Object.keys(result).length > 0) {
          return [...acc, result];
        }

        return acc;
      }, [])
    );
  }

  return errors;
};

export const validateSchema = (
  schema: any,
  value: any,
  type: ComponentType['type'],
  components: ComponentType[]
) =>
  Object.fromEntries(
    Object.entries(schema.properties).reduce<[string, any[]][]>((acc, [key, property]) => {
      const result = validate(property as Property, value[key], type, components);

      if (result.length > 0) {
        return [...acc, [key, result]];
      }

      return acc;
    }, [])
  );
