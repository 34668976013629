import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Image,
  SimpleGrid,
  useEditableControls,
} from '@chakra-ui/react';
import React from 'react';
import { Controller, UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import ActionButton from '../../../../../shared/components/ActionButton';
import UploadFile from '../../../../../shared/components/Form/UploadFile';
import Icon from '../../../../../shared/components/Icon';
import toast from '../../../../../shared/components/Toast';
import { TextEllipsis } from '../../../../../shared/components/Typography';
import { getBase64, stripExtension } from '../../../../../shared/utils/imageUpload';
import { IForm } from '../CreateMediaModal.types';
import { BACKEND_ERROR_TYPE, getFileField } from '../shared';

type Props = {
  cursor: number;
  onChangeCursor: (position: number) => void;
  methods: UseFieldArrayReturn<IForm, 'files', 'id'>;
};

const openErrorToast = () =>
  toast({
    status: 'error',
    duration: 5000,
    title:
      'Os arquivos enviados não seguem os formatos permitidos (.JPG, .JPEG ou .PNG com até 5MB), por favor, tente novamente',
  });

const openMaxLimitInfoToast = () =>
  toast({
    status: 'warning',
    duration: 5000,
    title: 'Alguns arquivos foram descartados!',
    description: 'Não é possível selecionar mais que 50 arquivos, portanto alguns foram ignorados.',
  });

function EditableControls({ error, onDelete }: { error?: string; onDelete: () => void }) {
  const { getEditButtonProps } = useEditableControls();

  return (
    <>
      {error && (
        <ActionButton
          marginLeft="auto"
          aria-label={error}
          title={error}
          icon={<Icon icon="icon-circle-information" color="red.100" fontSize="xl" />}
          {...getEditButtonProps()}
        />
      )}
      <ActionButton
        marginLeft="auto"
        aria-label="editar nome"
        title="Editar nome"
        icon={<Icon icon="icon-edit-alt" fontSize="xl" />}
        {...getEditButtonProps()}
      />
      <ActionButton
        aria-label="remover arquivo"
        title="Remover arquivo"
        icon={<Icon icon="icon-delete" fontSize="xl" />}
        onClick={onDelete}
      />
    </>
  );
}

function MediaList({ cursor, onChangeCursor, methods }: Props) {
  const { control, getValues, trigger, setValue, clearErrors, formState } = useFormContext<IForm>();
  const { fields, replace, remove } = methods;
  const { errors } = formState;

  const handleBorderColor = (position: number) => {
    if (position === cursor) {
      return 'green.100';
    }
    if (errors.files && errors.files[position]) {
      return 'red.100';
    }

    return 'transparent';
  };

  const handleUpload = async (files?: FileList | null) => {
    const FILES_MAX_LENGTH = 50;

    if (!files || files.length === 0) {
      return;
    }

    onChangeCursor(0);

    const length = files.length > FILES_MAX_LENGTH ? FILES_MAX_LENGTH : files.length;
    const filesArr = Array.from({ length }, async (_, index) => {
      let name = getValues(getFileField('name', index));

      const isFirstItem = index === 0;
      const file = files.item(index)!;
      const prevFile = getValues(getFileField('source.file', index));
      const prevFileName = prevFile && stripExtension(prevFile.name);
      if (!name || prevFileName === name) {
        name = stripExtension(file.name) || '';
      }

      if (isFirstItem) {
        setValue(getFileField('name', 0), name);
      }

      return { name, source: { file, url: await getBase64(file) } };
    });

    replace(await Promise.all(filesArr));
    if (!(await trigger('files'))) {
      openErrorToast();
    }
    if (files.length > FILES_MAX_LENGTH) {
      openMaxLimitInfoToast();
    }
  };

  const uploadFileView = (
    <UploadFile
      accept=".jpg, .jpeg, .png"
      showImage={!!fields[0]?.source?.url}
      placeholder="Clique para selecionar uma mídia ou arraste e solte o arquivo aqui"
      onChange={handleUpload}
    />
  );

  const largeMediaListView = (
    <Box maxHeight="55vh" overflow="auto">
      {fields.map((field, index) => (
        <Controller
          key={field.id}
          name={getFileField('name', index)}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Editable
              alignItems="center"
              marginY="12px"
              display="flex"
              value={value}
              onChange={(e) => {
                onChange(e);

                if (errors.files && errors.files[index]?.type === BACKEND_ERROR_TYPE) {
                  clearErrors(`files.${index}`);
                  trigger(`files.${index}.name`);
                }
              }}
              onCancel={onChange}
              onSubmit={onChange}
            >
              <Image
                marginRight="12px"
                width="40px"
                height="40px"
                objectFit="cover"
                src={field.source?.url || undefined}
              />
              <EditablePreview as={TextEllipsis} width="70%" verticalAlign="middle" />
              <EditableInput width="70%" />
              <EditableControls
                error={
                  errors.files &&
                  (errors.files[index]?.message || errors.files[index]?.name?.message)
                }
                onDelete={() => remove(index)}
              />
            </Editable>
          )}
        />
      ))}
    </Box>
  );

  const regularMediaListView = (
    <SimpleGrid columns={4} spacing="24px" marginY="32px">
      {fields.map((field, index) => (
        <Box
          key={field.id}
          as="button"
          type="button"
          onClick={() => onChangeCursor(index)}
          width="150px"
          height="150px"
          border="4px solid"
          borderColor={handleBorderColor(index)}
        >
          <Image
            width="100%"
            height="100%"
            marginX="auto"
            objectFit="contain"
            src={field.source?.url || undefined}
          />
        </Box>
      ))}
    </SimpleGrid>
  );

  const mediaListView = fields.length > 8 ? largeMediaListView : regularMediaListView;

  return (
    <Controller
      name="files"
      control={control}
      render={({ field: { value } }) => (value.length > 1 ? mediaListView : uploadFileView)}
    />
  );
}

export default MediaList;
