import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';
import theme from '../../../theme';

const values = createStandaloneToast({
  theme,
  defaultOptions: { position: 'top', duration: 2000 },
});
const { toast } = values;

const RESPONSE_TOAST = 'HTTP_RESPONSE_TOAST';
const EDIT_MEDIA_TOAST_ID = 'EDIT_MEDIA_TOAST_ID';

const ERROR_TITLE = 'Ops, ocorreu um erro inesperado!';
const ERROR_DESCRIPTION = 'Caso o erro persista, contate um administrador.';

export const editMediaToast = () => {
  if (!toast.isActive(EDIT_MEDIA_TOAST_ID)) {
    toast({
      status: 'error',
      id: EDIT_MEDIA_TOAST_ID,
      title: 'Não é possível salvar um arquivo no momento',
      description: 'Não é possível realizar essa ação enquanto outras mídias estão sendo salvas',
    });
  }
};

export const responseToast = (options: UseToastOptions) => {
  if (!toast.isActive(RESPONSE_TOAST)) {
    toast({ ...options, id: RESPONSE_TOAST });
  }
};

export const errorToast = (message: string, position?: UseToastOptions['position']) =>
  toast({ status: 'error', title: ERROR_TITLE, description: message, position });

export const unknownErrorToast = (position?: UseToastOptions['position']) =>
  toast({ position, status: 'error', title: ERROR_TITLE, description: ERROR_DESCRIPTION });

export const responseErrorToast = (
  message: string,
  position?: UseToastOptions['position'],
  duration?: number
) =>
  responseToast({ status: 'error', title: ERROR_TITLE, description: message, position, duration });

export const unknownResponseErrorToast = (position?: UseToastOptions['position']) =>
  responseToast({ position, status: 'error', title: ERROR_TITLE, description: ERROR_DESCRIPTION });

export const { ToastContainer } = values;
export default toast;
