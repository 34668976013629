import { CreateStyled } from '@emotion/styled';

/**
 * Random helpers that don't fit any other
 */

export const noop = () => {};

export const splitIn = (arr: any[], steps: number) => {
  const batches = [];

  for (let index = 0; index < arr.length; index += steps) {
    batches.push(arr.slice(index, index + steps));
  }

  return batches;
};

export const capitalizeFirst = (text: string) =>
  `${text.slice(0, 1).toUpperCase()}${text.slice(1).toLowerCase()}`;

export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};
