import {
  Button,
  forwardRef,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Select as ChakraSelect,
  SelectProps,
  useControllableState,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';
import Icon from '../Icon';

type Props = {
  isTouched?: boolean;
};

const TextInput = styled(Input)`
  &:focus ~ div {
    color: var(--chakra-colors-black);
  }
`;

export const PasswordInput = forwardRef((props: InputProps, ref) => {
  const { size } = props;
  const [show, setShow] = useState(false);
  const handleClick = () => setShow((state) => !state);

  return (
    <InputGroup size={size}>
      <TextInput {...props} ref={ref} type={show ? 'text' : 'password'} />
      <InputRightElement color={props.isInvalid ? 'black' : 'gray.60'}>
        <Button
          display="inline-flex"
          alignItems="center"
          minWidth="min-content"
          variant="unstyled"
          fontSize="xl"
          aria-label={show ? 'Revele a senha' : 'Esconda a senha'}
          tabIndex={-1}
          onClick={handleClick}
        >
          {show ? <Icon icon="icon-eye" /> : <Icon icon="icon-eye-off" />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});

export const Select = forwardRef(
  ({ children, onChange, onBlur, isTouched: isTouchedProp, ...rest }: SelectProps & Props, ref) => {
    const [internalIsTouched, internalSetTouched] = useState(false);
    const [isTouched, setTouched] = useControllableState({
      value: isTouchedProp || internalIsTouched,
      onChange: internalSetTouched,
    });

    const [isOpen, setOpen] = useState(false);
    const hasChanged = useRef(false);

    const handleClick = () => {
      if (hasChanged.current || !isOpen) {
        setOpen((state) => !state);
        hasChanged.current = false;
      }
    };

    const handleBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
      if (onBlur) {
        onBlur(e);
      }

      setOpen(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (onChange) {
        onChange(e);
      }

      hasChanged.current = true;
      setTouched(true);
    };

    return (
      <ChakraSelect
        ref={ref}
        {...rest}
        icon={
          <Icon
            icon="icon-caret-down"
            transform={`rotate(${isOpen ? '180' : '360'}deg)`}
            transition="transform 300ms ease"
            fontSize="lg"
            color="primary.100 !important"
          />
        }
        color={isTouched ? 'initial' : 'gray.50'}
        onChange={handleChange}
        onClick={handleClick}
        onBlur={handleBlur}
      >
        {children}
      </ChakraSelect>
    );
  }
);

Select.displayName = 'AppSelect';
