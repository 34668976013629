import { privateApi } from '../../api';
import { responseToast } from '../components/Toast';
import createApi from '../store/serverState';
import { Getter, ParamsWithPage, Setter } from '../store/store.types';
import { findById, getPage, getParam, handlePaginationOnDeletion } from '../utils/requestHelpers';
import {
  IListRolesResponse,
  IListUserResponse,
  INewPassword,
  IResetPassword,
  IUserResponse,
  UpdatePassword,
  UpsertUser,
  UserCreation,
  UserEdit,
  UserListParams,
} from './types/user';
import AuthService from './AuthService';
import { DefaultSort, ISuccessResponse, ISuccessResponseList, Order } from './types';

const PER_PAGE = 10;

const getParams = (searchParams: URLSearchParams): ParamsWithPage<UserListParams> => ({
  page: getPage(searchParams),
  order: getParam<Order>('order', searchParams),
  sort: getParam<DefaultSort>('sort', searchParams),
});

const getUsers = async (params: UserListParams) => {
  const { perPage = PER_PAGE, ...rest } = params;
  const { data } = await privateApi.get<ISuccessResponseList<IListUserResponse>>('/users', {
    params: { perPage, ...rest },
  });

  return data.data;
};

const createUser = async (user: UserCreation) =>
  (await privateApi.post<ISuccessResponse<{ user: IUserResponse }>>('/users', user)).data.data.user;

const editUser = async (user: UserEdit) => {
  const { id, ...payload } = user;
  const { data } = await privateApi.patch<ISuccessResponse<{ user: IUserResponse }>>(
    `/users/${id}`,
    payload
  );

  return data.data.user;
};

const deleteUser = async (userId: string) => privateApi.delete(`/users/${userId}`);
const newPassword = (data: INewPassword) => privateApi.put('/passwords', data);
const resetPassword = (data: IResetPassword) => privateApi.put('/passwords/reset', data);
const getRoles = async () =>
  (await privateApi.get<ISuccessResponseList<IListRolesResponse>>('/roles')).data.data.roles;

const isCreatedUser = (user: UpsertUser): user is UserEdit => !!user.id;
const isResetPassword = (data: UpdatePassword): data is IResetPassword => !!data.userId;

const upsertUser = (user: UpsertUser) => (isCreatedUser(user) ? editUser(user) : createUser(user));
const updatePasswordUser = (data: UpdatePassword) =>
  isResetPassword(data) ? resetPassword(data) : newPassword(data);

export const createUsersEndpoints = (set: Setter, get: Getter) => {
  const usersPlaceholder = {
    pagination: { current: 0, total: 0, perPage: PER_PAGE },
    users: [],
  };

  const createEndpoint = createApi({ set, getSlice: ({ users }) => users, prefix: 'users' });

  return {
    fetchUsers: createEndpoint(
      'fetchUsers',
      async ({ page = 1, ...rest }: ParamsWithPage<UserListParams>) => {
        const current = (page - 1) * get().users.fetchUsers.data.pagination.perPage;

        if (get().users.roles.length === 0) {
          get().api.dispatchRoles(await getRoles());
        }

        return getUsers({ current, ...rest });
      },
      { placeholder: usersPlaceholder }
    ),
    saveUser: createEndpoint(
      'saveUser',
      async (user: UpsertUser) => {
        const authUser = get().auth.login.data;
        const { users } = get().users.fetchUsers.data;
        const newUser = await upsertUser(user);

        const isAuthUser = authUser!.id === newUser.id;
        const hasAuthenticatedUserOnList = isAuthUser && users.find((u) => u.id === authUser!.id);
        if (isAuthUser) {
          const newAuthenticatedUser = { ...newUser, tokens: authUser!.tokens };
          AuthService.setAuth(newAuthenticatedUser);
          get().api.setUser(newAuthenticatedUser);
        }

        if (hasAuthenticatedUserOnList || !isAuthUser) {
          set(({ users: { fetchUsers } }) => {
            const { data } = fetchUsers;
            const index = findById(newUser, data.users);

            if (index === -1) {
              data.pagination.total += 1;

              if (data.users.length < data.pagination.perPage) {
                data.users.push(newUser);
              }
            } else {
              data.users[index] = newUser;
            }
          });
        }

        return newUser;
      },
      { placeholder: null, shouldThrowError: true }
    ),
    deleteUser: createEndpoint(
      'deleteUser',
      async (id: string) => {
        await deleteUser(id);
        handlePaginationOnDeletion(get().users.fetchUsers.data.pagination, '/usuarios', (params) =>
          get().api.fetchUsers.actions.refetch(getParams(params))
        );

        responseToast({ title: 'Usuário deletado com sucesso', status: 'success' });
      },
      { placeholder: null }
    ),
    newPassword: createEndpoint(
      'newPassword',
      async (data: UpdatePassword) => {
        const response = await updatePasswordUser(data);
        responseToast({ title: 'Senha atualizada com sucesso', status: 'success' });

        return response.data;
      },
      { placeholder: null }
    ),
  };
};

export default {
  getUsers,
  createUser,
  editUser,
  upsertUser,
  getRoles,
  deleteUser,
  updatePasswordUser,
  getParams,
  perPage: PER_PAGE,
};
