import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { PasswordInput } from '../../shared/components/Form';
import { LogoWhite, LogoWhiteSmall } from '../../shared/components/Logo';
import useStore from '../../shared/store';
import { EMAIL_REGEX } from '../../shared/utils/validation';

interface IForm {
  email: string;
  password: string;
}

function Login() {
  const { isLoading } = useStore((state) => state.auth.login);
  const { login } = useStore((state) => state.api);

  const { register, handleSubmit, formState } = useForm<IForm>({
    mode: 'onChange',
    defaultValues: { email: '', password: '' },
  });

  const { errors, isValid } = formState;

  return (
    <Flex height="100%">
      <Center flex="1" backgroundColor="primary.100">
        <LogoWhite />
      </Center>
      <Center flex="1" flexDirection="column">
        <Center
          marginBottom="20px"
          backgroundColor="primary.100"
          width="90px"
          height="90px"
          borderRadius="50%"
        >
          <LogoWhiteSmall />
        </Center>
        <Text
          textAlign="center"
          fontSize="26px"
          width="250px"
          lineHeight="1.2"
          marginBottom="20px"
          fontWeight="semibold"
          color="gray.90"
        >
          Olá! Você está no{' '}
          <Text as="span" color="primary.100">
            Marisa Hub
          </Text>
        </Text>
        <Box as="form" onSubmit={handleSubmit(login)}>
          <FormControl isInvalid={!!errors.email} marginBottom="20px" width="370px">
            <Input
              placeholder="Digite aqui seu e-mail"
              {...register('email', {
                required: 'Este campo é obrigatório',
                pattern: { value: EMAIL_REGEX, message: 'Formato de e-mail inválido' },
              })}
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password} marginBottom="20px" width="370px">
            <PasswordInput
              isInvalid={!!errors.password}
              placeholder="Digite aqui sua senha"
              {...register('password', { required: 'Este campo é obrigatório' })}
            />
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>
          <Button
            colorScheme="primary"
            width="100%"
            type="submit"
            isLoading={isLoading}
            loadingText="Carregando"
            disabled={!isValid || isLoading}
          >
            Entrar
          </Button>
        </Box>
      </Center>
    </Flex>
  );
}

export default Login;
