import { createCampaignEndpoints } from '../../services/CampaignService';
import { createSliceState } from '../serverState';
import { SliceCreator, CampaignsSlice } from '../store.types';

export const upsertInitialState: CampaignsSlice['upsert'] = {
  submitCount: 0,
  hasSubmitted: false,
  cursor: { position: -1, isDirty: false },
  components: [],
  errors: [],
};

const createCampaignsSlice: SliceCreator<CampaignsSlice> = (set, get) => ({
  ...createSliceState(() => createCampaignEndpoints(set, get)),
  selectedMedia: null,
  deletion: { currentId: null },
  upsert: upsertInitialState,
});

export default createCampaignsSlice;
