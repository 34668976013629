import { Badge, Box, Button, Flex, Image } from '@chakra-ui/react';
import React, { MouseEventHandler } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '../../../shared/components/Icon';
import { TextEllipsis } from '../../../shared/components/Typography';
import { IMedia } from '../../../shared/services/types/media';
import useStore from '../../../shared/store';

type Props = {
  item: IMedia;
};

const renderBadge = (text: string) => (
  <Badge
    backgroundColor="gray.10"
    borderRadius="4px"
    padding="4px 8px"
    color="gray.90"
    _notLast={{ marginRight: '5px' }}
  >
    {text}
  </Badge>
);

function MediaItem({ item }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { setEditMedia, dispatchSelectedMedia } = useStore((state) => state.api);

  const isMediaModal = !!location.state?.backgroundLocation;

  const handleSelect = () => {
    const { pathname, search, hash } = location.state!.backgroundLocation;

    dispatchSelectedMedia(item);
    navigate({ pathname, search, hash }, location.state?.backgroundLocation);
  };

  const handleEdit: MouseEventHandler = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setEditMedia(item.id);
  };

  return (
    <Box
      borderRadius="8px"
      backgroundColor="white"
      padding="12px"
      border="1px solid"
      borderColor="gray.20"
      fontWeight="bold"
      cursor={isMediaModal ? 'pointer' : undefined}
      onClick={isMediaModal ? handleSelect : undefined}
    >
      <Box position="relative">
        <Image
          src={item.download}
          objectFit="cover"
          marginX="auto"
          height="210px"
          width="100%"
          borderRadius="6px"
        />
        <Button
          colorScheme="black"
          backgroundColor="black"
          color="white"
          height="28px"
          padding="0 10px"
          borderRadius="5px"
          position="absolute"
          right="5px"
          top="5px"
          fontSize="smaller"
          _active={{ transform: 'scale(0.95)' }}
          onClick={handleEdit}
        >
          <Icon icon="icon-edit-alt" /> Editar
        </Button>
      </Box>
      <Box margin="24px 0 18px" textAlign="start">
        <TextEllipsis maxWidth="100%" marginBottom="12px">
          {item.name}
        </TextEllipsis>
        <Flex color="gray.60" fontSize="sm">
          {renderBadge(item.extension.toUpperCase())}
          {renderBadge(`${item.width}x${item.height}`)}
          {renderBadge(`${Math.floor(item.length / 10 ** 3)}KB`)}
        </Flex>
      </Box>
    </Box>
  );
}

export default MediaItem;
