import { Image, ImageProps, Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import logoWhiteSource from '../../../assets/images/logo-white.svg';
import logoWhiteSmallSource from '../../../assets/images/logo-white-small.svg';
import logoSmallSource from '../../../assets/images/logo-small.svg';
import logoSource from '../../../assets/images/logo.svg';

export function LogoWhiteSmall(props: ImageProps) {
  return <Image src={logoWhiteSmallSource} {...props} />;
}

export function LogoSmall(props: ImageProps) {
  return <Image src={logoSmallSource} {...props} />;
}

export function LogoWhite(props: ImageProps) {
  return <Image src={logoWhiteSource} {...props} />;
}

function Logo(props: ImageProps & { containerProps?: FlexProps }) {
  const { containerProps = {}, ...rest } = props;
  const { width = '200px' } = rest;

  return (
    <Flex {...containerProps}>
      <Image {...rest} src={logoSource} alt="Logo Marisa" />
      <Flex
        color="primary.100"
        fontSize={`calc(${width} / 13)`}
        fontWeight="600"
        fontFamily="heading"
        marginLeft="2px"
        alignItems="flex-end"
        lineHeight="calc(100% - 2px)"
      >
        Hub
      </Flex>
    </Flex>
  );
}

export default Logo;
