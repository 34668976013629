import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import createAppSlice from './slices/app';
import createAuthSlice from './slices/auth';
import createUsersSlice from './slices/users';
import createMediaSlice from './slices/media';
import createApiSlice from './slices/api';
import createCampaignsSlice from './slices/campaigns';
import createCategoriesSlice from './slices/categories';
import createScreensSlice from './slices/screens';
import { RootStore } from './store.types';

/**
 * Zustand store with flux pattern.
 */
const useStore = create<RootStore>()(
  immer(
    devtools((...args) => {
      const [set] = args;

      const initialState = {
        app: createAppSlice(...args),
        auth: createAuthSlice(...args),
        users: createUsersSlice(...args),
        media: createMediaSlice(...args),
        categories: createCategoriesSlice(...args),
        campaigns: createCampaignsSlice(...args),
        screens: createScreensSlice(...args),
      };

      return {
        ...initialState,
        api: createApiSlice(...args),
        clear: () => set(initialState, false, 'clear'),
      };
    })
  )
);

export default useStore;
