import { createMediaEndpoints } from '../../services/MediaService';
import { createSliceState } from '../serverState';
import { SliceCreator, MediaSlice } from '../store.types';

const createMediaSlice: SliceCreator<MediaSlice> = (set, get) => ({
  ...createSliceState(() => createMediaEndpoints(set, get)),
  isOpen: false,
  initialImages: [],
  initialCategoryId: null,
  deletion: { currentId: null },
  upsert: { currentId: null, initialFiles: [] },
  uploadState: { total: 0, uploadCount: 0, failedUploads: [] },
});

export default createMediaSlice;
