import { privateApi } from '../../api';
import history from '../../history';
import { responseToast } from '../components/Toast';
import createService from '../store/serverState';
import { Getter, Setter } from '../store/store.types';
import { handleGenericErrors } from '../utils/requestHelpers';
import { ISuccessResponse, ISuccessResponseList } from './types';
import {
  CreateScreen,
  IScreenListResponse,
  IScreenResponse,
  UpdateScreen,
  UpsertScreen,
} from './types/screen';

const getScreens = async () => {
  const { data } = await privateApi.get<ISuccessResponseList<IScreenListResponse>>('/screens');

  return data.data;
};

const createScreen = async (payload: CreateScreen) => {
  const { data } = await privateApi.post<ISuccessResponse<IScreenResponse>>('/screens', payload);
  return data?.data.screen;
};

const updateScreen = async ({ id, ...payload }: UpdateScreen) =>
  (await privateApi.patch<ISuccessResponse<IScreenResponse>>(`/screens/${id}`, payload)).data.data
    .screen;

const deleteScreen = (id: string) => privateApi.delete(`/screens/${id}`);

const orderScreens = (screens: string[]) => privateApi.post('/screens/order', { screens });

const isUpdateScreen = (screen: UpsertScreen): screen is UpdateScreen =>
  (screen as UpdateScreen).id !== undefined;

const upsertScreen = (screen: UpsertScreen) =>
  isUpdateScreen(screen) ? updateScreen(screen) : createScreen(screen);

export const createScreensEndpoints = (set: Setter, get: Getter) => {
  const screensPlaceholder = {
    pagination: { current: 0, total: 0, perPage: 0 },
    screens: [],
  };

  const createEndpoint = createService({
    set,
    getSlice: ({ screens }) => screens,
    prefix: 'users',
  });

  const getFetchScreens = () => get().screens.fetchScreens;

  return {
    fetchScreens: createEndpoint('fetchScreens', getScreens, { placeholder: screensPlaceholder }),

    saveScreen: createEndpoint(
      'saveScreen',
      async (payload: UpsertScreen) => {
        const newScreen = await upsertScreen(payload);

        set(({ screens: { fetchScreens } }) => {
          const { data } = fetchScreens;

          data.screens.push(newScreen);
        });

        responseToast({ title: 'Tela salva com sucesso!', status: 'success' });

        history.push('/telas');

        return newScreen;
      },
      { placeholder: null, shouldThrowError: true }
    ),

    deleteScreen: createEndpoint(
      'deleteScreen',
      async (id: string) => {
        await deleteScreen(id);

        responseToast({ title: 'Tela deletada com sucesso', status: 'success' });
      },
      { placeholder: null }
    ),
    orderScreens: createEndpoint(
      'orderScreens',
      async (oldPosition: number, newPosition: number) => {
        const prevScreensState = getFetchScreens().data.screens;

        set(({ screens: { fetchScreens } }) => {
          const [screen] = fetchScreens.data.screens.splice(oldPosition, 1);
          fetchScreens.data.screens.splice(newPosition, 0, screen);
        });

        try {
          const screenIds = getFetchScreens().data.screens.map(({ id }) => id);
          await orderScreens(screenIds.slice(1));
        } catch (error) {
          set(({ screens: { fetchScreens } }) => {
            fetchScreens.data.screens = prevScreensState;
          });

          throw error;
        }
      },
      { placeholder: null }
    ),
    enableScreen: Object.assign(
      async (id: string, enabled: boolean) => {
        let screen = null;
        try {
          set((state) => {
            state.screens.enableScreen.push({ id, fetchState: { isLoading: true } });
          });

          screen = await updateScreen({ id, enabled });
          await get().api.fetchScreens.actions.refetch();
        } catch (error) {
          handleGenericErrors(error);
        } finally {
          set((state) => {
            state.screens.enableScreen = state.screens.enableScreen.filter(
              (screenState) => screenState.id !== id
            );
          });
        }

        return screen;
      },
      { initialState: [] }
    ),
  };
};

export default {
  getScreens,
  createScreen,
  updateScreen,
  upsertScreen,
};
