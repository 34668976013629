/* eslint-disable no-nested-ternary */
import { Checkbox } from '@chakra-ui/checkbox';
import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/form-control';
import { QuestionIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Box, Flex, Grid, GridItem, HStack, Stack, Text } from '@chakra-ui/layout';
import { chakra, Tooltip, useRadio, useRadioGroup } from '@chakra-ui/react';
import { addMinutes, differenceInHours, isAfter } from 'date-fns';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DateTime from '../../../../shared/components/Form/DateTime';
import Icon from '../../../../shared/components/Icon';
import { Item } from '../../../../shared/services/types/campaign';
import { useCampaingCountdown } from '../../../../shared/store/useCampaignCountdown';

interface CustomRadioProps extends React.HTMLAttributes<HTMLElement> {
  color: string;
}

interface FormConfigCountdownProps {
  item?: Partial<Item>;
}

function CustomRadio(props: CustomRadioProps) {
  const { color, ...radioProps } = props;
  const { state, getInputProps, getCheckboxProps, htmlProps } = useRadio(radioProps);

  return (
    <chakra.label {...htmlProps} cursor="pointer">
      <input {...getInputProps({})} hidden />
      <Flex
        {...getCheckboxProps()}
        bg={color}
        w="46px"
        h="46px"
        p={1}
        borderRadius="md"
        align="center"
        justify="center"
        border={state.isChecked ? '2px solid #2DDF89' : '2px solid #E5E5E5'}
      >
        {state.isChecked ? (
          <Icon icon="icon-check" color={color === '#FFFFFF' ? '#2DDF89' : '#FFFF'} />
        ) : null}
      </Flex>
    </chakra.label>
  );
}

function CustomRadioGroup(props: any) {
  const { options, ...rest } = props;

  const { getRootProps, getRadioProps } = useRadioGroup({
    ...rest,
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {options?.map((color: string) => (
        <CustomRadio key={color} color={color} {...getRadioProps({ value: color })} />
      ))}
    </HStack>
  );
}

export function FormConfigCountdown({ item }: FormConfigCountdownProps) {
  const [titleCountdown, setTitleCountdown] = useState(30);
  const [loadingDate, setLoadingDate] = useState(false);
  const [endCampaignDate, setEndCampaignDate] = useState('');
  const colors = ['#EC008C', '#000000', '#FFFFFF'];

  const { register, control, getValues, watch, setValue } = useFormContext();
  const { currentDate } = useCampaingCountdown((state) => state);

  const { getRootProps } = useRadioGroup();

  const handleTitleCountdown = (event: ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    const countCharacter = 30 - inputText.length;

    setTitleCountdown(countCharacter);
  };

  const handleColorCharacteres = () => {
    if (titleCountdown <= 30 && titleCountdown >= 16) {
      return <Text color="#838181">{titleCountdown}</Text>;
    }
    if (titleCountdown <= 15 && titleCountdown >= 6) {
      return <Text color="#FF922D">{titleCountdown}</Text>;
    }
    if (titleCountdown <= 5 && titleCountdown >= 0) {
      return <Text color="#FF4B56">{titleCountdown}</Text>;
    }
  };

  const validationDates = () => {
    const startDateCampaing = new Date(currentDate);
    const endDateCampaing = new Date(watch('endDate'));

    const checkIfIsLessThanSixtyMinutes = endDateCampaing.getTime() - startDateCampaing.getTime();
    const checkIfHasMoreThanOneDay = differenceInHours(endDateCampaing, startDateCampaing);
    const checkIfIsAfterCampaingEnd = isAfter(endDateCampaing, new Date(endCampaignDate));

    if (checkIfIsLessThanSixtyMinutes < 3600000) {
      return <Text color="red.500">A promoção deve ter duração mínima de 60 minutos</Text>;
    }

    if (checkIfHasMoreThanOneDay > 24) {
      return <Text color="red.500">A promoção não deve ter duração maior que 24 horas</Text>;
    }

    if (checkIfIsAfterCampaingEnd) {
      return (
        <Text color="red.500">
          O término da promoção deve ser menor ou igual ao término da campanha
        </Text>
      );
    }

    return false;
  };

  const handleSelectFontColor = (color: string) => {
    setValue('fontColor', color);
  };

  const handleSelectBackgroundColor = (color: string) => {
    setValue('backgroundColor', color);
  };

  useEffect(() => {
    const getEndDate = localStorage.getItem('@end-campaign-date');
    if (getEndDate) {
      setEndCampaignDate(getEndDate);
    }

    if (!endCampaignDate && currentDate === 'Invalid Date') {
      setLoadingDate(true);
    }
  }, [currentDate, endCampaignDate]);

  if (loadingDate) return <Text>Carregando...</Text>;

  return (
    <Box>
      <Text fontWeight="bold" fontSize="lg">
        <Text as="span" color="primary.100">
          Configurações
        </Text>{' '}
        da contagem regressiva
      </Text>

      <Box p="1rem 0.5rem" margin="30px 0" borderTop="1px solid #EEE" borderBottom="1px solid #EEE">
        <FormControl isRequired>
          <FormLabel>Texto da contagem regressiva </FormLabel>
          <InputGroup>
            <Input
              isRequired
              maxLength={30}
              value={getValues('label')}
              position="relative"
              {...register('label', { onChange: (e) => handleTitleCountdown(e) })}
            />
            <InputRightElement>
              <Tooltip label="Caracteres restantes">{handleColorCharacteres()}</Tooltip>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Flex flexDirection="column" padding="7px 0 ">
          <Checkbox
            size="lg"
            marginTop="6px"
            colorScheme="primary"
            color="#838181"
            alignItems="flex-start"
            mt={2}
            {...register('hasHelperModal')}
            defaultChecked={item?.countdown?.helper}
          >
            <Text fontSize="14px" mt="-2px">
              Adicionar modal de ajuda “
              <QuestionIcon />” com detalhes da promoção
            </Text>
          </Checkbox>
        </Flex>
      </Box>
      <Grid px="0.5rem" gap="20px">
        <GridItem>
          <Tooltip
            borderRadius="md"
            textAlign="center"
            label="A data de início da promoção é a mesma data de início da campanha."
          >
            <FormControl minWidth="300px" isDisabled>
              <FormLabel>Data e hora de início da promoção</FormLabel>
              <Controller
                name="endDate"
                control={control}
                rules={{ deps: 'startDate' }}
                render={() => (
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{ deps: 'endDate' }}
                    render={({ field: { onChange } }) => (
                      <DateTime value={currentDate} onChange={onChange} isDisabled />
                    )}
                  />
                )}
              />
            </FormControl>
          </Tooltip>
        </GridItem>

        <GridItem margin="0">
          <FormControl minWidth="300px">
            <FormLabel>Data e hora de finalização da promoção</FormLabel>
            <InputGroup>
              <Controller
                name="startDate"
                control={control}
                render={() => (
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{ deps: 'endDate' }}
                    render={({ field: { value, onChange } }) => (
                      <DateTime
                        highlightMode
                        value={value || String(addMinutes(new Date(currentDate), 60))}
                        onChange={onChange}
                        minValue={currentDate}
                      />
                    )}
                  />
                )}
              />
            </InputGroup>
            <FormHelperText>{validationDates()}</FormHelperText>
          </FormControl>
        </GridItem>
      </Grid>

      <Box marginTop="30px" pl="11px" borderTop="1px solid #EEE">
        <Text marginTop="16px" fontWeight="bold" fontSize="lg">
          <Text as="span" color="primary.100">
            Estilo
          </Text>{' '}
          da contagem regressiva
        </Text>

        <FormControl isRequired gap="8px" mt="16px">
          <FormLabel>Cor do fundo</FormLabel>
          <CustomRadioGroup
            options={colors}
            onChange={handleSelectBackgroundColor}
            defaultValue={getValues('backgroundColor') || '#EC008C'}
          />
        </FormControl>

        <FormControl isRequired gap="8px" mt="16px">
          <FormLabel>Cor do Texto</FormLabel>
          <Stack {...getRootProps()}>
            <HStack>
              <CustomRadioGroup
                options={colors}
                onChange={handleSelectFontColor}
                defaultValue={getValues('fontColor') || '#FFFFFF'}
              />
            </HStack>
          </Stack>
        </FormControl>
      </Box>
    </Box>
  );
}
