/* eslint-disable no-console */
import { Flex } from '@chakra-ui/react';
import React from 'react';
import DateInput from './DateInput';
import Time from './Time';

type Props = {
  /**
   * ISO 8601 Datetime string format
   */
  value: string;
  /**
   * @param datetime ISO 8601 Datetime string format
   */
  onChange: (datetime: string) => void;
  minValue?: string;
  highlightMode?: boolean;
  startHighlight?: string;
  endHighlight?: string;
  isDisabled?: boolean;
};

function DateTime(props: Props) {
  const { value, onChange, minValue, isDisabled } = props;

  return (
    <Flex width="100%" height="56px" cursor="not-allowed">
      <DateInput {...props} />
      <Time value={value} minValue={minValue} onChange={onChange} isDisabled={isDisabled} />
    </Flex>
  );
}

export default DateTime;
