import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Center,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import warningSource from '../../../assets/icons/warning.png';
import { PrimaryOutlineButton } from '../../styles';

type Props = {
  title?: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  isCancelDisabled?: boolean;
};

const DEFAULT_TITLE = 'Tem certeza que deseja continuar?';
const DEFAULT_DESCRIPTION = 'Atenção! Essa ação não poderá ser desfeita';

const Footer = styled(Flex)`
  * {
    flex: 1;
  }
`;

/**
 * Generic confirmation modal, if you need to customize more than the API allow, use chakraUI
 * AlertDialog component to create your own custom component.
 *
 * use the children prop to render the footer.
 */
function ConfirmationModal({ children: footer, ...props }: PropsWithChildren<Props>) {
  const {
    isOpen,
    onClose,
    isCancelDisabled = false,
    title = DEFAULT_TITLE,
    description = DEFAULT_DESCRIPTION,
  } = props;

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogCloseButton />
        <AlertDialogContent>
          <AlertDialogBody textAlign="center" padding="48px 64px">
            <Center flexDirection="column" marginBottom="32px">
              <Image src={warningSource} marginBottom="24px" />
              <Text fontWeight="semibold" fontSize="24px" marginBottom="16px" color="black">
                {title}
              </Text>
              <Text color="gray.60">{description}</Text>
            </Center>
            <Footer>
              <PrimaryOutlineButton
                ref={cancelRef}
                onClick={onClose}
                marginRight={footer ? '4' : undefined}
                disabled={isCancelDisabled}
              >
                Cancelar
              </PrimaryOutlineButton>
              {footer}
            </Footer>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default ConfirmationModal;
