import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Icon from '../../../shared/components/Icon';
import { CustomTag } from '../../../shared/components/Tag';

interface AccordionFormProps {
  id: string;
  children: React.ReactNode;
  label: string;
  icon: string | any;
  status?: 'CONCLUIDO' | 'PENDENTE';
}

export function AccordionForm({ id, children, label, icon, status, ...rest }: AccordionFormProps) {
  const [isCorrectId, setIsCorrectId] = useState(0);

  const handleChange = (idx: number) => {
    setIsCorrectId(idx);
  };

  const isOpen = isCorrectId >= 0;

  return (
    <Accordion allowToggle onChange={handleChange} {...rest}>
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton borderBottom={isExpanded ? '1px solid #EEEEEE' : ''}>
              <Flex
                flex="1"
                gap="8px"
                alignItems="center"
                justifyContent="space-between"
                textAlign="left"
                py="24px"
              >
                <Flex>
                  <Icon icon={icon} fontSize="x-large" color="primary.500" mr="10px" />
                  <Text color="black.100" fontWeight="bold">
                    {label}
                  </Text>
                </Flex>
                {isOpen || id ? <CustomTag status={status} /> : ''}
              </Flex>
              <AccordionIcon color="primary.500" fontSize="xx-large" />
            </AccordionButton>
            <AccordionPanel>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}
