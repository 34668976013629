import {
  Breadcrumb as CUIBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink as CUIBreakcrumbLink,
  BreadcrumbLinkProps,
  BreadcrumbProps,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { SimpleText } from '../../styles';

export function Breadcrumb(props: BreadcrumbProps) {
  return (
    <CUIBreadcrumb
      {...props}
      color="gray.60"
      spacing="25px"
      separator={
        <SimpleText color="gray.90" fontWeight="bold">
          /
        </SimpleText>
      }
    />
  );
}

export function BreadcrumbLink(props: BreadcrumbLinkProps & Partial<LinkProps>) {
  const { isCurrentPage } = props;

  return (
    <CUIBreakcrumbLink
      {...props}
      as={isCurrentPage ? 'span' : Link}
      fontWeight={isCurrentPage ? 'bold' : 'normal'}
      color={isCurrentPage ? 'primary.100' : 'inherit'}
    />
  );
}

// it's useless, but we want to only import Breadcrumbs from here instead of chakraUI
export { BreadcrumbItem, BreadcrumbSeparator };
