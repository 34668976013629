import { Button, Center, Flex, SimpleGrid, useMediaQuery } from '@chakra-ui/react';
import React, { ChangeEventHandler, useEffect, useMemo } from 'react';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '../../shared/components/Breadcrumb';
import { Select } from '../../shared/components/Form';
import { editMediaToast } from '../../shared/components/Toast';
import Header from '../../shared/components/Header';
import Icon from '../../shared/components/Icon';
import Search from '../../shared/components/Search';
import useDebounce from '../../shared/hooks/useDebounce';
import { Order, DefaultSort } from '../../shared/services/types';
import MediaService from '../../shared/services/MediaService';
import useStore from '../../shared/store';
import { PageBox, PageSpinner, SimpleText, TablePagination } from '../../shared/styles';
import { capitalizeFirst } from '../../shared/utils';
import DeleteMediaModal from './components/DeleteMediaModal';
import EditMediaModal from './components/EditMediaModal';
import MediaItem from './components/MediaItem';

function MediaLibrary() {
  const [isLessThan768] = useMediaQuery('(max-width: 768px)');
  const [isLessThan425] = useMediaQuery('(max-width: 425px)');
  const debounce = useDebounce();
  const { categoryId } = useParams();
  const location = useLocation();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const params = useMemo(
    () => MediaService.getParams(urlSearchParams, categoryId),
    [urlSearchParams, categoryId]
  );

  const { isLoading, data } = useStore((state) => state.media.fetchMedia);
  const categories = useStore((state) => state.categories.fetchCategories.data.categories);
  const isLoadingBatchUpload = useStore((state) => state.media.batchUpload.isLoading);
  const { fetchMedia, setEditMedia, setInitialCategoryId } = useStore((state) => state.api);

  useEffect(() => {
    fetchMedia(params);
  }, [fetchMedia, params]);

  const openCreateModal = () => {
    if (isLoadingBatchUpload) {
      editMediaToast();
    } else {
      setEditMedia('');
      if (categoryId) {
        setInitialCategoryId(categoryId);
      }
    }
  };

  const { pagination } = data;
  const { current, perPage, total } = pagination;
  const totalPages = Math.ceil(total / perPage) || 1;
  const lastPageOffset = total % perPage;
  const isLastPage = totalPages === params.page;

  const currentCategory = categories.find((category) => category.id === categoryId);

  const handleSearch = debounce((filter: string) => setUrlSearchParams({ filter }, location), 500);

  const handleSortCreatedAt: ChangeEventHandler<HTMLSelectElement> = (e) =>
    setUrlSearchParams({ order: e.target.value, sort: DefaultSort.CREATED_AT }, location);

  const handleSortName: ChangeEventHandler<HTMLSelectElement> = (e) =>
    setUrlSearchParams({ order: e.target.value, sort: DefaultSort.NAME }, location);

  const loadingView = (
    <Center>
      <PageSpinner marginBottom="5" />
    </Center>
  );

  const mediaList = (
    <SimpleGrid
      columns={isLastPage && lastPageOffset > 4 ? undefined : { md: 2, lg: 2, xl: 3, '2xl': 4 }}
      minChildWidth={isLastPage && lastPageOffset > 4 ? '300px' : undefined}
      spacing="50px"
    >
      {data.media.map((item) => (
        <MediaItem key={item.id} item={item} />
      ))}
    </SimpleGrid>
  );

  const checkBreadCrumbTitle = capitalizeFirst(currentCategory?.name || 'todas as imagens');

  const hideUploadButon = checkBreadCrumbTitle.includes('Todas as imagens');
  return (
    <PageBox>
      <Header marginBottom="20px">
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/midia/galeria" state={location.state}>
              <Icon icon="icon-image" /> <SimpleText>Categoria</SimpleText>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink isCurrentPage>
              <SimpleText>{checkBreadCrumbTitle}</SimpleText>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justifyContent="space-between">
          <Search
            placeholder="Pesquisar arquivo"
            width={`${hideUploadButon && !isLessThan425 ? '535px' : '256px'}`}
            onSearch={handleSearch}
            onChange={handleSearch}
            hideUploadButon={hideUploadButon}
          />
          {!hideUploadButon ? (
            <Button colorScheme="primary" size="lg" width="256px" onClick={openCreateModal}>
              <Icon icon="icon-cloud-upload" marginRight="5px" fontSize="xl" />
              Enviar arquivo
            </Button>
          ) : null}
        </Flex>
      </Header>
      <Flex marginBottom="40px" justifyContent={isLessThan768 ? 'center' : 'flex-end'}>
        <Select
          key={`${params.sort} createdAt`}
          marginRight="24px"
          width="256px"
          placeholder="Ordenar por data de criação"
          isTouched={params.sort === DefaultSort.CREATED_AT}
          value={params.sort === DefaultSort.CREATED_AT ? params.order : undefined}
          onChange={handleSortCreatedAt}
        >
          <option value={Order.DESC}>Mais novo</option>
          <option value={Order.ASC}>Mais antigo</option>
        </Select>
        <Select
          key={`${params.sort} name`}
          width="256px"
          placeholder="Ordenar por nome"
          isTouched={params.sort === DefaultSort.NAME}
          value={params.sort === DefaultSort.NAME ? params.order : undefined}
          onChange={handleSortName}
        >
          <option value={Order.ASC}>Ordem alfábetica (A-Z)</option>
          <option value={Order.DESC}>Ordem alfábetica (Z-A)</option>
        </Select>
      </Flex>
      {isLoading ? loadingView : mediaList}
      <TablePagination
        marginTop="24px"
        path={categoryId ? `/midia/galeria/${categoryId}` : '/midia'}
        current={current}
        perPage={perPage}
        total={total}
      />
      <EditMediaModal />
      <DeleteMediaModal />
    </PageBox>
  );
}

export default MediaLibrary;
