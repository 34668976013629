import { Box, Button, Center, Flex, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import React, { ChangeEventHandler, useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import ActionButton from '../../shared/components/ActionButton';
import Icon from '../../shared/components/Icon';
import Header, { HeaderDescription, HeaderTitle } from '../../shared/components/Header';
import { TextEllipsis } from '../../shared/components/Typography';
import { Select } from '../../shared/components/Form';
import Table, { renderDefaultColumn } from '../../shared/components/Table';
import { PageSpinner, TablePagination } from '../../shared/styles';
import useStore from '../../shared/store';
import UserService from '../../shared/services/UserService';
import { DefaultSort, Order } from '../../shared/services/types';
import DeleteUserModal from './components/DeleteUserModal';
import PasswordResetPopover from './components/PasswordResetPopover';

const columns = ['Nome', 'E-mail', 'Perfil', 'Ações'].map(renderDefaultColumn);

function UserList() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => UserService.getParams(searchParams), [searchParams]);

  const { fetchUsers, setEditUser, setDeleteUser } = useStore((state) => state.api);
  const { isLoading, data } = useStore((state) => state.users.fetchUsers);
  const authenticatedUser = useStore((state) => state.auth.login.data);

  useEffect(() => {
    fetchUsers(params);
  }, [fetchUsers, params]);

  const { pagination } = data;
  const { current, perPage, total } = pagination;

  const handleSortCreatedAt: ChangeEventHandler<HTMLSelectElement> = (e) =>
    setSearchParams({ order: e.target.value, sort: DefaultSort.CREATED_AT }, location);
  const handleSortName: ChangeEventHandler<HTMLSelectElement> = (e) =>
    setSearchParams({ order: e.target.value, sort: DefaultSort.NAME }, location);

  const openDrawer = () => setEditUser('');
  const closeDeleteModal = () => setDeleteUser(null);

  const users = data.users.map((user) => {
    const handleEdit = () => setEditUser(user.id);
    const handleDelete = () => setDeleteUser(user.id);

    const actionsView = (
      <>
        <ActionButton
          icon={<Icon icon="icon-edit-alt" fontSize="2xl" />}
          aria-label={`Editar usuário ${user.name}`}
          onClick={handleEdit}
          title="Editar usuário"
        />
        <PasswordResetPopover user={user} />
        <ActionButton
          icon={<Icon icon="icon-delete" fontSize="2xl" />}
          onClick={handleDelete}
          aria-label={`Excluir usuário ${user.name}`}
          title="Excluir usuário"
        />
      </>
    );

    return (
      <Tr key={user.id} _hover={{ backgroundColor: 'gray.5' }}>
        <Td>
          <Tooltip label={user.name}>
            <TextEllipsis
              width={{ base: '120px', xl: '220px' }}
              maxWidth={{ base: '120px', xl: '220px' }}
            >
              {user.name}
            </TextEllipsis>
          </Tooltip>
        </Td>
        <Td>
          <Tooltip label={user.email}>
            <TextEllipsis
              width={{ base: '145px', xl: '250px' }}
              maxWidth={{ base: '145px', xl: '250px' }}
            >
              {user.email}
            </TextEllipsis>
          </Tooltip>
        </Td>
        <Td>
          <Text width="80px">{user.role.name}</Text>
        </Td>
        <Td>{user.id === authenticatedUser!.id || actionsView}</Td>
      </Tr>
    );
  });

  const loadingView = (
    <Center>
      <PageSpinner marginBottom="5" />
    </Center>
  );

  const tableView = (
    <Table columns={columns} rows={users}>
      <TablePagination path="/usuarios" current={current} perPage={perPage} total={total} />
    </Table>
  );

  return (
    <Box padding="40px 30px 0 45px">
      <Header marginBottom="20px">
        <HeaderTitle icon="icon-users">
          Gestão de usuários
          <HeaderDescription>Faça a gestão de usuários da sua equipe</HeaderDescription>
        </HeaderTitle>
        <Button colorScheme="primary" size="lg" width="256px" onClick={openDrawer}>
          <Icon icon="icon-user-add" marginRight="5px" fontSize="xl" />
          Cadastrar usuário
        </Button>
      </Header>
      <Flex marginBottom="40px" justifyContent="flex-end">
        <Select
          key={`${params.sort} createdAt`}
          marginRight="24px"
          width="256px"
          paddingLeft="10px"
          placeholder="Ordenar por data de criação"
          isTouched={params.sort === DefaultSort.CREATED_AT}
          value={params.sort === DefaultSort.CREATED_AT ? params.order : undefined}
          onChange={handleSortCreatedAt}
        >
          <option value={Order.DESC}>Mais novo</option>
          <option value={Order.ASC}>Mais antigo</option>
        </Select>
        <Select
          key={`${params.sort} name`}
          width="256px"
          placeholder="Ordenar por nome"
          isTouched={params.sort === DefaultSort.NAME}
          value={params.sort === DefaultSort.NAME ? params.order : undefined}
          onChange={handleSortName}
        >
          <option value={Order.ASC}>Ordem alfábetica (A-Z)</option>
          <option value={Order.DESC}>Ordem alfábetica (Z-A)</option>
        </Select>
      </Flex>
      {isLoading ? loadingView : tableView}
      <DeleteUserModal onClose={closeDeleteModal} />
    </Box>
  );
}

export default UserList;
