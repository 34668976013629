import { Box, chakra, Switch, Tooltip } from '@chakra-ui/react';
import { Draggable } from '@hello-pangea/dnd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../../shared/components/ActionButton';
import Icon from '../../../shared/components/Icon';
import { Td, Tr } from '../../../shared/components/Table';
import { TextEllipsis } from '../../../shared/components/Typography';
import { IScreen } from '../../../shared/services/types/screen';
import useStore from '../../../shared/store';
import { SimpleText } from '../../../shared/styles';
import { isScreenAlmostObsolete } from '../shared';

type Props = {
  index: number;
  screen: IScreen;
};

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
} as const;

const ScreenTd = chakra(Td, {
  baseStyle: {
    paddingX: { base: '12px !important', '2xl': 'auto' },
  },
});

const placeholder = <SimpleText fontSize="25px">---</SimpleText>;
const expirationWarning = (
  <Tooltip label="Esta campanha expirará em breve, por gentileza, publique novas campanhas">
    <Box display="inline-block" marginLeft="6px">
      <Icon fontSize="2xl" icon="icon-circle-warning" color="red.100" verticalAlign="middle" />
    </Box>
  </Tooltip>
);
const standardActiveCell = (
  <Tooltip label="A tela backup é uma tela sistêmica e só será ativada se caso não houver nenhuma tela com campanha vigente.">
    <Icon
      icon="icon-circle-information"
      fontSize="3xl"
      color="gray.80"
      verticalAlign="middle"
      _hover={{ color: 'black' }}
    />
  </Tooltip>
);

function ScreenItem({ index, screen }: Props) {
  const navigate = useNavigate();
  const isLoadingOrdering = useStore((state) => state.screens.orderScreens.isLoading);
  const { clearCampaignUpsert } = useStore((state) => state.api);
  const isEnabling = useStore(
    ({ screens }) => !!screens.enableScreen.find(({ id }) => id === screen.id)?.fetchState.isLoading
  );

  const { setEditScreen, setDeleteScreen, enableScreen } = useStore((state) => state.api);

  const { campaign, enabled } = screen;
  const showCampaign = enabled && campaign;
  const endDate = campaign && new Date(campaign.endDate).toLocaleString('pt-BR', DATE_OPTIONS);
  const shouldShowExpirationWarn = isScreenAlmostObsolete(screen);

  const goToCampaigns = () => {
    if (screen.standard) {
      const upsertPath = screen.campaign?.id;
      navigate(`${screen.id}/campanhas/${upsertPath}/componentes`);
      clearCampaignUpsert();
    } else {
      navigate(`${screen.id}/campanhas`);
    }
  };

  const editScreen = () => setEditScreen(screen.id);
  const openDeleteModal = () => setDeleteScreen(screen.id);
  const onEnable = () => enableScreen(screen.id, !screen.enabled);

  const nonStandardActions = (
    <>
      <ActionButton
        title="Editar tela"
        aria-label="editar tela"
        icon={<Icon icon="icon-edit-alt" fontSize="2xl" />}
        onClick={editScreen}
      />
      <ActionButton
        title="Deletar tela"
        aria-label="deletar tela"
        icon={<Icon icon="icon-delete" fontSize="2xl" />}
        onClick={openDeleteModal}
      />
    </>
  );

  const activeSwitch = (
    <Switch
      colorScheme="primary"
      isChecked={isEnabling ? !screen.enabled : screen.enabled}
      onChange={onEnable}
      disabled={isEnabling}
    />
  );

  const showBackupCampaingInfo = campaign?.name.includes('Backup');
  const showCampaingNameOnTable =
    showCampaign || showBackupCampaingInfo ? campaign?.name : placeholder;

  const hidePlaceholderOnBackup = showBackupCampaingInfo ? '' : placeholder;

  const cells = (
    <>
      <ScreenTd>
        <Tooltip label={screen.name}>
          <TextEllipsis
            maxWidth={{ base: '100px', '2xl': '200px' }}
            width={{ base: '100px', '2xl': '200px' }}
          >
            {screen.name}
          </TextEllipsis>
        </Tooltip>
      </ScreenTd>
      <ScreenTd>{screen.standard ? standardActiveCell : activeSwitch}</ScreenTd>
      <ScreenTd>
        <Tooltip label={screen.campaign ? screen.campaign.name : ''}>
          <TextEllipsis
            maxWidth={{ base: '150px', xl: '175px', '2xl': '300px' }}
            width={{ base: '150px', xl: '175px', '2xl': '300px' }}
          >
            {showCampaingNameOnTable}
          </TextEllipsis>
        </Tooltip>
      </ScreenTd>
      <ScreenTd width="300px">
        {showCampaign ? (
          <SimpleText verticalAlign="middle">{endDate}</SimpleText>
        ) : (
          hidePlaceholderOnBackup
        )}

        {shouldShowExpirationWarn && expirationWarning}
      </ScreenTd>
      <ScreenTd>
        <ActionButton
          title="Ir para campanhas"
          aria-label="Ir para campanhas"
          icon={<Icon icon="icon-chevron-double-right" fontSize="2xl" />}
          isDisabled={screen.standard ? !screen.campaign : false}
          onClick={goToCampaigns}
        />
        {!screen.standard && nonStandardActions}
      </ScreenTd>
    </>
  );

  if (screen.standard) {
    return (
      <Tr>
        <Td />
        {cells}
      </Tr>
    );
  }

  return (
    <Draggable draggableId={screen.id} index={index} isDragDisabled={isLoadingOrdering}>
      {(dragProvided, snapshot) => (
        <Tr
          backgroundColor={snapshot.isDragging ? 'gray.5' : 'white'}
          fontWeight={snapshot.isDragging ? 'bold' : 'normal'}
          boxShadow={snapshot.isDragging ? 'lg' : 'none'}
          {...dragProvided.draggableProps}
          ref={dragProvided.innerRef}
        >
          <ScreenTd>
            <Tooltip label="A ordenação desta listagem reflete diretamente no menu superior do aplicativo">
              <Box
                width="min-content"
                marginX="auto"
                color={isLoadingOrdering ? 'gray.60' : 'black'}
                {...dragProvided.dragHandleProps}
              >
                <Icon
                  icon="icon-menu"
                  fontSize="2xl"
                  display={screen.standard ? 'none' : 'inline'}
                />
              </Box>
            </Tooltip>
          </ScreenTd>
          {cells}
        </Tr>
      )}
    </Draggable>
  );
}

export default ScreenItem;
