import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, { FormEventHandler, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AutoComplete, { AutocompleteItem } from '../../../shared/components/Form/AutoComplete';
import Header, { HeaderDescription, HeaderTitle } from '../../../shared/components/Header';
import useStore from '../../../shared/store';
import { DrawerBody, DrawerCloseButton, DrawerHeader } from '../../../shared/styles';

type Props = {
  campaignId?: string;
  onClose: () => void;
};

function MoveCampaignDrawer({ campaignId, onClose }: Props) {
  const initialRef = useRef<HTMLInputElement | null>(null);
  const { screenId } = useParams();
  const [screen, setScreen] = useState<AutocompleteItem>();

  const screens = useStore((state) => state.screens.fetchScreens.data.screens);
  const isLoading = useStore((state) => state.campaigns.moveCampaign.isLoading);
  const { moveCampaign } = useStore((state) => state.api);

  const options = screens.reduce<AutocompleteItem[]>((acc, { id, name, standard }) => {
    if (!standard && screenId !== id) {
      acc.push({ value: id, name });
    }

    return acc;
  }, []);

  const onChangeScreen = (selectedItems: AutocompleteItem[]) => setScreen(selectedItems.pop());

  const handleClose = () => {
    setScreen(undefined);
    onClose();
  };

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    await moveCampaign(campaignId!, screenId!, screen!.value);
    handleClose();
  };

  return (
    <Drawer isOpen={campaignId !== undefined} onClose={handleClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Header>
            <HeaderTitle icon="icon-switch">
              Mover campanha
              <HeaderDescription>
                Insira a informação abaixo para mover a campanha
              </HeaderDescription>
            </HeaderTitle>
          </Header>
        </DrawerHeader>
        <DrawerBody>
          <Box as="form" onSubmit={onSubmit}>
            <FormControl>
              <FormLabel>Tela</FormLabel>
              <AutoComplete
                ref={initialRef}
                removeable={false}
                placeholder="Escolha uma tela"
                selectedItems={screen ? [screen] : []}
                options={options}
                onChange={onChangeScreen}
              />
            </FormControl>
            <Button
              marginTop="18px"
              width="100%"
              type="submit"
              colorScheme="primary"
              disabled={!screen || isLoading}
              isLoading={isLoading}
            >
              Mover campanha
            </Button>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default MoveCampaignDrawer;
