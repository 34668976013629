import { Box, Button, Flex, HStack, List, ListItem, Text } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { LogoWhite } from '../../../shared/components/Logo';
import { TextEllipsis } from '../../../shared/components/Typography';
import useStore from '../../../shared/store';
import Icon from '../../../shared/components/Icon';
import marisaEffect from '../../../assets/images/marisa-effect.png';
import { links } from '../shared';
import UserMenu from './UserMenu';
import { SimpleText } from '../../../shared/styles';

function Sidebar() {
  const user = useStore((state) => state.auth.login.data);
  const { logout } = useStore((state) => state.api);

  const navLinks = links.reduce<JSX.Element[]>((acc, link) => {
    const { filter = [], name, to, icon } = link;
    const isFiltered = filter.some((role) => role === user!.role.name);
    const navLink = (
      <ListItem key={name}>
        <NavLink to={to}>
          {({ isActive }) => (
            <Box
              display="flex"
              alignItems="center"
              height="60px"
              _hover={{ textDecoration: 'none', fontWeight: 'bold' }}
              color={isActive ? 'primary.100' : 'gray.50'}
              fontWeight={isActive ? 'bold' : 'semibold'}
            >
              <Box
                border="4.5px solid transparent"
                alignSelf="stretch"
                marginRight="15px"
                borderLeftColor={isActive ? 'primary.100' : 'transparent'}
              />
              <Icon icon={icon} fontSize="xl" />
              <SimpleText verticalAlign="top" marginLeft="10px">
                {name}
              </SimpleText>
            </Box>
          )}
        </NavLink>
      </ListItem>
    );

    return isFiltered ? acc : [...acc, navLink];
  }, []);

  return (
    <Flex
      position="relative"
      zIndex="sticky"
      flexDirection="column"
      minWidth="250px"
      backgroundColor="white"
      paddingBottom="30px"
      boxShadow="rgba(0, 0, 0, 0.1) 5px 0px 25px -5px, rgba(0, 0, 0, 0.04) 0px 0px 25px -5px"
    >
      <HStack
        spacing={0}
        height="80px"
        justifyContent="center"
        alignItems="center"
        marginBottom="25px"
        backgroundColor="primary.100"
        position="relative"
      >
        <Box
          position="absolute"
          backgroundImage={marisaEffect}
          backgroundSize="100%"
          backgroundRepeat="no-repeat"
          height="100%"
          width="50px"
          left="0px"
        />
        <LogoWhite width="130px" />
      </HStack>
      <HStack spacing={0}>
        <Flex
          flexDirection="column"
          padding="0 15px 25px"
          marginBottom="25px"
          borderBottom="1px solid"
          borderColor="gray.10"
          width="100%"
        >
          <TextEllipsis maxWidth="190px" fontWeight="bold">
            {user!.name}
          </TextEllipsis>
          <Flex width="100%" justifyContent="space-between" fontWeight="semibold">
            <TextEllipsis maxWidth="190px" paddingRight="5px" color="gray.50">
              {user!.email}
            </TextEllipsis>
            <UserMenu>
              <Icon icon="icon-caret-down" fontSize="lg" />
            </UserMenu>
          </Flex>
        </Flex>
      </HStack>
      <HStack spacing={0} flexDirection="column" alignItems="stretch">
        <Text color="gray.80" fontWeight="semibold" padding="0 20px">
          Ações
        </Text>
        <List spacing={0} fontSize="16px">
          {navLinks}
        </List>
      </HStack>
      <HStack spacing={0} marginTop="auto">
        <Button
          onClick={logout}
          color="red"
          margin="auto"
          variant="unstyled"
          fontWeight="normal"
          fontSize="lg"
        >
          <Icon icon="icon-log-out" marginRight="10px" fontSize="xl" verticalAlign="middle" />
          <Text as="span" verticalAlign="middle">
            Sair
          </Text>
        </Button>
      </HStack>
    </Flex>
  );
}

export default Sidebar;
