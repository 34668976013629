import { Box, Button, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import ActionButton from '../../../shared/components/ActionButton';
import Icon from '../../../shared/components/Icon';
import { editMediaToast } from '../../../shared/components/Toast';
import { TextEllipsis } from '../../../shared/components/Typography';
import { ICategoryImages } from '../../../shared/services/types/category';
import useStore from '../../../shared/store';
import { capitalizeFirst } from '../../../shared/utils';
import { CategoryCard, renderImages } from '../shared';

function CategoryItem({ item: { id, name, media } }: { item: ICategoryImages }) {
  const location = useLocation();
  const isLoadingBatchUpload = useStore((state) => state.media.batchUpload.isLoading);
  const { setEditMedia, setEditCategory, setDeleteCategory, setInitialCategoryId } = useStore(
    (state) => state.api
  );

  const hasImages = media.length > 0;

  const openCreateModal = () => {
    if (isLoadingBatchUpload) {
      editMediaToast();
    } else {
      setEditMedia('');
    }
  };
  const openCreateModalWithCategory = (categoryId: string) => {
    setInitialCategoryId(categoryId);
    openCreateModal();
  };

  const onClick = () => openCreateModalWithCategory(id);
  const onEdit = () => setEditCategory(id);
  const onDelete = () => setDeleteCategory(id);

  const emptyCategoryView = (
    <Button
      whiteSpace="inherit"
      variant="unstyled"
      width="100%"
      height="auto"
      display="block"
      onClick={onClick}
    >
      <Center
        flexDirection="column"
        height="200px"
        backgroundColor="gray.5"
        color="gray.80"
        textAlign="center"
        padding="15px"
        fontSize="xl"
      >
        <Icon icon="icon-cloud-upload" fontSize="5xl" />
        <Text>Clique para adicionar uma imagem</Text>
      </Center>
    </Button>
  );

  return (
    <CategoryCard key={id} overflow="hidden" height="300px">
      {hasImages ? renderImages(media, id, location) : emptyCategoryView}
      <Flex
        margin="18px 0 12px"
        textAlign="start"
        padding="0 12px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip label={name}>
          <TextEllipsis maxWidth="100%">{capitalizeFirst(name)}</TextEllipsis>
        </Tooltip>
        <Box minWidth="max-content" lineHeight="none">
          <ActionButton
            icon={<Icon icon="icon-edit-alt" />}
            title="Editar categoria"
            aria-label="Editar categoria"
            onClick={onEdit}
          />
          <ActionButton
            icon={<Icon icon="icon-delete" />}
            title="Deletar categoria"
            aria-label="Deletar categoria"
            onClick={onDelete}
          />
        </Box>
      </Flex>
    </CategoryCard>
  );
}

export default CategoryItem;
