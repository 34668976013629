import { Box, Button, Center, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import Icon from '../../../shared/components/Icon';
import { NavPageLink } from '../../../shared/components/Pagination';
import toast from '../../../shared/components/Toast';
import { ICampaignCreate, Status } from '../../../shared/services/types/campaign';
import useStore from '../../../shared/store';
import { PrimaryOutlineButton } from '../../../shared/styles';
import Review from './Review';

const openUpsertErrorToast = () => {
  const UPSERT_ERROR_TOAST_ID = 'campaign-upsert-id';

  if (!toast.isActive(UPSERT_ERROR_TOAST_ID)) {
    toast({
      id: UPSERT_ERROR_TOAST_ID,
      status: 'error',
      title: 'Alguns campos estão inválidos ',
      description: 'Verifique e ajuste o componente destacado',
    });
  }
};

const DEFAULT_FORM = { form: undefined };

function BottomBar() {
  const { campaignId, screenId } = useParams();
  const [searchParams] = useSearchParams();
  const reviewModal = useDisclosure();
  const locationState = (useLocation().state as { form: ICampaignCreate } | null) || DEFAULT_FORM;
  const [openModal, setOpenModal] = React.useState(false);
  const campaign = useStore((state) => state.campaigns.fetchCampaign.data);
  const isLoadingSave = useStore((state) => state.campaigns.saveCampaign.isLoading);
  const isLoadingUnpublish = useStore((state) => state.campaigns.unpublishCampaign.isLoading);

  const { components: addedComponents, errors } = useStore((state) => state.campaigns.upsert);
  const { dispatchSubmit, changePosition, unpublishCampaign, redirectBackup } = useStore(
    (state) => state.api
  );

  const isEdit = !!campaignId;
  const isDuplicate = searchParams.get('duplicate');
  const isPublished = !isDuplicate && campaign && Status.DRAFT !== campaign.status;
  const backRoute = campaign?.standard ? '/telas' : `..${isDuplicate ? '?duplicate=true' : ''}`;
  const upsertCampaign = {
    ...locationState.form,
    ...((!isEdit || isDuplicate) && { screen: screenId }),
    ...(campaignId && !isDuplicate && { id: campaignId }),
    components: addedComponents,
  };

  const handleSubmit = (submit: () => void) => () => {
    const firstErrorPosition = errors.findIndex((error) => {
      if (Array.isArray(error)) {
        return error.every((subError) => Object.keys(subError).length > 0);
      }

      return Object.keys(error).length > 0;
    });
    const isValid = firstErrorPosition === -1;

    dispatchSubmit();
    if (isValid) {
      submit();
    } else {
      changePosition(firstErrorPosition);
      openUpsertErrorToast();
    }
  };

  const save = handleSubmit(() => redirectBackup(upsertCampaign));
  const onOpenReview = handleSubmit(() => reviewModal.onOpen());
  const onClose = () => setOpenModal(false);
  const unpublish = () => unpublishCampaign(campaignId!);

  const saveButtonView = (
    <PrimaryOutlineButton
      marginLeft="auto"
      maxWidth="245px"
      isLoading={isLoadingSave}
      disabled={isLoadingSave}
      onClick={save}
    >
      {isPublished ? 'Salvar' : 'Salvar rascunho'}
    </PrimaryOutlineButton>
  );

  let publishButtonView = (
    <Button
      colorScheme="primary"
      marginLeft="auto"
      width="300px"
      isLoading={isLoadingSave}
      disabled={isLoadingSave}
      onClick={save}
    >
      Salvar
    </Button>
  );

  if (!campaign?.standard) {
    if (isPublished) {
      publishButtonView = (
        <Button
          colorScheme="red"
          width="245px"
          disabled={isLoadingUnpublish}
          isLoading={isLoadingUnpublish}
          onClick={() => setOpenModal(true)}
        >
          Despublicar
        </Button>
      );
    } else {
      publishButtonView = (
        <Button
          width="245px"
          colorScheme="primary"
          variant="solid"
          disabled={isLoadingSave || addedComponents.length === 0}
          onClick={onOpenReview}
        >
          Continuar
        </Button>
      );
    }
  }

  return (
    <Center paddingX="20px" height="100px" borderTop="1px solid" borderColor="gray.20">
      <ConfirmationModal
        isOpen={openModal}
        onClose={onClose}
        isCancelDisabled={isLoadingUnpublish}
        description="
        Ao despublicar uma campanha, ela sairá do ar e pode atingir a programação da home do app."
      >
        <Button onClick={unpublish} variant="solid" colorScheme="primary">
          Continuar
        </Button>
      </ConfirmationModal>
      <NavPageLink as={Link} to={backRoute} state={locationState}>
        <Box as="span" marginRight="10px">
          <Icon icon="icon-chevron-left" paddingTop="1px" />
        </Box>
        <Text>Voltar</Text>
      </NavPageLink>
      {!campaign?.standard && saveButtonView}
      {publishButtonView}
      <Review
        isOpen={reviewModal.isOpen}
        onClose={reviewModal.onClose}
        data={{ ...campaign, ...locationState.form! }}
        campaign={upsertCampaign}
      />
    </Center>
  );
}

export default BottomBar;
