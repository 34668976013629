import React from 'react';
import { Flex, Tag } from '@chakra-ui/react';

interface TagProps {
  status?: 'CONCLUIDO' | 'PENDENTE';
}

export function CustomTag({ status }: TagProps) {
  const renderTag = () => {
    switch (status) {
      case 'CONCLUIDO':
        return (
          <Tag
            borderRadius="3xl"
            w="100%"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            fontWeight="500"
            color="white"
            bg="green.100"
          >
            CONCLUÍDO
          </Tag>
        );
      case 'PENDENTE':
        return (
          <Tag
            borderRadius="3xl"
            w="100%"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            fontWeight="500"
            color="white"
            bg="yellow.100"
          >
            PENDENTE
          </Tag>
        );
      default:
    }
  };

  return (
    <Flex width="100px" alignItems="center" justifyContent="center">
      {renderTag()}
    </Flex>
  );
}
