import { Text, TextProps, forwardRef } from '@chakra-ui/react';
import React from 'react';

type TextEllipsisProps = {
  maxWidth: TextProps['maxWidth'];
};

export const TextEllipsis = forwardRef(
  ({ maxWidth, children, ...rest }: TextEllipsisProps & TextProps, ref) => (
    <Text
      {...rest}
      ref={ref}
      maxWidth={maxWidth}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      {children}
    </Text>
  )
);

TextEllipsis.displayName = 'TextEllipsis';
