import { createSearchParams } from 'react-router-dom';
import { privateApi } from '../../api';
import history from '../../history';
import { responseErrorToast, responseToast } from '../components/Toast';
import createService from '../store/serverState';
import { Getter, ParamsWithPage, Setter } from '../store/store.types';
import { splitIn } from '../utils';
import {
  handlePaginationOnDeletion,
  isPayloadTooLarge,
  findById,
  getParam,
  getPage,
} from '../utils/requestHelpers';
import { DefaultSort, ISuccessResponse, ISuccessResponseList, Order } from './types';
import {
  IBatchMediaErrorFile,
  IBatchMediaErrorItem,
  IBatchMediaResponse,
  IEditMedia,
  IFormBatchMedia,
  IFormDataMedia,
  IMedia,
  IMediaListResponse,
  IMediaResponse,
  MediaLibraryParams,
  UpsertMedia,
} from './types/media';

const PER_PAGE = 12;

const getParams = (
  searchParams: URLSearchParams,
  categoryId?: string
): ParamsWithPage<MediaLibraryParams> => ({
  categoryId,
  order: getParam<Order>('order', searchParams),
  sort: getParam<DefaultSort>('sort', searchParams),
  filter: getParam('filter', searchParams),
  page: getPage(searchParams),
});

const getMediaLibrary = async (params: MediaLibraryParams = {}) => {
  const { perPage = PER_PAGE, categoryId: category, order = Order.DESC, ...rest } = params;
  const { data } = await privateApi.get<ISuccessResponseList<IMediaListResponse>>('/media', {
    params: { perPage, order, category, ...rest },
  });

  return data.data;
};

export const updateMedia = async (media: IEditMedia) => {
  const { id, ...payload } = media;
  const { data } = await privateApi.patch<ISuccessResponse<IMediaResponse>>(
    `/media/${id}`,
    payload
  );

  return data.data.media;
};

const createMedia = async (form: IFormDataMedia) => {
  const { file, category } = form;
  const { data } = await privateApi.postForm<ISuccessResponse<IMediaResponse>>('/media', {
    file,
    categoryId: category,
  });

  return data.data.media;
};

const batchCreateMedia = async (form: IFormBatchMedia) => {
  const formData = new FormData();

  if (form.categoryId) {
    formData.append('categoryId', form.categoryId);
  }
  form.files.forEach((file) => {
    formData.append('files', file);
  });

  const { data } = await privateApi.post<ISuccessResponse<IBatchMediaResponse>>(
    '/media/batch',
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

  return data.data.result;
};

const deleteMedia = (id: string) => privateApi.delete(`/media/${id}`);

const isCreatedMedia = (media: UpsertMedia): media is IEditMedia =>
  (media as IEditMedia).id !== undefined;

const upsertMedia = (media: UpsertMedia) =>
  isCreatedMedia(media) ? updateMedia(media) : createMedia(media);

const isErrorItem = (item: IBatchMediaErrorItem | IMedia): item is IBatchMediaErrorItem =>
  (item as IBatchMediaErrorItem).error !== undefined;

export const createMediaEndpoints = (set: Setter, get: Getter) => {
  const mediaPlaceholder = {
    pagination: { current: 0, total: 0, perPage: PER_PAGE },
    media: [],
  };

  const createEndpoint = createService({ set, getSlice: ({ media }) => media, prefix: 'media' });

  const setter = (nextStateOrUpdater: Parameters<typeof set>[0], action: string) =>
    set(nextStateOrUpdater, false, action);

  const resolveBatch = async (batch: File[], categoryId?: string | null) => {
    const result = await batchCreateMedia({ files: batch, categoryId });

    setter((state) => {
      const categories = state.categories.fetchCategories.data.categories;
      const successfulItems = result.filter((item) => !isErrorItem(item)) as IMedia[];
      const categoryIndex = categories.findIndex(({ id }) => id === categoryId);

      state.media.uploadState.uploadCount += successfulItems.length;
      state.media.fetchMedia.data.pagination.total += successfulItems.length;

      if (categoryIndex !== -1) {
        const length = successfulItems.length >= 2 ? 2 : 1;
        categories[categoryIndex].media.splice(0, length, ...successfulItems.slice(0, 2));
      }
    }, 'media/batchUpload/dispatchMedias');

    return result;
  };

  const getCategoryFromPathname = (pathname: string) => {
    const isShowAll = pathname.endsWith('/midia');

    return isShowAll ? undefined : pathname.split('/').pop();
  };

  return {
    fetchMedia: createEndpoint(
      'fetchMedia',
      async ({ page = 1, order = Order.DESC, ...params }: ParamsWithPage<MediaLibraryParams>) => {
        const current = (page - 1) * get().media.fetchMedia.data.pagination.perPage;
        return getMediaLibrary({ current, order, ...params });
      },
      { placeholder: mediaPlaceholder }
    ),
    saveMedia: createEndpoint(
      'saveMedia',
      async (media: UpsertMedia, categoryId?: string, isShowAll: boolean = false) => {
        try {
          const item = await upsertMedia(media);
          const hasCurrentCategory = item.category.id === categoryId;
          const index = findById(item, get().media.fetchMedia.data.media);

          if (!isCreatedMedia(media)) {
            set(({ categories: { initialImages, fetchCategories } }) => {
              initialImages.unshift(item);
              if (initialImages.length >= 2) {
                initialImages.pop();
              }

              const category = fetchCategories.data.categories.find(
                (currentItem) => item.category.id === currentItem.id
              );
              if (category) {
                category.media.unshift(item);
                if (category.media.length >= 2) {
                  category.media.pop();
                }
              }
            });
          }

          const searchParams = createSearchParams(history.location.search);
          const refetch = () =>
            get().api.fetchMedia.actions.refetch(getParams(searchParams, categoryId));

          if (hasCurrentCategory || isShowAll) {
            if (index === -1) {
              refetch();
            } else {
              set((state) => {
                state.media.fetchMedia.data.media[index] = item;
              });
            }
          } else if (index !== -1) {
            refetch();
          }

          responseToast({ title: 'Mídia salva com sucesso', status: 'success' });

          return item;
        } catch (error) {
          if (isPayloadTooLarge(error)) {
            responseErrorToast('O arquivo deve ser menor que 5MB');
          }

          throw error;
        }
      },
      { shouldThrowError: true, placeholder: null }
    ),
    deleteMedia: createEndpoint(
      'deleteMedia',
      async (id: string, categoryId?: string) => {
        await deleteMedia(id);

        handlePaginationOnDeletion(
          get().media.fetchMedia.data.pagination,
          `/midia/${categoryId ? `galeria/${categoryId}` : ''}`,
          (params) => get().api.fetchMedia.actions.refetch(getParams(params, categoryId))
        );

        responseToast({ title: 'Mídia deletada com sucesso', status: 'success' });
      },
      { placeholder: null }
    ),
    batchUpload: createEndpoint(
      'batchUpload',
      async ({ files, categoryId }: IFormBatchMedia) => {
        setter((state) => {
          state.media.uploadState.total = files.length;
        }, 'media/batchUpload/start');

        let uploadResult: (IBatchMediaErrorItem | IMedia)[] = [];
        try {
          const batches = splitIn(files, 10).map((batch) => resolveBatch(batch, categoryId));

          uploadResult = (await Promise.all(batches)).flat();
          if (!uploadResult.some(isErrorItem)) {
            responseToast({ status: 'success', title: 'mídias salvas com sucesso!' });

            if (get().media.fetchMedia.hasFetched) {
              const params = createSearchParams(history.location.search);

              get().api.fetchMedia.actions.refetch(
                getParams(params, getCategoryFromPathname(history.location.pathname))
              );
            }
          }
        } catch (error) {
          if (isPayloadTooLarge(error)) {
            responseErrorToast('O arquivo deve ser menor que 5MB');
          }

          throw error;
        } finally {
          setter(({ media: { uploadState } }) => {
            uploadState.total = 0;
            uploadState.uploadCount = 0;

            uploadState.failedUploads = uploadResult.reduce<IBatchMediaErrorFile[]>((acc, item) => {
              if (isErrorItem(item)) {
                const file = files.find((currentFile) => currentFile.name === item.file);
                if (file) {
                  acc.push({ file, error: item.error });
                }
              }

              return acc;
            }, []);
          }, 'media/batchUpload/done');
        }

        return uploadResult;
      },
      { placeholder: [] }
    ),
  };
};

export default {
  getMediaLibrary,
  createMedia,
  updateMedia,
  upsertMedia,
  deleteMedia,
  batchCreateMedia,
  getParams,
  perPage: PER_PAGE,
};
