import { createSearchParams } from 'react-router-dom';
import { privateApi } from '../../api';
import history from '../../history';
import { responseErrorToast, responseToast } from '../components/Toast';
import createService from '../store/serverState';
import { Getter, ParamsWithPage, Setter } from '../store/store.types';
import { handlePaginationOnDeletion, findById, getPage, getParam } from '../utils/requestHelpers';
import {
  CategoryParams,
  ICategory,
  ICategoryImages,
  ICategoryList,
  ICategoryResponse,
  ICategoryUpdateResponse,
  IMediaItemErrors,
  UpsertCategory,
} from './types/category';
import { ISuccessResponse, ISuccessResponseList, Order, DefaultSort } from './types';
import MediaService from './MediaService';

const PER_PAGE = 12;

const getParams = (searchParams: URLSearchParams) => ({
  page: getPage(searchParams),
  filter: getParam('filter', searchParams),
  order: getParam<Order>('order', searchParams),
  sort: getParam<DefaultSort>('sort', searchParams),
});

const getCategories = async (params: CategoryParams) => {
  const { perPage = PER_PAGE, order = Order.DESC, ...rest } = params;
  const { data } = await privateApi.get<ISuccessResponseList<ICategoryList>>('/categories', {
    params: { perPage, order, ...rest },
  });

  const categories: ICategoryImages[] = data.data.categories.map((category) => ({
    ...category,
    media: category.media || [],
  }));

  return { ...data.data, categories };
};

const createCategory = async (category: Omit<ICategory, 'id'>) => {
  const { data } = await privateApi.post<ISuccessResponse<ICategoryResponse>>(
    '/categories',
    category
  );

  return { ...data.data.category, media: data.data.category.media || [] };
};

const updateCategory = async ({ id, ...category }: Partial<ICategory>) => {
  const { data } = await privateApi.patch<ISuccessResponse<ICategoryUpdateResponse>>(
    `categories/${id}`,
    category
  );

  return data.data.category;
};

const isCreatedCategory = (category: UpsertCategory): category is ICategory =>
  (category as ICategory).id !== undefined;

const upsertCategory = (category: UpsertCategory) =>
  isCreatedCategory(category) ? updateCategory(category) : createCategory(category);

const deleteCategory = async (id: string) =>
  (await privateApi.delete<ISuccessResponse<IMediaItemErrors | null>>(`/categories/${id}`)).data
    .data;

export const createCategoriesEndpoints = (set: Setter, get: Getter) => {
  const categoriesPlaceholder = {
    pagination: { current: 0, total: 0, perPage: PER_PAGE },
    categories: [],
  };

  const createEndpoint = createService({
    set,
    getSlice: ({ categories }) => categories,
    prefix: 'categories',
  });

  return {
    fetchCategoriesAutocomplete: createEndpoint(
      'fetchCategoriesAutocomplete',
      async (search?: string) => (await getCategories({ filter: search, perPage: 5 })).categories,
      { placeholder: [] }
    ),
    fetchCategories: createEndpoint(
      'fetchCategories',
      async ({ page = 1, ...params }: ParamsWithPage<CategoryParams>) => {
        const current = (page - 1) * get().categories.fetchCategories.data.pagination.perPage;

        get().api.dispatchInitialImages((await MediaService.getMediaLibrary({ perPage: 2 })).media);
        return getCategories({ current, ...params });
      },
      { placeholder: categoriesPlaceholder }
    ),
    saveCategory: createEndpoint(
      'saveCategory',
      async (category: UpsertCategory, autocompleteSearch: string | null) => {
        const item = await upsertCategory(category);

        const { data } = get().categories.fetchCategories;
        const media = data.categories.find((c) => c.id === item.id)?.media || [];
        const newItem = { ...item, media };
        const index = findById(newItem, get().categories.fetchCategories.data.categories);

        if (index === -1) {
          const searchParams = createSearchParams(history.location.search);
          get().api.fetchCategories.actions.refetch(getParams(searchParams));
        } else {
          set((state) => {
            state.categories.fetchCategories.data.categories[index] = newItem;
          });
        }

        if (autocompleteSearch) {
          get().api.fetchCategoriesAutocomplete(autocompleteSearch);
        }
        responseToast({ title: 'Categoria salva com sucesso', status: 'success' });

        return item;
      },
      { placeholder: null, shouldThrowError: true }
    ),
    deleteCategory: createEndpoint(
      'deleteCategory',
      async (id: string) => {
        const data = await deleteCategory(id);

        if (data) {
          responseErrorToast(
            'Algumas mídias desta categoria estão sendo utilizadas em campanhas e não podem ser deletadas.',
            undefined,
            4000
          );
        } else {
          responseToast({ title: 'Categoria deletada com sucesso', status: 'success' });

          handlePaginationOnDeletion(
            get().categories.fetchCategories.data.pagination,
            '/midia/galeria',
            (params) => get().api.fetchCategories.actions.refetch(getParams(params))
          );
        }

        return data;
      },
      { placeholder: null }
    ),
  };
};

export default {
  getCategories,
  createCategory,
  updateCategory,
  upsertCategory,
  deleteCategory,
  getParams,
  perPage: PER_PAGE,
};
