import { createScreensEndpoints } from '../../services/ScreenService';
import { createSliceState } from '../serverState';
import { SliceCreator, ScreensSlice } from '../store.types';

const createScreensSlice: SliceCreator<ScreensSlice> = (set, get) => ({
  ...createSliceState(() => createScreensEndpoints(set, get)),
  upsert: { currentId: null },
  deletion: { currentId: null },
});

export default createScreensSlice;
