type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

export const getDirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: any
): Record<string, unknown> => {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is true for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  if (!dirtyFields) {
    return {};
  }
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyValues(dirtyFields[key] as UnknownArrayOrObject, allValues[key]),
    ])
  );
};
