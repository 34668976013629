import { createBrowserHistory } from 'history';

/**
 * History object for react-router and usage of routing outside of React components.
 * The react-router library doesn't have a good documentation about this, but everything
 * we need to know about it is covered here: https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
 */
const history = createBrowserHistory();

/**
 * The SPAs need to redirect any route to the index route to run
 * the application but on S3(the current production env) redirects
 * come with a #! prefix that needs to be removed, otherwise react-router
 * redirects the user to the application's index route.
 */
const stripRedirectPrefix = (): string | undefined => {
  const PREFIX_REGEX = /#!(\/.*)$/;

  // [prefix, path]
  const [, path] = PREFIX_REGEX.exec(history.location.hash) || [];

  return path;
};

export const initializeHistory = () => {
  const path = stripRedirectPrefix();
  if (path) {
    history.replace(path);
  }
};

export default history;
