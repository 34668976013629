import { Box, Flex, FlexProps, Link, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { Location, NavLink, useLocation, useSearchParams } from 'react-router-dom';
import Icon from '../Icon';

type Props = {
  current?: number;
  limit?: number;
  perPage: number;
  total: number;
  path: string;
};

const PageLink = styled(Link)`
  &:not(:last-child) {
    margin-right: 5px;
  }

  :hover {
    text-decoration: underline;
  }
`;

export const NavPageLink = styled(Link)`
  display: inline-flex;
  alignitems: center;

  :hover {
    text-decoration: none;

    p {
      text-decoration: underline;
    }
  }

  span {
    display: inline-flex;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
    background-color: var(--chakra-colors-primary-100);
    color: var(--chakra-colors-white);
  }
`;

const getLengths = (currentPage: number, totalPages: number, limit: number) => {
  let leftLength = Math.floor(limit / 2) + 1;
  let rightLength = Math.ceil(limit / 2);
  let isRightEdge = rightLength + currentPage >= totalPages;
  let isLeftEdge = currentPage - leftLength <= 1;

  if (isLeftEdge) {
    const newRightLength = rightLength + leftLength - currentPage;
    const isNewRightLengthEdge = newRightLength + currentPage >= totalPages;
    if (!isRightEdge) {
      if (isNewRightLengthEdge) {
        rightLength = totalPages - currentPage;
        isRightEdge = true;
      } else {
        rightLength = newRightLength;
      }
    }

    leftLength = currentPage;
  }

  if (isRightEdge) {
    const newRightLength = totalPages - currentPage;
    const newLeftLength = leftLength + rightLength - newRightLength;
    const isNewLeftLengthEdge = currentPage - newLeftLength <= 1;
    if (!isLeftEdge) {
      if (isNewLeftLengthEdge) {
        leftLength = currentPage;
        isLeftEdge = true;
      } else {
        leftLength = newLeftLength;
      }
    }

    rightLength = newRightLength;
  }

  return { leftLength, rightLength, isRightEdge, isLeftEdge };
};

const renderPageButton = (
  page: number,
  currentPage: number,
  generatePagePath: (page: number) => string,
  location: Location
) => {
  const isActive = page === currentPage;

  return (
    <PageLink
      key={page}
      as={isActive ? 'span' : NavLink}
      to={generatePagePath(page)}
      state={location.state}
      variant="link"
      disabled={isActive}
      cursor={isActive ? 'text' : 'pointer'}
      fontWeight={isActive ? 'bold' : 'normal'}
      color={isActive ? 'primary.100' : 'gray.80'}
      textDecoration={isActive ? 'underline' : 'none'}
    >
      {page}
    </PageLink>
  );
};

function Pagination(props: FlexProps & Props) {
  const [urlSearchParams] = useSearchParams();
  const location = useLocation();
  const { current = 0, limit = 2, perPage, total, path, ...rest } = props;

  const currentPage = Math.floor(current / perPage) + 1;
  const totalPages = Math.ceil(total / perPage) || 1;

  const { leftLength, rightLength, isRightEdge } = getLengths(currentPage, totalPages, limit);
  const startPage = currentPage - leftLength + 1;

  const generatePagePath = (page: number) => {
    const search = new URLSearchParams(urlSearchParams);
    search.set('page', `${page}`);

    return `${path}?${search}`;
  };

  const leftPageButtons = Array.from({ length: leftLength }, (_, i) =>
    renderPageButton(startPage + i, currentPage, generatePagePath, location)
  );
  const rightPageButtons = Array.from({ length: rightLength }, (_, i) =>
    renderPageButton(currentPage + i + 1, currentPage, generatePagePath, location)
  );

  const lastPageLink = (
    <Text>
      ...{' '}
      <PageLink
        as={NavLink}
        fontWeight="bold"
        to={generatePagePath(totalPages)}
        state={location.state}
        variant="link"
        color="gray.80"
      >
        {totalPages}
      </PageLink>
    </Text>
  );

  return (
    <Flex color="gray.80" fontSize="lg" {...rest}>
      <NavPageLink
        as={NavLink}
        to={generatePagePath(currentPage - 1)}
        state={location.state}
        visibility={currentPage === 1 ? 'hidden' : 'visible'}
      >
        <Box as="span" marginRight="10px">
          <Icon icon="icon-chevron-left" />
        </Box>
        <Text>Anterior</Text>
      </NavPageLink>
      <Flex marginX="auto">
        Página <Text marginX="3px">&lt;</Text>
        <Flex>
          {leftPageButtons}
          {rightPageButtons}
          {!isRightEdge && lastPageLink}
        </Flex>
        <Text marginLeft="3px">&gt;</Text>
      </Flex>
      <NavPageLink
        as={NavLink}
        to={generatePagePath(currentPage + 1)}
        state={location.state}
        display="inline-flex"
        visibility={currentPage === totalPages ? 'hidden' : 'visible'}
      >
        <Text>Próximo</Text>
        <Box as="span" marginLeft="10px">
          <Icon icon="icon-chevron-right" paddingTop="1px" />
        </Box>
      </NavPageLink>
    </Flex>
  );
}

export default Pagination;
