import { Box, Flex, FlexProps, Heading, Text, useMediaQuery } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';
import Icon from '../Icon';
import { IconType } from '../Icon/Icon.types';

function Header({ children, ...rest }: PropsWithChildren & FlexProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Flex
      justifyContent="space-between"
      flexDirection={`${isMobile ? 'column' : 'row'}`}
      alignItems="center"
      {...rest}
    >
      {children}
    </Flex>
  );
}

export function HeaderTitle({ children, icon }: PropsWithChildren & { icon?: IconType }) {
  return (
    <Flex fontSize="3xl" color="gray.90">
      {icon && <Icon icon={icon} marginRight="16px" />}
      <Box>
        <Heading as="h1" fontSize="3xl" lineHeight="none">
          {children}
        </Heading>
      </Box>
    </Flex>
  );
}

export function HeaderDescription({ children }: PropsWithChildren) {
  return (
    <Text fontWeight="semibold" fontSize="sm" color="gray.60" lineHeight="1.5">
      {children}
    </Text>
  );
}

export default Header;
