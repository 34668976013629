/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { differenceInHours, isAfter } from 'date-fns';
import { FormConfigCountdown } from './FormConfigCountdown';
import { FormHelperModal } from './FormHelperModal';
import { PreviewCountdown } from './PreviewCountdown';
import { validateUrl } from '../../../../shared/utils/validation';
import { MediaFieldCursor } from '../../CampaignComponents.types';
import { Item } from '../../../../shared/services/types/campaign';
import toast from '../../../../shared/components/Toast';
import { useCampaingCountdown } from '../../../../shared/store/useCampaignCountdown';

interface ModalCountdownConfigProps {
  disabled?: boolean;
  handleSave: (property: any) => void;
  mediaField: MediaFieldCursor;
}

const descriptionTextArea =
  'Promoção por tempo limitado e válida para compras realizadas no período em que houver a sinalização. Para mais informações, acesse o regulamento.';

export function ModalCountdowndConfig({
  disabled,
  handleSave,
  mediaField,
}: ModalCountdownConfigProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [defaultFormValues, setDefaultFormValues] = useState<Partial<Item>>({});
  const [endCampaingDate, setEndCampaignDate] = useState<Date | null>(new Date());
  const [endCampaingDateConfig, setEndCampaignDateConfig] = useState('');
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { currentDate } = useCampaingCountdown((state) => state);

  const methods = useForm({
    defaultValues: {
      label: defaultFormValues.countdown?.label || 'Essa promoção termina em:',
      backgroundColor: defaultFormValues.countdown?.backgroundColor || '#EC008C',
      fontColor: defaultFormValues.countdown?.backgroundColor || '#FFFFFF',
      endDate: defaultFormValues.countdown?.endDate || endCampaingDate,
      'title-modal': defaultFormValues.countdown?.helper?.title || 'Sobre a promoção',
      'description-modal': defaultFormValues.countdown?.helper?.description || descriptionTextArea,
      'button-link': defaultFormValues.countdown?.helper?.buttonLink || 'https://www.marisa.com.br',
      'button-text': defaultFormValues.countdown?.helper?.buttonText || 'Mais informações',
      hasUrl: false,
      hasHelperModal: false,
    },
  });

  const isMediaTypedCurrectly =
    methods.watch('button-link') && !validateUrl(methods.watch('button-link'));

  const handleSaveCountdown = () => {
    if (isMediaTypedCurrectly) {
      toast({ status: 'error', title: 'URL digitada incorretamente!' });
      return;
    }

    if (methods.watch('hasHelperModal')) {
      handleSave({
        label: `${methods.getValues('label')}`,
        endDate: `${methods.getValues('endDate')}`,
        backgroundColor: `${methods.getValues('backgroundColor')}`,
        fontColor: `${methods.getValues('fontColor')}`,
        helper: {
          title: `${methods.getValues('title-modal')}`,
          description: `${methods.getValues('description-modal')}`,
          buttonText: `${methods.getValues('button-text')}`,
          buttonLink: `${methods.getValues('hasUrl') ? '#' : methods.getValues('button-link')}`,
        },
      });
    } else {
      handleSave({
        label: `${methods.getValues('label')}`,
        endDate: `${methods.getValues('endDate')}`,
        backgroundColor: `${methods.getValues('backgroundColor')}`,
        fontColor: `${methods.getValues('fontColor')}`,
      });
    }

    onClose();
  };

  const validationDates = () => {
    const startDateCampaing = new Date(currentDate);
    const endDateCampaing = new Date(methods.watch('endDate'));

    const checkIfIsLessThanSixtyMinutes = endDateCampaing.getTime() - startDateCampaing.getTime();
    const checkIfHasMoreThanOneDay = differenceInHours(endDateCampaing, startDateCampaing);
    const checkIfIsAfterCampaingEnd = isAfter(endDateCampaing, new Date(endCampaingDateConfig));

    if (checkIfIsLessThanSixtyMinutes < 3600000) {
      return true;
    }

    if (checkIfHasMoreThanOneDay > 24) {
      return true;
    }

    if (checkIfIsAfterCampaingEnd) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setDefaultFormValues(mediaField.item!);

    const getEndDate = localStorage.getItem('@end-campaign-date');
    setEndCampaignDate(new Date(String(getEndDate)));

    methods.reset({
      label: mediaField.item?.countdown?.label || 'Essa promoção termina em:',
      backgroundColor: mediaField.item?.countdown?.backgroundColor || '#EC008C',
      fontColor: mediaField.item?.countdown?.fontColor || '#FFFFFF',
      endDate: mediaField.item?.countdown?.endDate,
      'title-modal': mediaField.item?.countdown?.helper?.title || 'Sobre a promoção',
      'description-modal': mediaField.item?.countdown?.helper?.description || descriptionTextArea,
      'button-link': mediaField.item?.countdown?.helper?.buttonLink || 'https://www.marisa.com.br',
      'button-text': mediaField.item?.countdown?.helper?.buttonText || 'Mais informações',
    });
  }, [mediaField]);

  useEffect(() => {
    const getEndDate = localStorage.getItem('@end-campaign-date');
    if (getEndDate) {
      setEndCampaignDateConfig(getEndDate);
    }
  }, [currentDate, endCampaingDateConfig]);

  return (
    <>
      <Button
        mt="14px"
        background="primary.500"
        color="white"
        _hover={{ background: 'primary.600' }}
        onClick={() => onOpen()}
        disabled={disabled}
      >
        Configurar
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="7xl"
      >
        <ModalOverlay />
        <FormProvider {...methods}>
          <ModalContent borderRadius="2xl" margin=" 10px" overflow="hidden">
            <ModalCloseButton />

            <form onSubmit={methods.handleSubmit(handleSaveCountdown)}>
              <ModalBody p={6}>
                <Grid templateColumns="repeat(3, 1fr)" gap="6">
                  <GridItem w="100%" px="1rem" borderRight="1px solid #EEE">
                    <FormConfigCountdown item={mediaField.item} />
                  </GridItem>

                  <GridItem w="100%" px="1rem" borderRight="1px solid #EEE">
                    <FormHelperModal item={mediaField.item} />
                  </GridItem>

                  <GridItem w="100%" px="1rem">
                    <PreviewCountdown item={mediaField.item} />
                  </GridItem>
                </Grid>
              </ModalBody>

              <ModalFooter borderTop="1px solid #eee">
                <Button
                  mt="14px"
                  background="primary.500"
                  color="white"
                  _hover={{ background: 'primary.600' }}
                  width="180px"
                  disabled={!!validationDates()}
                  type="submit"
                >
                  Salvar
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </FormProvider>
      </Modal>
    </>
  );
}
