import React from 'react';
import { Link, Location } from 'react-router-dom';
import { Box, Flex, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { IMedia } from '../../shared/services/types/media';

export const CategoryCard = styled(Box)`
  display: block;
  width: auto;
  height: auto;
  white-space: inherit;
  padding: 0;
  border-radius: 8px;
  border: 1px solid;
  border-color: var(--chakra-colors-gray-20);
  background-color: var(--chakra-colors-white);
  font-weight: bold;

  & > a:hover,
  & > button:hover {
    opacity: 0.5;
  }
`;

const mediaGradient = `
  linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) 75%, rgba(0, 0, 0, 0.8))
`;

export const renderImages = (images: IMedia[], to: string, location?: Location) => (
  <Flex
    as={Link}
    to={to}
    state={location && location.state}
    borderRadius="8px 8px 0 0"
    height="200px"
    position="relative"
    zIndex="base"
    overflow="hidden"
    backgroundColor="gray.90"
    _after={{
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      height: '60%',
      background: mediaGradient,
    }}
  >
    {images.slice(0, 2).map(({ id, download }) => (
      <Box
        _first={images.length > 1 ? { marginRight: '1px' } : {}}
        key={id}
        backgroundColor="white"
        height="200px"
        width="100%"
      >
        <Image src={download} objectFit="cover" height="100%" width="100%" />
      </Box>
    ))}
  </Flex>
);
