import { UseToastOptions } from '@chakra-ui/react';
import axios, { AxiosError, CanceledError } from 'axios';
import { createSearchParams } from 'react-router-dom';
import history from '../../history';
import { responseErrorToast, unknownResponseErrorToast } from '../components/Toast';
import { IErrorResponse, IPagination } from '../services/types';

export const isBadRequest = (error: unknown): error is AxiosError<IErrorResponse, any> =>
  axios.isAxiosError(error) && error.response?.status === 400;

export const isUnauthorized = (error: unknown): error is AxiosError<IErrorResponse, any> =>
  axios.isAxiosError(error) && error.response?.status === 401;

export const isForbidden = (error: unknown): error is AxiosError<IErrorResponse, any> =>
  axios.isAxiosError(error) && error.response?.status === 403;

export const isPayloadTooLarge = (error: unknown): error is AxiosError<undefined, any> =>
  axios.isAxiosError(error) && error.response?.status === 413;

export const isUnsupportedMediaType = (error: unknown): error is AxiosError<IErrorResponse, any> =>
  axios.isAxiosError(error) && error.response?.status === 415;

export const isNetworkError = (error: unknown): error is AxiosError<undefined, any> =>
  axios.isAxiosError(error) && !error.response && !!error.request;

export const isAbortError = (error: unknown): error is CanceledError<any> =>
  error instanceof CanceledError;

export const findById = <T extends { id?: string }>(item: T, list: T[]) =>
  list.findIndex((currentItem) => currentItem.id === item.id);

export const handleGenericErrors = (error: any, position?: UseToastOptions['position']) => {
  if (isUnauthorized(error) || isAbortError(error) || isPayloadTooLarge(error)) {
    return;
  }
  if (isForbidden(error)) {
    responseErrorToast('Você não possuí permissão para realizar a operação', position);
  } else if (isNetworkError(error)) {
    responseErrorToast('Erro de conexão, verifique sua conexão com a internet.', position);
  } else if (isBadRequest(error) || isUnsupportedMediaType(error)) {
    responseErrorToast(error.response!.data.data.message, position);
  } else {
    unknownResponseErrorToast(position);
  }
};

export const handlePaginationOnDeletion = (
  pagination: IPagination,
  to: string,
  onFetch: (params: URLSearchParams) => Promise<unknown>
) => {
  const { current, total, perPage } = pagination;
  const isLastPageItem = total - current === 1;
  const page = Math.floor(current / perPage) + 1;
  const params = createSearchParams(history.location.search);

  if (isLastPageItem && page > 1) {
    params.set('page', `${page - 1}`);

    history.replace(`${to}?${params}`);
  } else {
    onFetch(params);
  }
};

/**
 * Change return value of URLSearchParams get method from null to undefined when
 * the key isn't on the query string to show a omitted param, that makes typing easier.
 */
export const getParam = <T extends string = string>(key: string, params: URLSearchParams) =>
  (params.get(key) as T) || undefined;

export const getPage = (params: URLSearchParams) => parseInt(params.get('page') || '1', 10);
