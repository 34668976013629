import { Button, Center, Flex, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ActionButton from '../../shared/components/ActionButton';
import Header from '../../shared/components/Header';
import Icon from '../../shared/components/Icon';
import Table, { Td, Th, Tr } from '../../shared/components/Table';
import { Breadcrumb, BreadcrumbLink, BreadcrumbItem } from '../../shared/components/Breadcrumb';
import { TextEllipsis } from '../../shared/components/Typography';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import Search from '../../shared/components/Search';
import useDebounce from '../../shared/hooks/useDebounce';
import { ICampaign, Status } from '../../shared/services/types/campaign';
import { Order } from '../../shared/services/types';
import useStore from '../../shared/store';
import { PageBox, PageSpinner, SimpleText, TablePagination } from '../../shared/styles';
import CampaignService from '../../shared/services/CampaignService';
import DeleteCampaignModal from './components/DeleteCampaignModal';
import DuplicateCampaignModal from './components/DuplicateCampaignModal';
import MoveCampaignDrawer from './components/MoveCampaignDrawer';

const CURRENT_EDIT_WARN =
  'As alterações realizadas em uma campanha com status "vigente" serão exibidas diretamente na versão atual do app';

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
} as const;

const COLUMNS_DATA: { name: string; sort?: keyof ICampaign }[] = [
  { name: 'Nome', sort: 'name' },
  { name: 'Data início', sort: 'startDate' },
  { name: 'Data fim', sort: 'endDate' },
  { name: 'Status' },
  { name: 'Ações' },
];

const STATUS_COLOR = {
  [Status.CURRENT]: 'green.100',
  [Status.DELETED]: 'gray.100',
  [Status.DRAFT]: 'yellow.100',
  [Status.EXPIRED]: 'red.100',
  [Status.PUBLISHED]: 'blue.700',
} as const;

const renderActions = (
  campaign: ICampaign,
  handleEdit: () => void,
  handleDelete: () => void,
  handleDuplicate: () => void,
  handleMove: () => void,
  isMoveDisabled: boolean
) => {
  const moveAction = (
    <ActionButton
      minWidth={{ base: '36px', '2xl': '25px' }}
      icon={<Icon icon="icon-switch" fontSize={{ base: '2xl', '2xl': 'xl' }} />}
      aria-label={`Mover campanha ${campaign.name}`}
      onClick={handleMove}
      disabled={isMoveDisabled}
      title="Mover para outra tela"
    />
  );

  const editAction = (
    <ActionButton
      minWidth={{ base: '36px', '2xl': '25px' }}
      icon={<Icon icon="icon-edit-alt" fontSize={{ base: '2xl', '2xl': 'xl' }} />}
      aria-label={`Editar campanha ${campaign.name}`}
      onClick={handleEdit}
      title="Editar campanha"
    />
  );

  const deleteAction = (
    <ActionButton
      minWidth={{ base: '36px', '2xl': '25px' }}
      icon={<Icon icon="icon-delete" fontSize={{ base: '2xl', '2xl': 'xl' }} />}
      onClick={handleDelete}
      aria-label={`Excluir campanha ${campaign.name}`}
      title="Excluir campanha"
    />
  );

  const duplicateAction = (
    <ActionButton
      minWidth={{ base: '36px', '2xl': '25px' }}
      icon={<Icon icon="icon-copy" fontSize={{ base: '2xl', '2xl': 'xl' }} />}
      aria-label={`Duplicar campanha ${campaign.name}`}
      onClick={handleDuplicate}
      title="Duplicar campanha"
    />
  );

  switch (campaign.status) {
    case Status.EXPIRED:
      return (
        <>
          {moveAction}
          {duplicateAction}
        </>
      );
    case Status.CURRENT:
    case Status.PUBLISHED:
      return (
        <>
          {editAction}
          {duplicateAction}
        </>
      );
    case Status.DRAFT:
      return (
        <>
          {editAction}
          {moveAction}
          {deleteAction}
        </>
      );
    default:
      return null;
  }
};

function CampaignList() {
  const debounce = useDebounce();
  const navigate = useNavigate();
  const { screenId } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(
    () => CampaignService.getParams(searchParams, screenId),
    [searchParams, screenId]
  );

  const currentCampaignAlert = useDisclosure();
  const duplicateModal = useDisclosure();
  const [duplicateId, setDuplicateId] = useState<string>();
  const [moveId, setMoveId] = useState<string>();

  const screens = useStore((state) => state.screens.fetchScreens.data.screens);
  const { data, isLoading } = useStore((state) => state.campaigns.fetchCampaigns);
  const isMoving = useStore((state) => state.campaigns.moveCampaign.isLoading);
  const { fetchCampaigns, setDeleteCampaign } = useStore((state) => state.api);

  const screen = useMemo(() => screens.find((item) => item.id === screenId), [screens, screenId]);
  const currentCampaign = data.campaigns.find((campaign) => campaign.status === Status.CURRENT);

  const { expandSidebar } = useStore((state) => state.api);
  const { isSidebarExpanded } = useStore((state) => state.app);

  useEffect(() => {
    fetchCampaigns(params);

    if (!isSidebarExpanded) {
      expandSidebar(true);
    }
  }, [fetchCampaigns, params, isSidebarExpanded, expandSidebar]);

  const { pagination, campaigns } = data;
  const { current, perPage, total } = pagination;

  const onCloseMoveDrawer = () => setMoveId(undefined);
  const onDuplicate = () => navigate(`${duplicateId}?duplicate=true`);
  const onEditCurrent = () => navigate(currentCampaign!.id);
  const onSearch = debounce((filter: string) => setSearchParams({ filter }, location), 500);
  const handleSortOrder = (sort: string, order: Order) => () =>
    setSearchParams({ sort, order }, location);
  const api = useStore((state) => state.api);

  const columns = COLUMNS_DATA.map((column) => {
    const actions = (
      <Tooltip label="Ordenar">
        <Text as="span" position="relative" top="-10px">
          <ActionButton
            minWidth="auto"
            aria-label={`Ordenação asendente em ${column.name}`}
            icon={<Icon icon="icon-arrow-up" fontSize="md" />}
            color={column.sort! === params.sort && params.order === Order.ASC ? 'black' : 'gray.60'}
            onClick={handleSortOrder(column.sort || '', Order.ASC)}
          />
          <ActionButton
            minWidth="auto"
            aria-label={`Ordenação descendente em ${column.name}`}
            icon={<Icon icon="icon-arrow-down" fontSize="md" />}
            color={
              column.sort! === params.sort && params.order === Order.DESC ? 'black' : 'gray.60'
            }
            onClick={handleSortOrder(column.sort || '', Order.DESC)}
          />
        </Text>
      </Tooltip>
    );

    return (
      <Th key={column.name}>
        <SimpleText fontSize="19px" fontWeight="semibold" textTransform="none">
          {column.name}
        </SimpleText>
        {column.sort && actions}
      </Th>
    );
  });

  const rows = campaigns.map((campaign) => {
    const startDate = new Date(campaign.startDate).toLocaleString('pt-BR', DATE_OPTIONS);
    const endDate = new Date(campaign.endDate).toLocaleString('pt-BR', DATE_OPTIONS);

    const handleDelete = () => setDeleteCampaign(campaign.id);
    const handleMove = () => setMoveId(campaign.id);
    const handleDuplicate = () => {
      duplicateModal.onOpen();
      setDuplicateId(campaign.id);
    };

    const handleEdit = () => {
      if (campaign.status === Status.CURRENT) {
        currentCampaignAlert.onOpen();
      } else {
        navigate(campaign.id);
      }

      api.clearCampaignUpsert();
    };

    return (
      <Tr key={campaign.id}>
        <Td width={{ base: '200px', '2xl': '400px' }}>
          <Tooltip label={campaign.name}>
            <TextEllipsis maxWidth="100%" width={{ base: '200px', '2xl': '400px' }}>
              {campaign.name}
            </TextEllipsis>
          </Tooltip>
        </Td>
        <Td>
          <Text width={{ base: '100px', '2xl': '213px' }}>{startDate}</Text>
        </Td>
        <Td>
          <Text width={{ base: '100px', '2xl': '213px' }}>{endDate}</Text>
        </Td>
        <Td>
          <Text
            color="white"
            textAlign="center"
            borderRadius="30px"
            fontWeight="bold"
            width="min-content"
            textTransform="uppercase"
            padding={{ base: '2px 10px', '2xl': '2px 12px' }}
            fontSize={{ base: '13px', '2xl': '14px' }}
            backgroundColor={STATUS_COLOR[campaign.status]}
          >
            {campaign.status}
          </Text>
        </Td>
        <Td>
          {renderActions(campaign, handleEdit, handleDelete, handleDuplicate, handleMove, isMoving)}
        </Td>
      </Tr>
    );
  });

  const loadingView = (
    <Center>
      <PageSpinner />
    </Center>
  );

  const tableView = (
    <Table columns={columns} rows={rows} size={{ base: 'sm', '2xl': 'md' }}>
      <TablePagination path="." current={current} perPage={perPage} total={total} />
    </Table>
  );

  return (
    <PageBox>
      <Header marginBottom="40px">
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/telas">
              <Icon icon="icon-layout" /> <SimpleText>Telas</SimpleText>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink isCurrentPage>
              <SimpleText>{screen?.name}</SimpleText>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex>
          <Search
            placeholder="Pesquisar campanha"
            width="256px"
            onSearch={onSearch}
            onChange={onSearch}
          />
          <Button
            width="256px"
            as={Link}
            to="novo"
            colorScheme="primary"
            size="lg"
            onClick={() => api.clearCampaignUpsert()}
          >
            <Icon icon="icon-add" marginRight="5px" fontSize="xl" />
            Criar campanha
          </Button>
        </Flex>
      </Header>
      {isLoading ? loadingView : tableView}
      <DeleteCampaignModal />
      <MoveCampaignDrawer campaignId={moveId} onClose={onCloseMoveDrawer} />
      <DuplicateCampaignModal
        isOpen={duplicateModal.isOpen}
        onClose={duplicateModal.onClose}
        onDuplicate={onDuplicate}
      />
      <ConfirmationModal
        isOpen={currentCampaignAlert.isOpen}
        onClose={currentCampaignAlert.onClose}
        description={CURRENT_EDIT_WARN}
      >
        <Button onClick={onEditCurrent} variant="solid" colorScheme="primary">
          Continuar
        </Button>
      </ConfirmationModal>
    </PageBox>
  );
}

export default CampaignList;
