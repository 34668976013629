import { Button } from '@chakra-ui/react';
import React from 'react';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import useStore from '../../../shared/store';

type Props = {
  onClose: () => void;
};

function DeleteUserModal({ onClose }: Props) {
  const { deleteUser } = useStore((state) => state.api);
  const { currentId } = useStore((state) => state.users.deletion);
  const { isLoading } = useStore((state) => state.users.deleteUser);

  const handleDelete = async () => {
    await deleteUser(currentId!);
    onClose();
  };

  return (
    <ConfirmationModal isOpen={!!currentId} onClose={onClose} isCancelDisabled={isLoading}>
      <Button
        onClick={handleDelete}
        variant="solid"
        colorScheme="primary"
        isLoading={isLoading}
        disabled={isLoading}
      >
        Excluir
      </Button>
    </ConfirmationModal>
  );
}

export default DeleteUserModal;
