import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import useDebounceValue from '../../../../../shared/hooks/useDebounceValue';
import {
  getExtensionFromFile,
  getImageType,
  imageReceived,
  stripExtension,
} from '../../../../../shared/utils/imageUpload';
import { IForm } from '../CreateMediaModal.types';
import { defaultSource, getFileField } from '../shared';

type ImageFromURLProps = {
  url: string;
  onLoad: () => void;
  onLoadStart: () => void;
  isInvalid: boolean;
};

const VALID_EXTESIONS = ['png', 'jpg', 'jpeg'];

const ImageFromURL = memo(({ isInvalid, url, onLoadStart, onLoad }: ImageFromURLProps) => {
  const { setError, setValue, getValues } = useFormContext<IForm>();
  const [hasLoaded, setLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (isInvalid) {
      setLoaded(false);
    }
  }, [isInvalid]);

  const handleLoad = async () => {
    const blob = await imageReceived(imageRef.current!);
    const nameWihExtension = imageRef.current!.src.split('/').pop()!;
    const name = stripExtension(nameWihExtension);
    const extension = getImageType(nameWihExtension);

    if (VALID_EXTESIONS.every((v) => v !== extension)) {
      setError(getFileField('source', 0), {
        message: `Formato ${extension} recebido na URL informada não é suportado`,
      });
      return;
    }

    const file = new File([blob!], name, { type: blob!.type });
    const nameField = getValues(getFileField('name', 0));
    const fileField = getValues(getFileField('source.file', 0));
    const fileName = fileField && fileField.name;

    onLoad();
    setLoaded(true);
    setValue(
      getFileField('source', 0),
      { file, url },
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }
    );

    if (file && (!nameField || fileName === nameField)) {
      setValue(getFileField('name', 0), stripExtension(file.name) || '', {
        shouldValidate: true,
      });
    }
  };

  const onError = () => {
    if (url) {
      setError(getFileField('source', 0), {
        message: 'Não foi possível encontrar uma imagem na URL informada',
      });
      setValue(getFileField('source', 0), defaultSource);
    } else {
      setValue(getFileField('source', 0), defaultSource, { shouldValidate: true });
    }

    onLoad();
  };

  return (
    <Box>
      <Image
        maxWidth="100%"
        maxHeight="150px"
        display={hasLoaded ? 'block' : 'none'}
        ref={imageRef}
        marginX="auto"
        crossOrigin="anonymous"
        src={url}
        onLoadStart={onLoadStart}
        onLoad={handleLoad}
        onError={onError}
      />
    </Box>
  );
});

function URLImagePanel() {
  const { formState, register, control } = useFormContext<IForm>();
  const isURL = useWatch<IForm, 'isURL'>({ name: 'isURL' });
  const [isLoadingImage, setLoadingImage] = useState(false);
  const [url, setURL] = useState('');
  const debouncedFieldValue = useDebounceValue(url, 1000);

  const { errors } = formState;
  const currentErrors = errors.files && errors.files[0];
  const isImageValid = !!debouncedFieldValue && !currentErrors?.source && !isLoadingImage;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setURL(e.target.value);
  const onLoadStart = useCallback(() => setLoadingImage(true), []);
  const onLoad = useCallback(() => setLoadingImage(false), []);

  const imageFromURLView = (
    <ImageFromURL
      onLoadStart={onLoadStart}
      onLoad={onLoad}
      url={debouncedFieldValue}
      isInvalid={!!currentErrors?.source}
    />
  );

  const renderImage = (file: File | null) => (
    <Box padding="20px 20px 0" borderRadius="8px">
      {imageFromURLView}
      <Controller
        control={control}
        name={getFileField('name', 0)}
        render={({ field: { value } }) => (
          <Text marginTop="5px" textAlign="center" color="gray.60" fontSize="sm">
            {value}.{file ? getExtensionFromFile(file) : ''}
          </Text>
        )}
      />
    </Box>
  );

  const fieldsView = (
    <>
      <FormControl marginTop="24px" isInvalid={!!currentErrors?.name}>
        <FormLabel>Nome da mídia</FormLabel>
        <Input placeholder="Digite o nome da mídia" {...register(getFileField('name', 0))} />
        <FormErrorMessage>{currentErrors?.message}</FormErrorMessage>
      </FormControl>
      <Controller
        control={control}
        name={getFileField('source.file', 0)}
        render={({ field: { value } }) =>
          isImageValid && value ? renderImage(value) : imageFromURLView
        }
      />
    </>
  );

  return (
    <>
      <FormControl isInvalid={!!currentErrors?.source}>
        <FormLabel>Insira a URL do arquivo</FormLabel>
        <InputGroup>
          <Input placeholder="Digite a URL" value={url} onChange={handleInputChange} />
          <InputRightElement>{isLoadingImage && <Spinner />}</InputRightElement>
        </InputGroup>
        <FormErrorMessage>{currentErrors?.source?.message}</FormErrorMessage>
      </FormControl>
      {isURL && fieldsView}
    </>
  );
}

export default URLImagePanel;
