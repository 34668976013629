/* eslint-disable react/no-array-index-key */
import {
  Box,
  BoxProps,
  Center,
  CenterProps,
  Flex,
  Heading,
  Image,
  ImageProps,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import 'swiper/css';
import 'swiper/css/pagination';
import imagePlaceholderSource from '../../../assets/images/image-placeholder.png';
import { Item } from '../../../shared/services/types/campaign';
import { SimpleText } from '../../../shared/styles';
import useStore from '../../../shared/store';
import { Cursor } from '../CampaignComponents.types';
import { renderCarousel, renderStory } from '../shared';
import CursorOptions from './CursorOptions';
import handIcon from '../../../assets/icons/hand.png';

type Props = {
  getCursor: () => Cursor;
};

const UNDEF_ARR = Array.from({ length: 3 }, () => undefined);

const ComponentWrapper = styled(Box)`
  background-color: var(--chakra-colors-white);
  border: 3px solid;
  width: 100%;
  height: auto;

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const renderLabelText = (text: string) =>
  text.split(/(\*[^\\*]+\*)/g).reduce((acc: (string | JSX.Element)[], piece, index) => {
    const highlightedPiece = (
      <SimpleText fontWeight="bold" key={index}>
        {piece.slice(1, -1)}
      </SimpleText>
    );
    if (piece.startsWith('*') && piece.endsWith('*')) {
      return [...acc, highlightedPiece];
    }

    return [...acc, piece];
  }, []);

const renderBanner = (
  item?: Item,
  index?: number,
  containerProps?: CenterProps,
  imageProps?: ImageProps
) => (
  <Center
    key={`${item?.mediaId} ${index}`}
    padding={item?.mediaId ? '0' : '24px'}
    borderRadius="20px"
    {...containerProps}
  >
    <Image
      alt="Banner do componente"
      minWidth="50px"
      src={item?.download || imagePlaceholderSource}
      {...imageProps}
    />
  </Center>
);

const defaultBorder = { border: '3px solid', borderColor: 'blue.20' };

const isFieldValid = (errors: any) => Object.keys(errors).length === 0;
const isMediaEmpty = (item: Item) => item?.mediaId === undefined;
const isItemsEmpty = (items: Item[]) => items.every(isMediaEmpty);

const baseCarousel = (
  <Center borderRadius="20px" border="3px solid" borderColor="blue.20" margin="12px">
    {renderCarousel()}
  </Center>
);

function Preview({ getCursor }: Props) {
  const {
    components: addedComponents,
    errors,
    hasSubmitted,
  } = useStore((state) => state.campaigns.upsert);
  const { removeComponent, changePosition } = useStore((state) => state.api);
  const handleRemoveAndClearComponents = (position: any) => {
    removeComponent(position);
  };

  const components = addedComponents.map(({ type, content }, position) => {
    let contentView: React.ReactNode;
    let color = 'blue.20';
    const isSelected = getCursor().position === position;
    const error = errors[position];
    const isValid = Array.isArray(error) ? error.every(isFieldValid) : isFieldValid(error);

    if (!isValid && hasSubmitted) {
      color = 'red.100';
    }

    if (isSelected) {
      color = 'green.100';
    }

    const baseProps = {
      isSelected,
      shouldRenderOptions: addedComponents.length > 1,
      onRemove: () => handleRemoveAndClearComponents(position),
    };

    const mediaPadding = (content as { items: any[] }).items?.length ? '0' : '12px';

    const wrapperProps: BoxProps = {
      padding: type === 'label' ? undefined : mediaPadding,
      display: type === 'showcase' || type === 'story' ? 'flex' : undefined,
      borderColor: `${color} !important`,
      marginBottom: position === 0 && type === 'banner' ? '0 !important' : '10px',
      onClick: () => changePosition(position),
    } as const;

    switch (type) {
      case 'banner':
        contentView = renderBanner(content.items && content.items[0], undefined, {
          border: 'none',
          borderBottom: 'none',
        });
        break;
      case 'grid':
        contentView = (
          <SimpleGrid columns={2} gap="10px">
            {Array.from({ length: 2 }, (_, index) => {
              const item = content.items && content.items[index];
              return (
                <Center padding={isMediaEmpty(item) ? '12px' : undefined}>
                  {renderBanner(
                    item,
                    index,
                    isMediaEmpty(item) ? { ...defaultBorder, flex: 1 } : { alignSelf: 'stretch' }
                  )}
                </Center>
              );
            })}
          </SimpleGrid>
        );
        break;
      case 'label':
        contentView = (
          <Text
            width="100%"
            padding="12px"
            color={content.color}
            fontSize={`${content.fontSize}px`}
            textAlign={content.position || 'left'}
          >
            {content.text ? renderLabelText(content.text) : 'Texto'}
          </Text>
        );
        break;
      case 'story':
        wrapperProps.display = 'flex';
        wrapperProps.overflow = 'auto';
        if (isItemsEmpty(content.items)) {
          contentView = (
            <Box display="flex" margin="12px" width="100%" justifyContent="space-between">
              {renderStory()}
            </Box>
          );
        } else {
          contentView = content.items.map((item, index) => (
            <Box
              fontWeight="bold"
              textAlign="center"
              minWidth="150px"
              minHeight="150px"
              padding="12px"
              key={`${item?.mediaId} ${index}`}
            >
              {renderBanner(
                item,
                index,
                { borderRadius: '50%', padding: '0' },
                { borderRadius: '50%', width: '120px', height: '120px', objectFit: 'cover' }
              )}
              {item ? item.name : 'Story'}
            </Box>
          ));
        }
        break;
      case 'showcase':
        wrapperProps.display = 'flex';
        wrapperProps.overflow = 'auto';
        contentView = (
          <Box display="flex" width="100%" justifyContent="space-between">
            {Array.from({ length: 3 }, (_, index) =>
              renderBanner(undefined, index, {
                ...defaultBorder,
                borderRadius: '12px',
                width: '100px',
                height: '100px',
                _notLast: { marginRight: '12px' },
              })
            )}
          </Box>
        );
        break;
      case 'carousel':
        if (isItemsEmpty(content.items)) {
          contentView = baseCarousel;
        } else {
          contentView = (
            <Swiper modules={[Pagination]} pagination>
              {(content.items && content.items.length > 0 ? content.items : UNDEF_ARR).map(
                (item, index) => (
                  <SwiperSlide key={`${item?.mediaId} ${index}`}>
                    {renderBanner(item, index, { border: 'none' })}
                  </SwiperSlide>
                )
              )}
            </Swiper>
          );
        }
        break;
      default:
        contentView = <Text>Componente não suportado</Text>;
    }

    return (
      <Draggable key={`${type} ${position}`} draggableId={`${type} ${position}`} index={position}>
        {(dragProvided) => (
          <Box
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
            ref={dragProvided.innerRef}
          >
            <CursorOptions {...baseProps}>
              <ComponentWrapper {...wrapperProps}>{contentView}</ComponentWrapper>
            </CursorOptions>
          </Box>
        )}
      </Draggable>
    );
  });

  return (
    <Flex
      flexDirection="column"
      padding="35px 24px"
      overflow="auto"
      flex="1"
      minWidth={{ base: '320px' }}
      maxWidth="480px"
    >
      <Box height="0">
        <Heading as="h2" fontSize="xl" marginBottom="30px">
          Pré-visualização
        </Heading>
        <Droppable droppableId="preview-components">
          {(dropProvided) => (
            <Box
              id="preview-root"
              marginBottom="30px"
              border="2px dashed"
              borderRadius="3xl"
              height={components.length === 0 ? '70vh' : 'auto'}
              borderColor="blue.20"
              padding="14px"
              {...dropProvided.droppableProps}
              ref={dropProvided.innerRef}
            >
              {components.length === 0 ? (
                <Flex h="100%" flexDirection="column" alignItems="center" justifyContent="center">
                  <Box>
                    <Image src={handIcon} />
                  </Box>
                  <Text w="200px" textAlign="center" color="#99ACCC">
                    Clique no componente e arraste na posição que desejar
                  </Text>
                </Flex>
              ) : (
                <>
                  {components}
                  {dropProvided.placeholder}
                </>
              )}
            </Box>
          )}
        </Droppable>
      </Box>
    </Flex>
  );
}

export default Preview;
