import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import imagePlaceholderSource from '../../assets/images/image-placeholder.png';
import { ComponentType } from '../../shared/services/types/campaign';

export const componentSlug: { [K in ComponentType['type']]: string } = {
  banner: 'banner',
  carousel: 'carrossel',
  showcase: 'showcase',
  story: 'story',
  label: 'título',
  grid: 'grid',
};

export const renderCarousel = () =>
  Array.from({ length: 4 }, (_, i) => (
    <Box
      textAlign="center"
      key={i}
      padding="20px"
      borderRadius="20px"
      minWidth="40%"
      backgroundColor="white"
      transform={`translateX(-${i * 50}%)`}
      _first={{ marginLeft: '60%' }}
      _notFirst={{ borderLeft: '3px solid', borderColor: 'blue.20' }}
    >
      <Image
        display="inline-block"
        width="43px"
        alt="Banner do componente"
        src={imagePlaceholderSource}
      />
    </Box>
  ));

export const renderStory = () =>
  Array.from({ length: 3 }, (_, i) => (
    <Box key={i} borderRadius="50%" border="2px solid" borderColor="blue.20" padding="20px">
      <Image alt="Banner do componente" src={imagePlaceholderSource} />
    </Box>
  ));
