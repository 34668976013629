import {
  Box,
  Button,
  InputGroup,
  Input,
  InputRightAddon,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Checkbox,
  Tooltip,
  ButtonGroup,
  Flex,
} from '@chakra-ui/react';
import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../../../shared/components/Icon';
import useStore from '../../../shared/store';
import { IComponent, Item } from '../../../shared/services/types/campaign';
import { IMedia } from '../../../shared/services/types/media';
import { MediaFieldErrors, validateUrl } from '../../../shared/utils/validation';
import { MediaFieldCursor } from '../CampaignComponents.types';
import MediaFieldList from './MediaFieldList';
import IconButton from '../../../assets/icons/btn-image.png';
import { AccordionForm } from './AccordionForm';
import { ModalCountdowndConfig } from './ModalCountdownConfig';

type MediaFieldProps = {
  type: IComponent['type'];
  value: Partial<Item>[];
  componentErrors: MediaFieldErrors[];
  onChange: (media: Partial<Item>[]) => void;
};

function MediaField({ type, value, onChange, componentErrors }: MediaFieldProps) {
  const [cursor, setCursor] = useState(0);
  const selectedMediaRef = useRef<IMedia | null>(null);
  const location = useLocation();
  const isStory = type === 'story';
  const [hasCountdown, setHasCountdown] = useState(value.map(() => false));

  const { hasSubmitted } = useStore((state) => state.campaigns.upsert);
  const selectedMedia = useStore(({ campaigns }) => campaigns.selectedMedia);

  const getCursor = useCallback(
    (): MediaFieldCursor => ({
      position: cursor,
      item: {
        ...value[cursor],
      },
      isValid: Object.keys(componentErrors[cursor] || {}).length === 0,
    }),
    [cursor, value, componentErrors]
  );

  const overrideValue = useCallback(
    (item: Partial<Item>) =>
      value.map((currentItem, position) =>
        position === getCursor().position ? item : currentItem
      ),
    [value, getCursor]
  );

  const handleCursorChange = useCallback((position: number) => setCursor(position), []);

  useEffect(() => {
    if (!(selectedMedia && selectedMediaRef.current?.id !== selectedMedia.id)) return;

    const { url = '', name = '' } = getCursor().item || {};
    const addedMedia = {
      url,
      mediaId: selectedMedia.id,
      download: selectedMedia.download,
      ...(isStory ? { name } : null),
    };

    onChange(overrideValue(addedMedia));

    selectedMediaRef.current = selectedMedia;
  }, [overrideValue, componentErrors, isStory, selectedMedia, getCursor, onChange]);

  const onAddMedia = () => {
    setCursor(0);
    onChange([{}, ...value]);
    setHasCountdown([false, ...hasCountdown]);

    selectedMediaRef.current = null;
  };

  const onRemoveMedia = (position: number) => {
    const newPosition = position - 1;

    setCursor(newPosition > 0 ? newPosition : 0);
    onChange(value.filter((_, index) => index !== position));
    setHasCountdown(hasCountdown.filter((_, index) => index !== position));
  };

  const onChangeField = useCallback(
    (name: keyof Item, newValue: any) => {
      const newItem = { ...getCursor().item, [name]: newValue };
      onChange(overrideValue(newItem));
    },
    [getCursor, onChange, overrideValue]
  );

  const onChangePosition = (source: number, destination: number) => {
    const newValue = [...value];
    const [media] = newValue.splice(source, 1);
    newValue.splice(destination, 0, media);

    const newHasCountdown = [...hasCountdown];
    const [item] = newHasCountdown.splice(source, 1);
    newHasCountdown.splice(destination, 0, item);

    onChange(newValue);
    setHasCountdown(newHasCountdown);
    setCursor(destination);
  };

  const hasURL = getCursor().item?.url !== '#';
  const hasAnalyticsId = getCursor().item?.analyticsId !== '#';
  const hasStoryName = getCursor().item?.name !== undefined;
  const hasCountdown2 = !!Object.keys(getCursor().item?.countdown || {}).length;

  const handleHasURLOnChange = () => onChangeField('url', hasURL ? '#' : '');

  const handleHasStoryName = () => {
    const item = getCursor().item;
    if (item && item.name === undefined) {
      onChangeField('name', '');
    } else {
      const newItem = { ...item };
      delete newItem.name;

      onChange(overrideValue(newItem));
    }
  };

  const handleItemValueOnChange = useCallback(
    (property: any) => {
      onChangeField('countdown', property);
    },
    [onChangeField]
  );

  const handleHasCountdownOnChange = useCallback(() => {
    if (!hasCountdown2) {
      onChangeField('countdown', { status: 'pending...' });
    } else {
      onChangeField('countdown', {});
    }
  }, [onChangeField, hasCountdown2]);

  const handleHasAnalyticsOnChange = () => {
    onChangeField('analyticsId', hasAnalyticsId ? '#' : '');
  };

  const handleURLOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChangeField('url', e.target.value);
  };

  const handleAnalyticsOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChangeField('analyticsId', e.target.value);
  };

  const handleNameOnChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    onChangeField('name', e.target.value);

  const statusUrl =
    !hasURL || validateUrl(String(getCursor().item?.url)) ? 'CONCLUIDO' : 'PENDENTE';
  const statusAnalytics =
    !hasAnalyticsId || getCursor().item?.analyticsId ? 'CONCLUIDO' : 'PENDENTE';
  const statusNameStory = !hasStoryName || getCursor().item?.name ? 'CONCLUIDO' : 'PENDENTE';
  const statusCountdown =
    !hasCountdown2 || !getCursor().item?.countdown.status ? 'CONCLUIDO' : 'PENDENTE';

  const mediasView = (
    <MediaFieldList
      value={value}
      type={type}
      errors={componentErrors}
      onChangePosition={onChangePosition}
      onAddMedia={onAddMedia}
      onRemoveMedia={onRemoveMedia}
      getCursor={getCursor}
      setCursor={handleCursorChange}
      hasCountdown={hasCountdown}
    />
  );

  const mediaPlaceholderView = (
    <FormControl
      marginTop="32px"
      marginBottom="32px"
      isInvalid={hasSubmitted && !!componentErrors[cursor]?.mediaId}
    >
      <ButtonGroup width="100%">
        <Button
          width="100%"
          height="66px"
          background="primary.500"
          color="white"
          _hover={{ background: 'primary.600' }}
          borderRadius="10px"
        >
          <Image mr="1rem" src={IconButton} />
          Selecionar mídia da biblioteca
        </Button>
      </ButtonGroup>
    </FormControl>
  );

  const nameFieldView = (
    <AccordionForm
      id="id-name-story"
      label="Nome do story "
      icon="icon-text"
      status={statusNameStory}
    >
      <FormControl marginTop="24px" isInvalid={hasSubmitted && !!componentErrors[cursor]?.name}>
        <FormLabel>Nome</FormLabel>
        <Input
          value={getCursor().item?.name || ''}
          onChange={handleNameOnChange}
          placeholder="Digite aqui o nome"
          disabled={!hasStoryName}
        />

        <Checkbox
          marginTop="6px"
          colorScheme="primary"
          color="#838181"
          isChecked={!hasStoryName}
          onChange={handleHasStoryName}
        >
          Esta mídia não possui nome
        </Checkbox>
        <FormErrorMessage>{componentErrors[cursor]?.name}</FormErrorMessage>
      </FormControl>
    </AccordionForm>
  );

  return (
    <Box _notLast={{ marginBottom: '24px' }}>
      <FormControl isRequired>
        <Button
          as={Link}
          to="/midia/galeria"
          state={{ backgroundLocation: location }}
          width="auto"
          height="auto"
          variant="unstyled"
        >
          {mediaPlaceholderView}
        </Button>
      </FormControl>
      {mediasView}

      {isStory && nameFieldView}

      <AccordionForm
        id="id-link-direcionamento"
        label="Link de direcionamento"
        icon="icon-link"
        status={statusUrl}
      >
        <FormControl
          marginTop="24px"
          isRequired
          isInvalid={hasSubmitted && !!componentErrors[cursor]?.url}
        >
          <FormLabel>Link de direcionamento da mídia</FormLabel>
          <InputGroup>
            <Input
              value={getCursor().item?.url || ''}
              onChange={handleURLOnChange}
              placeholder="Digite um link válido"
              disabled={!hasURL}
              _placeholder={{ color: 'gray.40', opacity: 0.5 }}
            />
            <InputRightAddon height="56px" backgroundColor="white">
              <Icon icon="icon-link" fontSize={{ base: 'lg', xl: '2xl' }} color="gray.60" />
            </InputRightAddon>
          </InputGroup>
          <Checkbox
            marginTop="6px"
            colorScheme="primary"
            color="#838181"
            isChecked={!hasURL}
            onChange={handleHasURLOnChange}
          >
            Esta mídia não possui link de direcionamento
          </Checkbox>
          <FormErrorMessage>{componentErrors[cursor]?.url}</FormErrorMessage>
        </FormControl>
      </AccordionForm>

      <AccordionForm id="id-analytics" label="Analytics" icon="icon-pipe" status={statusAnalytics}>
        <FormControl
          marginTop="24px"
          isRequired
          isInvalid={hasSubmitted && !!componentErrors[cursor]?.analyticsId}
        >
          <FormLabel>Analytics ID</FormLabel>
          <InputGroup>
            <Input
              isRequired={getCursor().item?.analyticsId === '#'}
              value={getCursor().item?.analyticsId || ''}
              onChange={handleAnalyticsOnChange}
              placeholder="Digite um identificador"
              disabled={!hasAnalyticsId}
            />
            <InputRightAddon height="56px" backgroundColor="white">
              <Tooltip label="Insira um parâmetro para esta mídia que será disparado para o Analytics">
                <Icon
                  icon="icon-circle-information"
                  fontSize={{ base: 'lg', xl: '2xl' }}
                  color="gray.60"
                  cursor="pointer"
                  _hover={{ color: 'gray.80' }}
                />
              </Tooltip>
            </InputRightAddon>
          </InputGroup>
          <Checkbox
            marginTop="6px"
            colorScheme="primary"
            color="#838181"
            isChecked={!hasAnalyticsId}
            onChange={handleHasAnalyticsOnChange}
          >
            Esta mídia não possui Analytics ID
          </Checkbox>
          <FormErrorMessage>{componentErrors[cursor]?.analyticsId}</FormErrorMessage>
        </FormControl>
      </AccordionForm>

      {type === 'carousel' || type === 'banner' ? (
        <AccordionForm
          id="id-contagem-regressiva"
          label="Contagem regressiva"
          icon="icon-tumer"
          status={statusCountdown}
        >
          <Flex flexDirection="column" alignItems="flex-start">
            <Checkbox
              marginTop="6px"
              colorScheme="primary"
              color="#838181"
              isChecked={!hasCountdown2}
              onChange={handleHasCountdownOnChange}
            >
              Esta mídia não possui promoção com contagem regressiva
            </Checkbox>
            {}
            <ModalCountdowndConfig
              disabled={!hasCountdown2}
              handleSave={handleItemValueOnChange}
              mediaField={getCursor()}
            />
          </Flex>
        </AccordionForm>
      ) : null}
    </Box>
  );
}

export default MediaField;
